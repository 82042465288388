<template>
  <section class="section">
    <div class="container is-max-desktop">
      <div class="has-text-centered mt-3">
        <p class="mb-5"><img src="/static/logo-wide.png" /></p>
      </div>
      <div class="block mt-3 mb-6">
        <div class="has-text-centered">
          <h3 class="title is-3">Let's reset your password</h3>
          <h6 class="subtitle is-6 pt-1">
            Or
            <router-link :to="{ name: 'Login' }">login if you've remembered</router-link>
          </h6>
        </div>
      </div>
      <div class="columns">
        <div class="column is-three-fifths is-offset-one-fifth">
          <b-notification type="is-danger" role="alert" v-if="form.errors.has('NOFIELD')">
            {{ form.errors.get('NOFIELD') }}
          </b-notification>
          <div class="box is-clearfix">
            <form @submit.prevent="handleSubmit" novalidate>
              <div class="block">
                <b-field
                  label="Email"
                  :message="
                    form.errors.get('email') ||
                    `Enter your password and we'll send you reset instructions`
                  "
                  :type="{ 'is-danger': form.errors.has('email') }"
                >
                  <b-input v-model="form.email" placeholder="user@email.com"></b-input>
                </b-field>
              </div>

              <div class="block has-text-right">
                <b-button native-type="submit" :loading="loading" type="is-primary">
                  Send Email
                </b-button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Form from '../utils/form';

export default {
  name: 'PasswordReset',
  data() {
    return {
      loading: false,
      error: '',
      form: new Form({ email: '' }),
    };
  },
  methods: {
    handleSubmit() {
      this.loading = true;
      this.form
        .post('/auth/password/reset')
        .then(() => {
          let msg =
            'If that email is in our system, instructions to reset your password have been sent to it.';
          this.$buefy.toast.open({ message: msg, type: 'is-success', duration: 10000 });
          this.$router.push({ name: 'Login' });
        })
        .catch(() => {
          if (!this.form.errors.any()) {
            this.form.errors.setGenericError();
          }
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
