<template>
  <b-field
    label="Phone"
    :message="errors.get('phone')"
    :type="{ 'is-danger': errors.has('phone') }"
  >
    <b-input type="text" :value="value" @input="parseNumber" maxlength="14" />
  </b-field>
</template>

<script>
// Is you change :value="content" or v-model="content" with
// a data function this doesn't work as expected.
export default {
  props: {
    value: String,
    errors: Object,
  },
  methods: {
    parseNumber(value) {
      // This doesn't actually visually chop off the last segment to 4 but I'm unsure why.
      // To provide that functionality the maxlength on the input is set to 14.
      let x = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      let val = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
      this.$emit('input', val);
    },
  },
};
</script>
