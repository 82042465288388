<template>
  <div>
    <inquiry-detail-header
      :createdAt="createdAt"
      :patient="patient"
      :subscriber="subscriber"
      :provider="provider"
      :primary-care-provider="plan?.primary_care_provider"
    />

    <div class="container is-max-hd" v-if="inputDoesNotMatchMessages.length !== 0">
      <br />
      <div class="content">
        <b-notification type="is-warning" :closable="false" role="alert">
          Update your records:
          <ul>
            <li v-for="message in inputDoesNotMatchMessages" :key="message">
              {{ message }}
            </li>
          </ul>
        </b-notification>
      </div>
    </div>

    <section class="section">
      <div class="container is-max-hd">
        <div class="columns">
          <div class="column is-one-quarter">
            <inquiry-detail-sidebar
              :plan="plan"
              :selected-networks="selectedNetworks"
              :selected-payments="selectedPayments"
              :selected-benefits="selectedBenefits"
              :selected-level="selectedLevel"
              @input-network="selectedNetworks = $event"
              @input-payment="selectedPayments = $event"
              @input-benefit="selectedBenefits = $event"
              @input-level="selectedLevel = $event"
            />
          </div>
          <div class="column is-three-quarters">
            <div class="block has-text-right">
              <b-button
                tag="router-link"
                type="is-primary"
                :to="{ name: 'InquiryCopy', params: { id: inquiryId } }"
                style="margin-right: 4px"
                icon-left="reload"
              ></b-button>

              <b-button
                target="_blank"
                :href="pdfUrl"
                tag="a"
                type="is-primary"
                icon-left="cloud-download-outline"
              >
                Download PDF
              </b-button>
            </div>

            <reimbursement-report v-if="permReimbursements" :inquiry-id="inquiryId" />

            <inquiry-detail-payer
              :plan="plan"
              :payer="payer"
              :response-payer="responsePayer"
              :payer-name-override="payerNameOverride"
            ></inquiry-detail-payer>

            <div class="block" v-for="(benefitGroup, i) in plan.benefit_groups" :key="i">
              <inquiry-detail-benefit
                :benefit-group="benefitGroup"
                :selected-networks="selectedNetworks"
                :selected-payments="selectedPayments"
                :selected-benefits="selectedBenefits"
                :selected-level="selectedLevel"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import InquiryDetailBenefit from './InquiryDetailBenefit.vue';
import InquiryDetailHeader from './InquiryDetailHeader.vue';
import InquiryDetailPayer from './InquiryDetailPayer.vue';
import InquiryDetailSidebar from './InquiryDetailSidebar.vue';
import ReimbursementReport from './ReimbursementReport.vue';

export default {
  name: 'Plan',
  components: {
    InquiryDetailBenefit,
    InquiryDetailHeader,
    InquiryDetailPayer,
    InquiryDetailSidebar,
    ReimbursementReport,
  },
  props: {
    // Raw data passed in
    rawPlan: Object,
    createdAt: String,
    inquiryId: String,
    payer: Object,
    responsePayer: String,
    patient: Object,
    subscriber: Object,
    provider: String,
    payerNameOverride: String,
    inputDoesNotMatchMessages: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters(['permReimbursements']),
    pdfUrl() {
      let url = `/api/v1/inquiries/${this.inquiryId}/pdf`;
      url += `?payments=${this.selectedPayments}`;
      url += `&networks=${this.selectedNetworks}`;
      url += `&benefits=${this.selectedBenefits}`;
      url += `&level=${this.selectedLevel}`;
      return url;
    },
  },

  data() {
    return {
      selectedNetworks: ['All'],
      selectedPayments: ['All'],
      selectedBenefits: ['All'],
      selectedLevel: 'All',
      // Processed plan
      plan: this.parsePlan(),
    };
  },
  methods: {
    parsePlan() {
      let copiedPlan = JSON.parse(JSON.stringify(this.rawPlan));

      // Plan has no benefit_groups so just return the raw plan with an
      // empty benefit_groups array. This is usually when there is unknown
      // coverage and the response contains contact information.
      if (!Object.hasOwn(this.rawPlan, 'benefit_groups')) {
        copiedPlan['benefit_groups'] = [];
        return copiedPlan;
      }
      return copiedPlan;
    },
  },
};
</script>
