<template>
  <b-field
    v-if="schema.allowed"
    :label="schema.label"
    :message="errors"
    :type="{ 'is-danger': errors ? errors.length : false }"
  >
    <b-datepicker
      v-model="content"
      :min-date="new Date(schema.min)"
      :max-date="new Date(schema.max)"
      :date-parser="dateParser"
      editable
      locale="en-US"
      placeholder="Click to select..."
      icon="calendar-today"
      :mobile-modal="false"
      :mobile-native="false"
      trap-focus
      :use-html5-validation="false"
      @input="$emit('input', content)"
    ></b-datepicker>
  </b-field>
</template>

<script>
export default {
  props: {
    value: Date,
    schema: Object,
    errors: String,
  },
  data() {
    return {
      content: this.value,
    };
  },
  methods: {
    dateParser(s) {
      // Change 01071981 -> 01/07/1981. Allows fast input without slashes.
      if (s.length === 8) {
        s = `${s.substr(0, 2)}/${s.substr(2, 2)}/${s.substr(4, 4)}`;
      }
      return new Date(s);
    },
  },
};
</script>
