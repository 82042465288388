<template>
  <li :class="active ? 'is-active' : ''">
    <router-link :to="{ name: view, params: params }">{{ label }}</router-link>
  </li>
</template>

<script>
export default {
  name: 'BreadcrumbLink',
  props: {
    view: String,
    label: String,
    params: Object,
    active: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
