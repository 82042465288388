<template>
  <section>
    <b-field>
      <b-button
        icon-left="delete"
        type="is-danger"
        v-if="checkedRows.length && !isForAdmin && isManager"
        @click="handleBulkRemove"
      >
        Remove selected inquiries
      </b-button>
    </b-field>
    <b-field>
      <b-input v-model="search" placeholder="Search" />
    </b-field>

    <b-table
      :data="filteredOpts"
      sort-icon="chevron-up"
      sort-icon-size="is-small"
      :default-sort="['date', 'desc']"
      striped
      :paginated="filteredOpts.length > 15"
      per-page="15"
      hoverable
      :checkable="!isForAdmin && isManager"
      :checked-rows.sync="checkedRows"
      :debounce-search="1000"
      :detailed="isForAdmin"
      detail-key="id"
      detail-transition="fade"
      :show-detail-icon="showDetailIcon"
    >
      <b-table-column field="patient" label="Patient" sortable v-slot="props">
        <router-link
          v-if="props.row.status === 'complete' || props.row.status === 'expired'"
          :to="{ name: 'InquiryDetail', params: { id: props.row.id } }"
        >
          {{ props.row.patient }}
        </router-link>
        <span v-else>
          {{ props.row.patient }}
        </span>
      </b-table-column>

      <b-table-column field="user" label="User" sortable v-slot="props">
        {{ props.row.user }}
      </b-table-column>

      <b-table-column field="provider" label="Provider" sortable v-slot="props" v-if="isForAdmin">
        {{ props.row.provider }}
      </b-table-column>

      <b-table-column field="payer" label="Payer" sortable v-slot="props">
        {{ props.row.payer }}
      </b-table-column>

      <b-table-column field="created_at" label="Date" sortable centered v-slot="props">
        {{ props.row.date }}
      </b-table-column>

      <b-table-column
        v-if="isForAdmin"
        field="is_hidden"
        label="Visibility"
        sortable
        numeric
        v-slot="props"
      >
        <b-icon v-if="props.row.is_hidden" icon="eye-off-outline"></b-icon>
      </b-table-column>

      <b-table-column field="status" label="Status" sortable numeric v-slot="props">
        <span
          :class="{
            tag: true,
            'is-success': props.row.plan_status == 'active',
            'is-danger': props.row.plan_status == 'inactive',
            'is-warning': props.row.plan_status == 'unavailable',
          }"
        >
          <span>{{ props.row.plan_status }}</span>
        </span>
      </b-table-column>

      <b-table-column v-if="isForAdmin" field="had_reimbursement" label="EIV" sortable v-slot="props">
        <b-icon v-if="props.row.had_reimbursement"
          type="is-success"
          icon="check-circle"
          size="is-small"
        />
      </b-table-column>

      <b-table-column label="Re-run" numeric v-slot="props" v-if="!isForAdmin">
        <router-link :to="{ name: 'InquiryCopy', params: { id: props.row.id } }">
          <b-icon icon="reload"></b-icon>
        </router-link>
      </b-table-column>

      <template #detail="props">
        <p>
          {{ props.row.submission }}
        </p>
      </template>
    </b-table>
  </section>
</template>

<script>
import request from '../request';
import { mapGetters } from 'vuex';

export default {
  name: 'InquiryTable',
  props: {
    options: Array,
    isForAdmin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search: '',
      checkedRows: [],
      showDetailIcon: true,
    };
  },
  computed: {
    ...mapGetters(['isManager']),
    filteredOpts() {
      return this.options.filter((option) => {
        let v = this.search.toLowerCase();
        return (
          option.provider.toLowerCase().indexOf(v) >= 0 ||
          option.payer.toLowerCase().indexOf(v) >= 0 ||
          option.user.toLowerCase().indexOf(v) >= 0 ||
          option.patient.toLowerCase().indexOf(v) >= 0 ||
          option.date.toLowerCase().indexOf(v) >= 0
        );
      });
    },
  },
  methods: {
    handleBulkRemove() {
      let message = `Are you sure you want to remove ${this.checkedRows.length} inquiries?`;
      message += `<br/><br/>NOTE: This will not delete the related data, but these inquiries will no longer be visible to you or your organization's users.`;

      this.$buefy.dialog.confirm({
        title: 'Remove inquiries',
        message: message,
        confirmText: `Remove ${this.checkedRows.length} inquiries`,
        type: 'is-danger',
        onConfirm: () => {
          let ids = this.checkedRows.map((r) => r.id);
          request
            .put(`/inquiries`, { inquiry_ids: ids, is_hidden: true })
            .then(() => {
              this.$buefy.toast.open({
                message: `Successfully removed ${this.checkedRows.length} inquiries`,
                type: 'is-success',
                duration: 10000,
              });
              this.checkedRows = [];
            })
            .finally(() => {
              // emit regardless just to ensure we are up to date
              this.$emit('bulkremove');
            });
        },
      });
    },
  },
};
</script>
