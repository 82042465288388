<template>
  <div>
    <breadcrumb-list>
      <breadcrumb-link view="Home" label="Home" />
      <breadcrumb-link view="AdminIndex" label="Admin" />
      <breadcrumb-link view="AdminRegisterPopulate" label="Register Populate" :active="true" />
    </breadcrumb-list>

    <section class="section">
      <div class="container is-max-desktop">
        <div class="columns">
          <div class="column is-three-fifths is-offset-one-fifth">
            <b-notification v-if="form.errors.has('NOFIELD')" type="is-danger" role="alert">
              {{ form.errors.get('NOFIELD') }}
            </b-notification>

            <div class="box" v-if="url === ''">
              <div class="block">
                <div class="has-text-centered">
                  <h1 class="subtitle is-3 has-text-primary">Register Populate</h1>
                </div>
              </div>
              <form @submit.prevent="handleSubmit" novalidate>
                <div class="block">
                  <form-input :form="form" label="First Name" field-name="first_name" />
                </div>

                <div class="block">
                  <form-input :form="form" label="Last Name" field-name="last_name" />
                </div>

                <div class="block">
                  <form-input :form="form" label="Email" field-name="email" />
                </div>

                <div class="block">
                  <PhoneField v-model="form.phone" :errors="form.errors" />
                </div>

                <div class="block">
                  <form-input
                    :form="form"
                    label="Organization Name"
                    field-name="organization_name"
                  />
                </div>

                <div class="block">
                  <form-input :form="form" label="Facility Name" field-name="provider_name" />
                </div>

                <div class="block">
                  <form-input
                    :form="form"
                    label="National Provider ID (NPI)"
                    field-name="provider_npi"
                  />
                </div>

                <div class="block">
                  <form-input :form="form" label="Tax ID" field-name="provider_tax_id" />
                </div>

                <div class="has-text-right">
                  <b-button :loading="loading" type="is-primary" native-type="submit">
                    Create
                  </b-button>
                </div>
              </form>
            </div>
            <div class="box" v-else>
              <div class="block">
                <div class="has-text-centered">
                  <h1 class="subtitle is-3 has-text-primary">Register Populate Url</h1>
                </div>
                <br />
                <b-tag type="primary">{{ url }}</b-tag>
                <br />
              </div>
            </div>

            <div class="has-text-centered">
              <b-button
                type="is-primary is-outlined"
                tag="router-link"
                :to="{ name: 'AdminIndex' }"
              >
                Nevermind, take me back.
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Form from '../../utils/form';

export default {
  name: 'AdminRegisterPopulate',
  components: {
    PhoneField: () => import('../../components/fields/PhoneField.vue'),
    FormInput: () => import('../../components/FormInput.vue'),
  },
  data() {
    return {
      loading: false,
      url: '',
      form: new Form({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        organization_name: '',
        provider_name: '',
        provider_npi: '',
        provider_tax_id: '',
      }),
    };
  },
  methods: {
    handleSubmit() {
      this.loading = true;
      this.form.errors.clear();

      this.form
        .post('/admin/auth/register/info')
        .then((resp) => {
          let msg = `Register population was added successfully.`;
          this.$buefy.toast.open({ message: msg, type: 'is-success' });
          this.url = resp.register_url;
        })
        .catch(() => {
          if (!this.form.errors.any()) {
            this.form.errors.setGenericError();
          }
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
