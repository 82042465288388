<template>
  <div>
    <breadcrumb-list>
      <breadcrumb-link view="Home" label="Home" />
      <breadcrumb-link view="AdminIndex" label="Admin" />
      <breadcrumb-link view="AdminPayerList" label="Payers" />
      <breadcrumb-link view="AdminPayerCreate" label="Create" :active="true" />
    </breadcrumb-list>

    <section class="section">
      <div class="container is-max-desktop">
        <div class="columns">
          <div class="column is-three-fifths is-offset-one-fifth">
            <b-notification v-if="form.errors.has('NOFIELD')" type="is-danger" role="alert">
              {{ form.errors.get('NOFIELD') }}
            </b-notification>

            <div class="box">
              <div class="block">
                <div class="has-text-centered">
                  <h1 class="subtitle is-3 has-text-primary">Create Payer</h1>
                </div>
              </div>
              <form @submit.prevent="handleSubmit" novalidate>
                <div class="block">
                  <b-field
                    label="Name"
                    :message="form.errors.get('name')"
                    :type="{ 'is-danger': form.errors.has('name') }"
                  >
                    <b-input v-model="form.name"></b-input>
                  </b-field>
                </div>
                <div class="block">
                  <b-field
                    label="Code"
                    :message="form.errors.get('code')"
                    :type="{ 'is-danger': form.errors.has('code') }"
                  >
                    <b-input v-model="form.code"></b-input>
                  </b-field>
                </div>

                <div class="block">
                  <b-switch
                    size="is-medium"
                    v-model="form.is_blue"
                    :type="form.errors.has('is_blue') ? 'is-danger' : 'is-info'"
                    :message="form.errors.get('is_blue')"
                  >
                    Is Blue
                  </b-switch>
                </div>

                <div class="block">
                  <b-switch
                    size="is-medium"
                    v-model="form.is_group"
                    :type="form.errors.has('is_group') ? 'is-danger' : ''"
                    :message="form.errors.get('is_group')"
                  >
                    Is Group
                  </b-switch>
                </div>

                <div class="has-text-right">
                  <b-button :loading="loading" type="is-primary" native-type="submit">
                    Create Payer
                  </b-button>
                </div>
              </form>
            </div>
            <div class="has-text-centered">
              <b-button
                type="is-primary is-outlined"
                tag="router-link"
                :to="{ name: 'AdminPayerList' }"
              >
                Nevermind, take me back.
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Form from '../../utils/form';

export default {
  name: 'AdminPayerCreate',
  data() {
    return {
      loading: false,
      form: new Form({
        name: '',
        code: '',
        is_blue: false,
        is_group: false,
      }),
    };
  },
  methods: {
    handleSubmit() {
      this.loading = true;
      this.form.errors.clear();

      this.form
        .post('/admin/payers')
        .then(() => {
          let msg = `${this.form.name} was added successfully.`;
          this.$buefy.toast.open({ message: msg, type: 'is-success' });
          this.$router.push({ name: 'AdminPayerList' });
        })
        .catch(() => {
          if (!this.form.errors.any()) {
            this.form.errors.setGenericError();
          }
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
