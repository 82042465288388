<template>
  <AdminLayout>
    <template #breadcrumb>
      <breadcrumb-link view="AdminOrganizationList" label="Organizations" :active="true" />
    </template>

    <div class="box" style="position: relative">
      <b-loading :is-full-page="false" v-model="loading"></b-loading>

      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <h1 class="title">Manage Organizations</h1>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <b-switch v-model="showDeactivated" type="is-primary is-light">
              <span>Show Deactivated</span>
            </b-switch>
          </div>
        </div>
      </div>
      <hr />

      <b-table
        v-if="filteredOptions"
        :data="filteredOptions"
        sort-icon="chevron-up"
        sort-icon-size="is-small"
        :default-sort="['name', 'asc']"
        :paginated="filteredOptions.length > 50"
        per-page="50"
        hoverable
        striped
      >
        <b-table-column field="name" label="Name" sortable searchable v-slot="props">
          <router-link :to="{ name: 'AdminOrganizationDetail', params: { id: props.row.id } }">
            {{ props.row.name }}
          </router-link>
        </b-table-column>

        <b-table-column
          :visible="showDeactivated"
          field="is_active"
          label="Active"
          sortable
          v-slot="props"
        >
          <b-icon
            :type="props.row.is_active ? 'is-success' : 'is-danger'"
            :icon="props.row.is_active ? 'check-circle' : 'close-circle'"
            size="is-small"
          />
        </b-table-column>
        <b-table-column
          field="has_active_subscription"
          label="Active Subscription"
          sortable
          v-slot="props"
        >
          <b-icon
            :type="
              props.row.has_active_subscription
                ? props.row.has_cancelled
                  ? 'is-warning'
                  : 'is-success'
                : 'is-danger'
            "
            :icon="props.row.has_active_subscription ? 'check-circle' : 'close-circle'"
            size="is-small"
          />
        </b-table-column>
        <b-table-column label="Reimbursement Estimates" v-slot="props">
          <b-icon
            :type="
              props.row.has_feature_reimbursement
                ? 'is-success'
                : props.row.has_override_reimbursement
                  ? 'is-warning'
                  : 'is-danger'
            "
            :icon="
              props.row.has_feature_reimbursement || props.row.has_override_reimbursement
                ? 'check-circle'
                : 'close-circle'
            "
            size="is-small"
          />

          <span v-if="props.row.has_override_reimbursement">
            (
            <span v-if="props.row.has_feature_reimbursement">unecessarily</span>
            overridden)
          </span>
        </b-table-column>
      </b-table>
    </div>
  </AdminLayout>
</template>

<script>
import request from '../../request';
import AdminLayout from '../../layouts/AdminLayout.vue';

export default {
  name: 'AdminOrganizationList',
  components: {
    AdminLayout,
  },

  data() {
    return {
      loading: true,
      organizationOptions: [],
      indexClicked: null,
      showDeactivated: false,
    };
  },
  created() {
    this.getOrganizations();
  },
  computed: {
    filteredOptions() {
      if (this.showDeactivated) {
        return this.organizationOptions;
      }
      return this.organizationOptions.filter((opt) => opt.is_active);
    },
  },
  methods: {
    getOrganizations() {
      this.loading = true;
      request
        .get('/admin/organizations')
        .then((resp) => (this.organizationOptions = resp.data.organizations))
        .finally(() => (this.loading = false));
    },
    handleIsActiveSwitch(isActive, id) {
      this.indexClicked = id;
      request
        .put(`/admin/organizations/${id}`, { is_active: isActive })
        .finally(() => (this.indexClicked = null));
    },
  },
};
</script>
