<template>
  <AdminLayout v-if="organization">
    <template #breadcrumb>
      <breadcrumb-link view="AdminOrganizationList" label="Organizations" />
      <breadcrumb-link view="OrganizationDetail" :label="organization.name" :active="true" />
    </template>
    <div class="box" style="position: relative">
      <b-switch
        size="is-medium"
        @input="handleFeature($event)"
        v-model="organization.has_override_reimbursement"
      >
        Reimbursement Estimate Feature Override
      </b-switch>
    </div>

    <SubscriptionDetail :isForAdmin="true" :subscription="currentSubscription" />

    <div class="box" style="position: relative">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <h1 class="title">Subscriptions</h1>
          </div>
        </div>
      </div>
      <hr />
      <div v-if="subscriptions.length > 0">
        <AdminSubscriptionTable :subscriptions="subscriptions" />
      </div>
      <h1 v-else>No Subscriptions</h1>
    </div>

   <AdminOrganizationInviteList :organization="organization" />

    <div class="box" style="position: relative">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <h1 class="title">Users</h1>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <b-button
              type="is-primary is-light"
              tag="router-link"
              :to="{ name: 'AdminUserInvite' }"
            >
              Invite New User
            </b-button>
          </div>
        </div>
      </div>
      <hr />
      <AdminUserTable :users="users" />
    </div>
    <div class="box" style="position: relative">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <h1 class="title">Providers</h1>
          </div>
        </div>
      </div>
      <hr />
      <div class="box" style="position: relative">
        <b-switch
          size="is-medium"
          @input="handlePermProviderOverride($event)"
          v-model="organization.perm_provider_override"
          :disabled="perm_provider_override_clicked"
        >
          Organization Providers Override
        </b-switch>
      </div>

      <AdminProviderTable :providers="providers" />
    </div>
    <div class="box" style="position: relative">
      <b-loading :is-full-page="false" v-model="loading"></b-loading>

      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <h1 class="title">Inquiries</h1>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <b-field>
              <b-select
                v-model="selectedSince"
                placeholder="Last 7 Days"
                :loading="loading"
                @input="getInquiries()"
              >
                <option value="7">Last 7 Days</option>
                <option value="30">Last 30 Days</option>
                <option value="0">All Time</option>
              </b-select>
            </b-field>
          </div>
        </div>
      </div>
      <hr />
      <div>
        <InquiryTable :options="inquiries" :isForAdmin="true" />
      </div>
    </div>
  </AdminLayout>
</template>

<script>
import axios from 'axios';
import request from '../../request';

import InquiryTable from '../../components/InquiryTable.vue';
import AdminProviderTable from '../../components/AdminProviderTable.vue';
import AdminUserTable from '../../components/AdminUserTable.vue';
import AdminSubscriptionTable from '../../components/AdminSubscriptionTable.vue';
import AdminLayout from '../../layouts/AdminLayout.vue';
import SubscriptionDetail from '../../components/SubscriptionDetail';
import AdminOrganizationInviteList from './AdminOrganizationInviteList.vue';

export default {
  name: 'AdminOrganizationDetail',
  components: {
    AdminLayout,
    AdminUserTable,
    AdminSubscriptionTable,
    AdminProviderTable,
    InquiryTable,
    SubscriptionDetail,
    AdminOrganizationInviteList,
  },
  data() {
    return {
      loading: false,
      selectedSince: 7,
      organization: null,
      currentSubscription: null,
      subscriptions: [],
      users: [],
      providers: [],
      inquiries: [],
      perm_provider_override_clicked: null,
    };
  },
  created() {
    axios
      .all([
        this.getSubscriptions(),
        this.getOrganization(),
        this.getUsers(),
        this.getProviders(),
        this.getInquiries(),
      ])
      .finally(() => (this.loading = false));
  },
  methods: {
    getOrganization() {
      request.get(`/admin/organizations/${this.$route.params.id}`).then((resp) => {
        this.organization = resp.data.organization;
      });
    },
    getSubscriptions() {
      request
        .get(`/admin/subscriptions?organization_id=${this.$route.params.id}`)
        .then((resp) => (this.subscriptions = resp.data.subscriptions));
      request
        .get(`/admin/organizations/${this.$route.params.id}/current-subscription`)
        .then((resp) => (this.currentSubscription = resp.data.subscription));
    },
    getUsers() {
      request
        .get(`/admin/users?organization_id=${this.$route.params.id}`)
        .then((resp) => (this.users = resp.data.users));
    },
    getProviders() {
      request
        .get(`/admin/providers?organization_id=${this.$route.params.id}`)
        .then((resp) => (this.providers = resp.data.providers));
    },
    getInquiries() {
      this.loading = true;
      let url = `/admin/inquiries?organization_id=${this.$route.params.id}`;
      if (this.selectedSince > 0) {
        url = url + `&since=${this.selectedSince}`;
      }
      request
        .get(url)
        .then((resp) => (this.inquiries = resp.data.inquiries))
        .finally(() => (this.loading = false));
    },
    handlePermProviderOverride(isOverride) {
      this.perm_provider_override_clicked = true;
      request
        .put(`/admin/organizations/${this.$route.params.id}`, {
          perm_provider_override: isOverride,
        })
        .finally(() => (this.perm_provider_override_clicked = null));
    },
    handleFeature(event) {
      let action = event ? 'activate' : 'deactivate';
      let actionTitle = action.charAt(0).toUpperCase() + action.slice(1);
      let msg = `<p>This will ${action} Reimbursement Estimates.</p>`;
      this.$buefy.dialog.confirm({
        title: 'Reimbursement Estimates',
        message: msg,
        confirmText: actionTitle,
        type: 'is-success',
        onCancel: () => {
          // This ensures the switch returns to its original position on cancel.
          this.loading = true;
          this.getOrganization().finally(() => (this.loading = false));
        },
        onConfirm: () => {
          this.loading = true;
          request
            .put(`/admin/organizations/${this.$route.params.id}`, {
              has_override_reimbursement: event,
            })
            .then(() => {
              this.$buefy.toast.open({
                message: `You have ${action}d Reimbursements.`,
                type: 'is-success',
              });
            });
        },
      });
    },
  },
};
</script>
