<template>
  <section class="section">
    <div class="container is-max-desktop">
      <div class="has-text-centered mt-3">
        <p class="mb-5"><img src="/static/logo-wide.png" /></p>
      </div>
      <div class="block mt-3 mb-6">
        <div class="has-text-centered">
          <h3 class="title is-3">Pick your new password</h3>
          <h6 class="subtitle is-6 pt-1">
            Or
            <router-link :to="{ name: 'Login' }">login if you've remembered</router-link>
          </h6>
        </div>
      </div>
      <div class="columns">
        <div class="column is-three-fifths is-offset-one-fifth">
          <b-notification type="is-danger" role="alert" v-if="form.errors.has('NOFIELD')">
            {{ form.errors.get('NOFIELD') }}
          </b-notification>
          <div class="box is-clearfix">
            <form @submit.prevent="handleSubmit" novalidate>
              <div class="block">
                <b-field
                  label="New Password"
                  :message="form.errors.get('new_password1')"
                  :type="{ 'is-danger': form.errors.has('new_password1') }"
                >
                  <b-input type="password" v-model="form.new_password1" password-reveal />
                </b-field>

                <b-field
                  label="New Password Confirm"
                  :message="form.errors.get('new_password2')"
                  :type="{ 'is-danger': form.errors.has('new_password2') }"
                >
                  <b-input type="password" v-model="form.new_password2" password-reveal />
                </b-field>
              </div>

              <div class="block has-text-right">
                <b-button :loading="loading" type="is-primary" native-type="submit">
                  Change Password
                </b-button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Form from '../utils/form';

export default {
  name: 'PasswordResetConfirm',
  data() {
    return {
      loading: false,
      form: new Form({
        user_id: Number(this.$route.params.uid),
        token: this.$route.params.token,
        new_password1: '',
        new_password2: '',
      }),
    };
  },
  methods: {
    handleSubmit() {
      this.loading = true;
      this.form
        .post('/auth/password/reset/confirm')
        .then(() => {
          let msg = 'Your password has been reset.';
          this.$buefy.toast.open({ message: msg, type: 'is-success' });
          this.$router.push({ name: 'Login' });
        })
        .catch(() => {
          if (!this.form.errors.any()) {
            this.form.errors.setGenericError();
          }
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
