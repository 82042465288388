import request from '../request';
import Errors from './errors';

export default class Form {
  constructor(data) {
    this.errors = new Errors();

    this.originalData = data;
    for (let field in data) {
      this[field] = data[field];
    }
  }

  data() {
    let data = {};
    for (let property in this.originalData) {
      data[property] = this[property];
    }
    return data;
  }

  reset() {
    for (let field in this.original) {
      this[field] = this.original[field];
    }
    this.errors.clear();
  }

  post(url, extra) {
    return this.submit('post', url, extra);
  }

  put(url) {
    return this.submit('put', url);
  }

  patch(url) {
    return this.submit('patch', url);
  }

  delete(url) {
    return this.submit('delete', url);
  }

  // Submit the form with data if there is some
  submit(requestType, url, extra) {
    let data = this.data();
    if (extra) {
      data = Object.assign(data, extra);
    }

    return new Promise((resolve, reject) => {
      request[requestType](url, data)
        .then((response) => {
          this.onSuccess();
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.errors) {
            this.onFail(error.response.data.errors);
          }

          reject(error.response.data);
        });
    });
  }

  onSuccess() {
    this.reset();
  }

  onFail(errors) {
    this.errors.record(errors);
  }
}
