<template>
  <div class="container is-max-hd">
    <b-notification type="is-info" :closable="false">
      This inquiry has expired. Please
      <router-link :to="{ name: 'InquiryCopy', params: { id: inquiryId } }">
        Re-run Inquiry
      </router-link>
      or
      <router-link :to="{ name: 'InquiryList' }">Return to list</router-link>
    </b-notification>
  </div>
</template>

<script>
export default {
  name: 'InquiryExpired',
  props: {
    inquiryId: String,
  },
};
</script>
