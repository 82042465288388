<template>
  <div class="box" style="position: relative">
    <b-loading :is-full-page="false" v-model="loading"></b-loading>
    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <h1 class="title">Users</h1>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <b-button type="is-primary is-light" tag="router-link" :to="{ name: 'UserInvite' }">
            Invite New User
          </b-button>
        </div>
      </div>
    </div>
    <hr />
    <div>
      <b-table
        :data="users"
        sort-icon="chevron-up"
        sort-icon-size="is-small"
        :default-sort="['name', 'asc']"
        striped
        :paginated="users.length > 10"
        per-page="10"
        hoverable
      >
        <b-table-column field="first_name" label="Name" sortable v-slot="props">
          {{ props.row.first_name }} {{ props.row.last_name }}
        </b-table-column>

        <b-table-column field="email" sortable label="Email" v-slot="props">
          {{ props.row.email }}
        </b-table-column>

        <b-table-column field="membership.is_manager" sortable label="Manager" v-slot="props">
          <b-icon
            :type="props.row.membership.is_manager ? 'is-success' : 'is-danger'"
            :icon="props.row.membership.is_manager ? 'check-circle' : 'close-circle'"
            size="is-small"
          />
        </b-table-column>
        <b-table-column field="membership.is_restricted" sortable label="Restricted" v-slot="props">
          <b-icon
            :type="props.row.membership.is_restricted ? 'is-success' : 'is-danger'"
            :icon="props.row.membership.is_restricted ? 'check-circle' : 'close-circle'"
            size="is-small"
          />
        </b-table-column>
        <b-table-column
          field="membership.perm_reimbursements"
          sortable
          label="Can see reimbursements"
          v-slot="props"
        >
          <b-icon
            :type="props.row.membership.perm_reimbursements ? 'is-success' : 'is-danger'"
            :icon="props.row.membership.perm_reimbursements ? 'check-circle' : 'close-circle'"
            size="is-small"
          />
        </b-table-column>
        <b-table-column field="is_active" sortable label="Active" v-slot="props">
          <b-icon
            :type="props.row.is_active ? 'is-success' : 'is-danger'"
            :icon="props.row.is_active ? 'check-circle' : 'close-circle'"
            size="is-small"
          />
        </b-table-column>
        <b-table-column v-slot="props" numeric>
          <router-link size="is-small" :to="{ name: 'UserUpdate', params: { id: props.row.id } }">
            <b-icon icon="square-edit-outline" />
          </router-link>
        </b-table-column>
      </b-table>
    </div>
  </div>
</template>

<script>
import request from '../request';

export default {
  name: 'UserList',
  data() {
    return {
      loading: true,
      users: [],
    };
  },
  mounted() {
    this.getUsers();
  },
  methods: {
    getUsers() {
      this.loading = true;
      request
        .get('/users')
        .then((resp) => (this.users = resp.data.users))
        .finally(() => (this.loading = false));
    },
  },
};
</script>
