<template>
  <section class="section">
    <div class="container is-max-desktop">
      <div class="has-text-centered mt-3">
        <p class="mb-5"><img src="/static/logo-wide.png" /></p>
      </div>
      <div class="block mt-3 mb-6">
        <div class="has-text-centered">
          <h3 class="title is-3">Sign in to your account</h3>
          <h6 class="subtitle is-6 pt-1">
            Or
            <router-link :to="{ name: 'Register' }">register your organization now</router-link>
          </h6>
        </div>
      </div>
      <div class="columns">
        <div class="column is-three-fifths is-offset-one-fifth">
          <div class="box">
            <div class="block">
              <LoginForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import LoginForm from '../components/LoginForm.vue';

export default {
  components: {
    LoginForm,
  },
  computed: {
    ...mapGetters(['isLoggedIn']),
  },
};
</script>
