export default class Errors {
  constructor() {
    this.errors = {};
  }
  any() {
    return Object.keys(this.errors).length > 0;
  }
  has(field) {
    return field in this.errors;
  }
  get(field) {
    if (this.has(field)) {
      return this.errors[field];
    }
  }
  record(errors) {
    this.errors = errors;
  }
  clear(field) {
    if (field) {
      delete this.errors[field];
    } else {
      this.errors = {};
    }
  }
  setGenericError() {
    this.errors.NOFIELD = 'There seems to be an issue. Please try again later.';
  }
}
