<template>
  <AdminLayout>
    <template #breadcrumb>
      <breadcrumb-link view="AdminProviderList" label="Providers" :active="true" />
    </template>

    <div class="box" style="position: relative">
      <b-loading :is-full-page="false" v-model="loading"></b-loading>

      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <h1 class="title">Manage Providers</h1>
          </div>
        </div>
      </div>
      <hr />

      <b-table
        v-if="providerOptions"
        :data="providerOptions"
        sort-icon="chevron-up"
        sort-icon-size="is-small"
        :default-sort="['name', 'asc']"
        :paginated="providerOptions.length > 50"
        per-page="50"
        hoverable
        striped
      >
        <b-table-column field="name" label="Name" sortable searchable v-slot="props">
          {{ props.row.name }}
        </b-table-column>

        <b-table-column field="npi" label="NPI" v-slot="props">
          {{ props.row.npi }}
        </b-table-column>

        <b-table-column field="tax_id" label="Tax ID" v-slot="props">
          {{ props.row.tax_id }}
        </b-table-column>

        <b-table-column
          field="organization.name"
          label="Organization"
          sortable
          searchable
          v-slot="props"
        >
          {{ props.row.organization.name }}
        </b-table-column>

        <b-table-column field="is_active" label="Active" sortable v-slot="props">
          <b-icon
            :type="props.row.is_active ? 'is-success' : 'is-danger'"
            :icon="props.row.is_active ? 'check-circle' : 'close-circle'"
            size="is-small"
          />
        </b-table-column>

        <b-table-column field="is_override" label="Override" sortable v-slot="props">
          <b-switch
            type="is-success"
            @input="handleIsOverrideSwitch($event, props.row.id)"
            :value="props.row.is_override"
            :disabled="indexClicked === props.row.id"
          />
        </b-table-column>
      </b-table>
    </div>
  </AdminLayout>
</template>

<script>
import request from '../../request';
import AdminLayout from '../../layouts/AdminLayout.vue';

export default {
  name: 'AdminProviderList',
  components: {
    AdminLayout,
  },

  data() {
    return {
      loading: true,
      providerOptions: [],
      indexClicked: null,
    };
  },
  created() {
    this.getProviders();
  },
  methods: {
    getProviders() {
      this.loading = true;
      request
        .get('/admin/providers')
        .then((resp) => (this.providerOptions = resp.data.providers))
        .finally(() => (this.loading = false));
    },
    handleIsOverrideSwitch(isOverride, id) {
      this.indexClicked = id;
      request
        .put(`/admin/providers/${id}`, { is_override: isOverride })
        .finally(() => (this.indexClicked = null));
    },
  },
};
</script>
