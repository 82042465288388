<template>
  <div>
    <breadcrumb-list>
      <breadcrumb-link view="Home" label="Home" />
      <breadcrumb-link view="OrganizationDetail" :label="organization" />
      <breadcrumb-link label="Invite User" :active="true" />
    </breadcrumb-list>

    <section class="section">
      <div class="container is-max-desktop">
        <div class="columns">
          <div class="column is-three-fifths is-offset-one-fifth">
            <b-notification type="is-danger" role="alert" v-if="form.errors.has('NOFIELD')">
              {{ form.errors.get('NOFIELD') }}
            </b-notification>

            <div class="box">
              <div class="block">
                <div class="has-text-centered">
                  <h1 class="subtitle is-3 has-text-primary">Invite User</h1>
                </div>
              </div>
              <form @submit.prevent="handleSubmit" novalidate>
                <div class="block">
                  <b-field
                    label="Email Address"
                    :message="form.errors.get('email')"
                    :type="{ 'is-danger': form.errors.has('email') }"
                  >
                    <b-input v-model="form.email"></b-input>
                  </b-field>
                </div>

                <template>
                  <section>
                    <b-field>
                      <section class="levels">
                        <b-radio v-model="levelContent" name="manager" native-value="manager">
                          <b>Manager</b>
                        </b-radio>
                        <p>Can manage account and users</p>
                      </section>
                    </b-field>

                    <b-field>
                      <section class="levels">
                        <b-radio
                          v-model="levelContent"
                          name="standard_user"
                          native-value="standard_user"
                        >
                          <b>Standard User</b>
                        </b-radio>
                        <p>Can see all inquries for the organization</p>
                      </section>
                    </b-field>

                    <b-field>
                      <section class="levels">
                        <b-radio
                          v-model="levelContent"
                          name="restricted_user"
                          native-value="restricted_user"
                        >
                          <b>Restricted User</b>
                        </b-radio>
                        <p>Can only see inquries they've created</p>
                      </section>
                    </b-field>
                  </section>
                </template>
                <br />

                <div class="block">
                  <b-field
                    message="Give this user the ability to see reimbursement estimates, if the feature is enabled."
                  >
                    <b-switch
                      size="is-medium"
                      v-model="form.perm_reimbursements"
                      :type="form.errors.has('perm_reimbursements') ? 'is-danger' : ''"
                      :message="form.errors.get('perm_reimbursements')"
                    >
                      Can see reimbursement estimates
                    </b-switch>
                  </b-field>
                </div>

                <p class="block">
                  This user will be sent an invitiation. They will have access to submit inquiries
                  for all of your providers.
                </p>

                <div class="has-text-right">
                  <b-button type="is-primary" native-type="submit" :loading="loading">
                    Invite User
                  </b-button>
                </div>
              </form>
            </div>
            <div class="has-text-centered">
              <b-button
                type="is-primary is-outlined"
                tag="router-link"
                :to="{ name: 'OrganizationDetail' }"
              >
                Nevermind, take me back.
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Form from '../utils/form';

export default {
  data() {
    return {
      loading: false,
      error: '',
      form: new Form({
        email: '',
        is_manager: false,
        is_restricted: false,
        perm_reimbursements: false,
      }),
      levelContent: 'restricted_user',
    };
  },
  computed: {
    ...mapGetters(['organization']),
  },
  methods: {
    handleSubmit() {
      this.loading = true;

      if (this.levelContent === 'manager') {
        this.form.is_manager = true;
        this.form.is_restricted = false;
      } else if (this.levelContent === 'standard_user') {
        this.form.is_manager = false;
        this.form.is_restricted = false;
      } else if (this.levelContent === 'restricted_user') {
        this.form.is_manager = false;
        this.form.is_restricted = true;
      }

      this.form
        .post('/invites')
        .then(() => {
          let msg = 'An invitation email has been sent.';
          this.$buefy.toast.open({ message: msg, type: 'is-success' });
          this.$router.push({ name: 'OrganizationDetail' });
        })
        .catch(() => {
          if (!this.form.errors.any()) {
            this.form.errors.setGenericError();
          }
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style scoped>
.levels p {
  font-size: 0.75rem;
}
</style>
