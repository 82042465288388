<template>
  <section>
    <b-table
      v-if="subscriptions"
      :data="subscriptions"
      sort-icon="chevron-up"
      sort-icon-size="is-small"
      :paginated="subscriptions.length > 50"
      :default-sort="['status', 'asc']"
      per-page="50"
      hoverable
      striped
    >
      <b-table-column field="id" label="ID" sortable v-slot="props">
        {{ props.row.id }}
      </b-table-column>

      <b-table-column field="remote_id" label="Stripe ID" sortable v-slot="props">
        <a
          target="_blank"
          :href="'https://dashboard.stripe.com/subscriptions/' + props.row.remote_id"
        >
          {{ props.row.remote_id }}
        </a>
      </b-table-column>

      <b-table-column label="Next Bill Date" sortable v-slot="props">
        {{ props.row.current_period_ends_at | formatDate }}
      </b-table-column>

      <b-table-column field="has_feature_reimbursement" label="Has Reimbursements" v-slot="props">
        <span v-if="props.row.has_feature_reimbursement">
          <b-icon type="is-success" icon="check-circle" size="is-small" />
        </span>
      </b-table-column>

      <b-table-column field="status" label="Status" sortable v-slot="props">
        <span
          :class="{
            tag: true,
            'is-success': props.row.is_active,
            'is-warning': props.row.status == 'past_due' || props.row.status == 'unpaid',
            'is-danger': !props.row.is_active,
          }"
        >
          {{ props.row.status }}
        </span>
      </b-table-column>
    </b-table>
  </section>
</template>

<script>
export default {
  name: 'AdminSubscriptionTable',
  props: {
    subscriptions: Array,
  },
};
</script>
