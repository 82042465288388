<template>
  <AdminLayout v-if="organization">
    <template #breadcrumb>
      <breadcrumb-link view="AdminOrganizationList" label="Organizations" />
      <breadcrumb-link view="OrganizationDetail" :label="organization.name" />
      <breadcrumb-link label="Invite User" :active="true" />
    </template>

    <section class="section">
      <div class="container is-max-desktop">
        <div class="columns">
          <div class="column is-three-fifths is-offset-one-fifth">
            <b-notification type="is-danger" role="alert" v-if="form.errors.has('NOFIELD')">
              {{ form.errors.get('NOFIELD') }}
            </b-notification>
            <div class="box">
              <div class="block">
                <div class="has-text-centered">
                  <h1 class="subtitle is-3 has-text-primary">Invite User</h1>
                </div>
              </div>
              <form @submit.prevent="handleSubmit" novalidate>
                <div class="block">
                  <b-field
                    label="Email Address"
                    :message="form.errors.get('email')"
                    :type="{ 'is-danger': form.errors.has('email') }"
                  >
                    <b-input v-model="form.email"></b-input>
                  </b-field>
                </div>

                <div class="block">
                  <b-field
                    message="As a manager they will be able to manage users, providers and
                             billing"
                  >
                    <b-switch
                      size="is-medium"
                      v-model="form.is_manager"
                      :type="form.errors.has('is_manager') ? 'is-danger' : ''"
                      :message="form.errors.get('is_manager')"
                    >
                      Is Manager
                    </b-switch>
                  </b-field>
                </div>

                <div class="block">
                  <b-field
                    message="Give this user the ability to see reimbursement estimates, if the feature is enabled."
                  >
                    <b-switch
                      size="is-medium"
                      v-model="form.perm_reimbursements"
                      :type="form.errors.has('perm_reimbursements') ? 'is-danger' : ''"
                      :message="form.errors.get('perm_reimbursements')"
                    >
                      Can see reimbursement estimates
                    </b-switch>
                  </b-field>
                </div>

                <p class="block">
                  This user will be sent an invitiation. They will have access to submit inquiries
                  for all of your providers.
                </p>

                <div class="has-text-right">
                  <b-button type="is-primary" native-type="submit" :loading="loading">
                    Invite User
                  </b-button>
                </div>
              </form>
            </div>
            <div class="has-text-centered">
              <b-button
                type="is-primary is-outlined"
                tag="router-link"
                :to="{
                  name: 'AdminOrganizationDetail',
                  params: { id: organization.id },
                }"
              >
                Nevermind, take me back.
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </AdminLayout>
</template>
<script>
import request from '../../request';
import Form from '../../utils/form';
import AdminLayout from '../../layouts/AdminLayout.vue';

export default {
  components: {
    AdminLayout,
  },

  data() {
    return {
      organization: null,
      loading: false,
      error: '',
      form: new Form({
        email: '',
        is_manager: false,
        perm_reimbursements: false,
        organization_id: this.$route.params.id,
      }),
    };
  },
  created() {
    this.getOrganization();
  },
  methods: {
    getOrganization() {
      request.get(`/admin/organizations/${this.$route.params.id}`).then((resp) => {
        this.organization = resp.data.organization;
      });
    },
    handleSubmit() {
      this.loading = true;
      this.form
        .post(`/admin/invites`)
        .then(() => {
          let msg = 'An invitation email has been sent.';
          this.$buefy.toast.open({ message: msg, type: 'is-success' });
          this.$router.push({
            name: 'AdminOrganizationDetail',
            params: { id: this.organization.id },
          });
        })
        .catch(() => {
          if (!this.form.errors.any()) {
            this.form.errors.setGenericError();
          }
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
