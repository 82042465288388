<template>
  <section>
    <label class="label mb-3">
      {{ schema.label }}
    </label>
    <b-radio
      v-for="opt in schema.options"
      v-model="content"
      :key="opt.id"
      :name="schema.name"
      :native-value="opt.id"
      @input="$emit('input', content)"
      :type="errors && errors.length ? 'is-danger' : ''"
      :class="{ 'has-text-danger': errors ? errors.length : false }"
    >
      {{ opt.name }}
    </b-radio>
    <p v-if="errors ? errors.length : false" class="help is-danger">{{ errors }}</p>
  </section>
</template>

<script>
export default {
  data() {
    return {
      content: this.value,
    };
  },
  props: {
    value: String,
    schema: Object,
    errors: String,
    helpText: String,
  },
};
</script>
