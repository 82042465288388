<template>
  <div class="box" style="position: relative">
    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <h1 class="title is-3">Current Subscription</h1>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <b-button
            v-if="!isForAdmin"
            type="is-primary is-light"
            tag="router-link"
            :to="{ name: 'PlanList' }"
          >
            Change Plan
          </b-button>
        </div>
      </div>
    </div>
    <hr />
    <div v-if="subscription">
      <div class="columns">
        <div class="column">
          <p class="title is-4">Plan</p>
          <div class="block">
            <strong class="has-text-info">
              {{ subscription.plan_name }}
              <span v-if="subscription.has_feature_reimbursement">(w/ Reimbursements)</span>
            </strong>
            <br />
            Billed Monthly
            <br />
            <span>
              Inquiries: {{ subscription.used_inquiries }} used of
              {{ subscription.plan_base_inquiries }}
            </span>
          </div>
        </div>
        <div class="column">
          <p class="title is-4">Subscription</p>
          <div v-if="subscription.cancel_at_period_end">
            <strong class="has-text-danger">Your subscription will not renew.</strong>
            <br />
            Subscription ends: {{ subscription.cancel_at | formatDate }}
          </div>
          <div v-else>
            Your subscription will renew automatically.
            <br />
            Next bill date: {{ subscription.next_bill_date | formatDate }}
          </div>
        </div>
        <div class="column">
          <p class="title is-4">Payment Method</p>
          Type: {{ subscription.payment_method }}
          <br />
          <div v-if="subscription.payment_method.toLowerCase() == 'card'">
            {{ subscription.card_brand | title }} •••• {{ subscription.card_last4 }}
            <br />
            {{ subscription.card_holder }}
            <span v-if="subscription.card_holder">|</span>
            Expires: {{ subscription.card_exp_month }}/{{ subscription.card_exp_year }}
            <br />
          </div>
          <a href="#" v-if="!isForAdmin" @click="onManageSubscription">Update Payment Method</a>
          |
          <a href="#" v-if="!isForAdmin" @click="onManageSubscription">View Reciepts</a>
        </div>
      </div>
    </div>
    <div v-else>No Active Subscrition</div>
  </div>
</template>

<script>
import request from '../request';

export default {
  name: 'SubscriptionDetail',
  props: {
    isForAdmin: {
      type: Boolean,
      default: false,
    },
    subscription: {
      required: false,
      type: Object,
    },
  },
  methods: {
    onManageSubscription() {
      this.loading = true;
      request.post('/subscriptions/portal-session').then((resp) => {
        window.location.href = resp.data.redirect_url;
      });
    },
  },
};
</script>
