<template>
  <div>
    <breadcrumb-list>
      <breadcrumb-link view="Home" label="Home" />
      <breadcrumb-link view="OrganizationDetail" :label="organization" />
      <breadcrumb-link view="ProviderCreate" label="New Provider" :active="true" />
    </breadcrumb-list>

    <form-base
      title="Create Provider"
      cancel="OrganizationDetail"
      :error="form.errors.get('NOFIELD')"
      @submit="handleSubmit"
    >
      <template v-slot:fields>
        <div class="block">
          <form-input :form="form" label="Provider Name" field-name="name" />
        </div>

        <div class="block">
          <form-input :form="form" label="Provider ID (NPI)" field-name="npi" />
        </div>

        <div class="block">
          <form-input :form="form" label="Tax ID" field-name="tax_id" />
        </div>
      </template>
    </form-base>
  </div>
</template>

<script>
import Form from '../utils/form';
import FormBase from '../components/FormBase.vue';
import FormInput from '../components/FormInput.vue';

import { mapGetters } from 'vuex';

export default {
  name: 'ProviderCreate',
  components: { FormBase, FormInput },
  data() {
    return {
      loading: false,
      form: new Form({
        name: '',
        npi: '',
        tax_id: '',
      }),
    };
  },
  computed: {
    ...mapGetters(['organization']),
  },
  methods: {
    handleSubmit() {
      this.loading = true;
      this.form
        .post('/providers')
        .then(() => {
          let msg = `${this.form.name} was added successfully.`;
          this.$buefy.toast.open({ message: msg, type: 'is-success' });
          this.$router.push({ name: 'OrganizationDetail' });
        })
        .catch(() => {})
        .finally(() => (this.loading = false));
    },
  },
};
</script>
