<template>
  <AdminLayout>
    <template #breadcrumb>
      <breadcrumb-link view="AdminLAPrefixList" label="Local Allowable Prefixes" :active="true" />
    </template>

    <div class="box">
      <b-loading :is-full-page="false" v-model="loading"></b-loading>
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <h1 class="title">Manage Local Allowable Prefixes</h1>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <b-button
              type="is-primary is-outlined"
              tag="router-link"
              icon-left="cloud-upload"
              :to="{ name: 'AdminLAPrefixUpload' }"
            >
              Upload LA Prefixes
            </b-button>
          </div>
        </div>
      </div>
      <hr />

      <div class="block">
        <b-table
          :data="options"
          sort-icon="chevron-up"
          sort-icon-size="is-small"
          :default-sort="['alpha_prefix', 'asc']"
          striped
          :paginated="options.length > 50"
          per-page="50"
          hoverable
        >
          <b-table-column
            field="alpha_prefix"
            label="Alpha Prefix"
            searchable
            sortable
            v-slot="props"
          >
            {{ props.row.alpha_prefix }}
          </b-table-column>

          <b-table-column
            field="member_id"
            sortable
            searchable
            label="Original Member ID"
            v-slot="props"
          >
            {{ props.row.member_id }}
          </b-table-column>

          <b-table-column field="is_active" label="Active" sortable v-slot="props">
            <b-switch
              type="is-success"
              @input="handleIsActiveSwitch($event, props.row.id)"
              :value="props.row.is_active"
              :disabled="indexClicked === props.row.id"
            />
          </b-table-column>

          <template #bottom-left>
            <b>Total</b>
            : {{ options.length }}
          </template>
        </b-table>
      </div>
    </div>
  </AdminLayout>
</template>

<script>
import request from '../../request';
import AdminLayout from '../../layouts/AdminLayout.vue';

export default {
  name: 'AdminLAPrefixList',
  components: {
    AdminLayout,
  },
  data() {
    return {
      loading: true,
      options: [],
      indexClicked: null,
    };
  },
  created() {
    this.getLAPrefixes();
  },
  methods: {
    getLAPrefixes() {
      return request
        .get('/admin/la-prefixes')
        .then((resp) => (this.options = resp.data.la_prefixes))
        .then(() => (this.loading = false));
    },
    handleIsActiveSwitch(isActive, id) {
      this.indexClicked = id;
      request
        .put(`/admin/la-prefixes/${id}`, { is_active: isActive })
        .finally(() => (this.indexClicked = null));
    },
  },
};
</script>
