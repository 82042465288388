import { render, staticRenderFns } from "./InquiryDetailBenefitAmountStatus.vue?vue&type=template&id=d31cf968&scoped=true"
import script from "./InquiryDetailBenefitAmountStatus.vue?vue&type=script&lang=js"
export * from "./InquiryDetailBenefitAmountStatus.vue?vue&type=script&lang=js"
import style0 from "./InquiryDetailBenefitAmountStatus.vue?vue&type=style&index=0&id=d31cf968&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d31cf968",
  null
  
)

export default component.exports