<template>
  <b-field
    :label="label"
    :message="form.errors.get(fieldName)"
    :type="{ 'is-danger': form.errors.has(fieldName) }"
  >
    <!-- eslint-disable-next-line -->
    <b-input :disabled="disabled" v-model="form[fieldName]" type="textarea" />
  </b-field>
</template>

<script>
export default {
  name: 'form-input',
  props: {
    label: String,
    form: Object,
    fieldName: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
