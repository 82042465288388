<template>
  <b-table
    :data="providers"
    sort-icon="chevron-up"
    sort-icon-size="is-small"
    :default-sort="['name', 'asc']"
    striped
    :paginated="providers.length > 10"
    per-page="10"
    hoverable
  >
    <b-table-column field="name" label="Name" v-slot="props">
      {{ props.row.name }}
    </b-table-column>

    <b-table-column field="npi" label="NPI" v-slot="props">
      {{ props.row.npi }}
    </b-table-column>

    <b-table-column field="tax_id" label="Tax ID" v-slot="props">
      {{ props.row.tax_id }}
    </b-table-column>

    <b-table-column field="is_active" label="Active" v-slot="props">
      <b-icon
        :type="props.row.is_active ? 'is-success' : 'is-danger'"
        :icon="props.row.is_active ? 'check-circle' : 'close-circle'"
        size="is-small"
      />
    </b-table-column>

    <b-table-column field="is_override" label="Override" sortable v-slot="props">
      <b-switch
        type="is-success"
        @input="handleIsOverrideSwitch($event, props.row.id)"
        :value="props.row.is_override"
        :disabled="indexClicked === props.row.id"
      />
    </b-table-column>
  </b-table>
</template>

<script>
import request from '../request';

export default {
  name: 'AdminProviderTable',
  props: {
    providers: Array,
  },
  data() {
    return {
      indexClicked: null,
    };
  },
  methods: {
    handleIsOverrideSwitch(isOverride, id) {
      this.indexClicked = id;
      request
        .put(`/admin/providers/${id}`, { is_override: isOverride })
        .finally(() => (this.indexClicked = null));
    },
  },
};
</script>
