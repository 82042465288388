// Constants used in the Inquiry payloads

export const possibleNetworks = [
  { label: 'In Network', key: 'inNetwork' },
  { label: 'Out Of Network', key: 'outOfNetwork' },
  { label: 'No Network', key: 'noNetwork' },
  { label: 'N/A', key: 'notApplicableNetwork' },
];

export const possiblePayments = [
  { label: 'Co-Insurance', key: 'coInsurance' },
  { label: 'Co-Payment', key: 'coPayment' },
  { label: 'Deductible', key: 'deductibles' },
  { label: 'Out Of Pocket', key: 'outOfPocket' },
];

export const possibleBenefitItems = [
  { label: 'Status Details', key: 'statusDetails', auto: false },
  {
    label: 'Benefit Descriptions',
    key: 'benefitDescriptions',
    auto: true,
    type: 'has-text-info',
  },
  {
    label: 'Cost Containment',
    key: 'costContainment',
    auto: true,
    type: 'has-text-success',
  },
  { label: 'Coverage Basis', key: 'coverageBasis', auto: true, type: 'has-text-info' },
  { label: 'Exclusions', key: 'exclusions', auto: true, type: 'has-text-danger' },
  { label: 'Limitations', key: 'limitations', auto: true, type: 'has-text-danger' },
  { label: 'Non Covered', key: 'nonCovered', auto: true, type: 'has-text-danger' },
  {
    label: 'Preexisting Conditions',
    key: 'preexistingConditions',
    auto: true,
    type: 'has-text-danger',
  },
  { label: 'Reserve', key: 'reserve', auto: true, type: 'has-text-info' },
];
