<template>
  <AdminLayout>
    <template #breadcrumb>
      <breadcrumb-link view="AdminInviteList" label="Invites" :active="true" />
    </template>

    <div class="box" style="position: relative">
      <b-loading :is-full-page="false" v-model="loading"></b-loading>

      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <h1 class="title is-3">Invites</h1>
          </div>
        </div>
      </div>
      <hr />
      <b-table
        :data="inviteOptions"
        sort-icon="chevron-up"
        sort-icon-size="is-small"
        :default-sort="['name', 'asc']"
        striped
        :paginated="inviteOptions.length > 10"
        per-page="10"
        hoverable
      >
        <b-table-column label="Invitee Email" v-slot="props">
          {{ props.row.email }}
        </b-table-column>

        <b-table-column label="Invited By" v-slot="props">
          {{ props.row.user.first_name }} {{ props.row.user.last_name }}
        </b-table-column>

        <b-table-column field="created_at" label="Invited Date" v-slot="props">
          {{ new Date(props.row.created_at).toLocaleDateString() }}
        </b-table-column>

        <b-table-column field="status" label="Status" v-slot="props">
          <span v-if="props.row.revoked_at">Revoked {{ props.row.revoked }}</span>
          <span v-else-if="props.row.accepted_at">Accepted</span>
          <span v-else>Pending</span>
        </b-table-column>

        <b-table-column numeric v-slot="props">
          <a
            v-if="!props.row.revoked_at && !props.row.accepted_at"
            href="#"
            class="has-text-danger"
            @click.prevent="handleRevoke(props.row)"
          >
            Revoke
          </a>
          <a
            v-if="!props.row.revoked_at && !props.row.accepted_at"
            href="#"
            class="has-text-info"
            @click.prevent="handleResend(props.row)"
          >
            Resend
          </a>
        </b-table-column>
      </b-table>
    </div>
  </AdminLayout>
</template>

<script>
import request from '../../request';
import AdminLayout from '../../layouts/AdminLayout.vue';

export default {
  name: 'AdminInviteList',
  components: {
    AdminLayout,
  },

  data() {
    return {
      loading: true,
      inviteOptions: [],
    };
  },
  created() {
    Promise.all([
      request.get('/admin/invites').then((resp) => (this.inviteOptions = resp.data.invites)),
    ]).finally(() => (this.loading = false));
  },
  methods: {
    handleRevoke(invite) {
      this.$buefy.dialog.confirm({
        message: `Are you sure you want to revoke invitation for "${invite.email}"?`,
        onConfirm: () => {
          request
            .put(`/admin/invites/${invite.id}`, { revoke: true })
            .then(() => {
              return request
                .get('/admin/invites')
                .then((resp) => (this.inviteOptions = resp.data.invites));
            })
            .finally(() => {
              let msg = 'Invitation revoked!';
              this.$buefy.toast.open({ message: msg, type: 'is-success' });
            });
        },
      });
    },
    handleResend(invite) {
      this.$buefy.dialog.confirm({
        message: `Are you sure you want to resend the invitation for "${invite.email}"?`,
        onConfirm: () => {
          request.put(`/admin/invites/${invite.id}`, { resend: true }).finally(() => {
            let msg = 'Invitation sent!';
            this.$buefy.toast.open({ message: msg, type: 'is-success' });
          });
        },
      });
    },
  },
};
</script>
