<template>
  <section class="section">
    <div class="container is-max-hd">
      <div class="has-text-centered mt-3">
        <p class="mb-5"><img src="/static/logo-wide.png" /></p>
      </div>
      <div class="block mt-3 mb-6">
        <div class="has-text-centered">
          <h3 class="title is-3">Register you organization</h3>
          <h6 class="subtitle is-6 pt-1">
            Or
            <router-link :to="{ name: 'Login' }">login if you're already registerd</router-link>
          </h6>
        </div>
      </div>
      <hr />
      <form @submit.prevent="onSubmit" novalidate>
        <b-notification type="is-danger" role="alert" v-if="form.errors.has('NOFIELD')">
          {{ form.errors.get('NOFIELD') }}
        </b-notification>

        <div class="columns">
          <div class="column is-4 is-offset-1">
            <h4 class="title is-4 has-text-grey-darker">Organization Information</h4>
            <h6 class="subtitle is-6 has-text-grey-darker">
              Your organization is your company/team's account. An organization can have multiple
              users and providers.
            </h6>
          </div>
          <div class="column is-6">
            <div class="box">
              <b-field
                label="Organization Name"
                :message="
                  form.errors.get('organization_name') || 'This is your organization or team name.'
                "
                :type="{ 'is-danger': form.errors.has('organization_name') }"
              >
                <b-input
                  placeholder="Acme Organization, Inc."
                  v-model="form.organization_name"
                ></b-input>
              </b-field>
            </div>
            <div class="is-size-6 mt-5 has-text-centered">
              <b-tooltip
                label="As the organization manager you will be able to manage users, providers and billing"
              >
                You'll be the QuickAdmit organization manager since you're creating the account.
                <b-icon icon="information" size="is-small" />
              </b-tooltip>
            </div>
          </div>
        </div>
        <hr />
        <div class="columns">
          <div class="column is-4 is-offset-1">
            <h4 class="title is-4 has-text-grey-darker">User Information</h4>
            <h6 class="subtitle is-6 has-text-grey-darker">
              This is your personal information for your user account. Your user account will belong
              to the organizational account.
            </h6>
          </div>
          <div class="column is-6">
            <div class="box">
              <div class="block">
                <div class="columns">
                  <div class="column">
                    <b-field
                      label="First Name"
                      :message="form.errors.get('first_name')"
                      :type="{ 'is-danger': form.errors.has('first_name') }"
                    >
                      <b-input v-model="form.first_name"></b-input>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field
                      label="Last Name"
                      :message="form.errors.get('last_name')"
                      :type="{ 'is-danger': form.errors.has('last_name') }"
                    >
                      <b-input v-model="form.last_name"></b-input>
                    </b-field>
                  </div>
                </div>
              </div>
              <div class="block">
                <div class="columns">
                  <div class="column">
                    <b-field
                      label="Email"
                      :message="form.errors.get('email')"
                      :type="{ 'is-danger': form.errors.has('email') }"
                    >
                      <b-input v-model="form.email"></b-input>
                    </b-field>
                  </div>
                  <div class="column">
                    <PhoneField v-model="form.phone" :errors="form.errors" />
                  </div>
                </div>
              </div>
              <div class="block">
                <b-field
                  label="Password"
                  :message="form.errors.get('password')"
                  :type="{ 'is-danger': form.errors.has('password') }"
                >
                  <b-input type="password" v-model="form.password" password-reveal></b-input>
                </b-field>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div class="columns">
          <div class="column is-4 is-offset-1">
            <h4 class="title is-4 has-text-grey-darker">Facility Information</h4>
            <h6 class="subtitle is-6 has-text-grey-darker">
              This information is for your first facility. You can add more facilities once your
              organization is created.
            </h6>
          </div>
          <div class="column is-6">
            <div class="box">
              <div class="block">
                <div class="block">
                  <b-field
                    label="Facility Name (for your reference)"
                    :message="form.errors.get('provider_name')"
                    :type="{ 'is-danger': form.errors.has('provider_name') }"
                  >
                    <b-input v-model="form.provider_name"></b-input>
                  </b-field>
                </div>
                <div class="block">
                  <b-field
                    label="National Provider ID (NPI)"
                    :message="form.errors.get('provider_npi')"
                    :type="{ 'is-danger': form.errors.has('provider_npi') }"
                  >
                    <b-input v-model="form.provider_npi"></b-input>
                  </b-field>
                </div>
                <div class="block">
                  <b-field
                    label="Tax ID"
                    :message="form.errors.get('provider_tax_id')"
                    :type="{ 'is-danger': form.errors.has('provider_tax_id') }"
                  >
                    <b-input v-model="form.provider_tax_id"></b-input>
                  </b-field>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div class="columns mb-3">
          <div class="column is-6 is-offset-5">
            <div class="">
              <div>
                <b-checkbox
                  v-model="form.agree"
                  class="pt-2"
                  :type="form.errors.has('agree') ? 'is-danger' : ''"
                  :class="{ 'has-text-danger': form.errors.has('agree') }"
                >
                  I agree to the
                  <router-link :to="{ name: 'TermsAndConditions' }">
                    Terms and Conditions
                  </router-link>
                </b-checkbox>
                <b-button
                  native-type="submit"
                  type="is-primary"
                  :loading="loading"
                  class="is-pulled-right"
                >
                  Register Organization
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import Form from '../utils/form';
import PhoneField from '../components/fields/PhoneField.vue';
import request from '../request';

export default {
  name: 'Register',
  components: {
    PhoneField,
  },
  data() {
    return {
      activeStep: 0,
      loading: false,
      form: new Form({
        organization_name: '',
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        password: '',
        provider_name: '',
        provider_npi: '',
        provider_tax_id: '',
        agree: false,
      }),
    };
  },
  beforeRouteEnter(to, from, next) {
    // get (id) query param
    const id = to.query.id;
    if (id && id !== '') {
      request.get(`/auth/register/info/${id}`).then((resp) => {
        next((vm) => {
          vm.populateForm(resp.data.registration_info);
        });
      });
      return;
    }
    next();
  },
  methods: {
    onSubmit() {
      this.loading = true;
      this.form
        .post('/auth/register')
        .then(() => {
          this.$buefy.toast.open({
            message: "You've registered and been logged in!",
            type: 'is-success',
          });
          window.location.href = '/organization/settings';
        })
        .catch(() => {
          if (!this.form.errors.any()) {
            this.form.errors.errors.NOFIELD = 'An unknown error occured. Please try again.';
          }
        })
        .finally(() => (this.loading = false));
    },
    populateForm(data) {
      this.form.first_name = data.first_name;
      this.form.last_name = data.last_name;
      this.form.email = data.email;
      this.form.phone = data.phone;
      this.form.organization_name = data.organization_name;
      this.form.provider_name = data.provider_name;
      this.form.provider_npi = data.provider_npi;
      this.form.provider_tax_id = data.provider_tax_id;
    },
  },
};
</script>
<style>
hr {
  background-color: #e7ebe5;
  height: 1px;
}
</style>
