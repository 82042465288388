<template>
  <section>
    <b-field>
      <b-input v-model="search" placeholder="Search" />
    </b-field>

    <b-table
      v-if="users"
      :data="filteredOpts"
      sort-icon="chevron-up"
      sort-icon-size="is-small"
      :default-sort="['name', 'asc']"
      :paginated="users.length > 50"
      per-page="50"
      hoverable
      striped
    >
      <b-table-column label="Name" sortable v-slot="props">
        {{ props.row.first_name }} {{ props.row.last_name }}
      </b-table-column>

      <b-table-column field="email" label="Email" sortable v-slot="props">
        {{ props.row.email }}
      </b-table-column>

      <b-table-column field="phone" label="Phone" sortable v-slot="props">
        {{ props.row.phone }}
      </b-table-column>

      <b-table-column field="organization.name" label="Organization" sortable v-slot="props">
        <span v-if="props.row.organization">
          {{ props.row.organization.name }}
        </span>
      </b-table-column>

      <b-table-column field="last_login" label="Last Login" sortable v-slot="props">
        {{ props.row.last_login | formatDateTime }}
      </b-table-column>

      <b-table-column field="membership.is_manager" sortable label="Manager" v-slot="props">
        <b-icon
          :type="props.row.membership.is_manager ? 'is-success' : 'is-danger'"
          :icon="props.row.membership.is_manager ? 'check-circle' : 'close-circle'"
          size="is-small"
        />
      </b-table-column>

      <b-table-column field="membership.is_restricted" sortable label="Restricted" v-slot="props">
        <b-icon
          :type="props.row.membership.is_restricted ? 'is-success' : 'is-danger'"
          :icon="props.row.membership.is_restricted ? 'check-circle' : 'close-circle'"
          size="is-small"
        />
      </b-table-column>

      <b-table-column field="is_active" sortable label="Active" v-slot="props">
        <b-icon
          :type="props.row.is_active ? 'is-success' : 'is-danger'"
          :icon="props.row.is_active ? 'check-circle' : 'close-circle'"
          size="is-small"
        />
      </b-table-column>

      <b-table-column label="Reset Password" v-slot="props">
        <b-button size="is-small" @click="handlePasswordReset(props.row.email)">
          Reset Password
        </b-button>
      </b-table-column>
      <b-table-column v-slot="props" numeric>
        <router-link
          size="is-small"
          :to="{ name: 'AdminUserUpdate', params: { id: props.row.id } }"
        >
          <b-icon icon="square-edit-outline" />
        </router-link>
      </b-table-column>
    </b-table>
  </section>
</template>

<script>
import request from '../request';

export default {
  name: 'AdminUserTable',
  props: {
    users: Array,
  },
  data() {
    return {
      search: '',
    };
  },
  computed: {
    filteredOpts() {
      return this.users.filter((opt) => {
        let v = this.search.toLowerCase();
        return (
          opt.first_name.toLowerCase().indexOf(v) >= 0 ||
          opt.last_name.toLowerCase().indexOf(v) >= 0 ||
          opt.email.toLowerCase().indexOf(v) >= 0 ||
          opt.phone.toLowerCase().indexOf(v) >= 0 ||
          opt.organization.name.toLowerCase().indexOf(v) >= 0
        );
      });
    },
  },
  methods: {
    handlePasswordReset(email) {
      this.$buefy.dialog.confirm({
        message: `Are you sure you want to reset the password for ${email}`,
        type: 'is-success',
        onConfirm: () => {
          request.post(`/auth/password/reset`, { email: email });
        },
      });
    },
  },
};
</script>
