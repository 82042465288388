<template>
  <div class="block" v-if="isVisible">
    <div class="card">
      <header class="card-header">
        <p class="card-header-title">Reimbursement Estimate (OON Only)</p>
      </header>
      <div class="card-content" v-if="report.local_allowable">
        <div class="content">
          <section class="section has-background-white-ter">
            <div class="level">
              <div class="level-item has-text-centered">
                <div>
                  <p class="heading has-text-primary">Average</p>
                </div>
              </div>
              <div class="level-item has-text-centered">
                <div>
                  <p class="heading">Detox</p>
                </div>
              </div>
              <div class="level-item has-text-centered">
                <div>
                  <p class="heading">Residential</p>
                </div>
              </div>
              <div class="level-item has-text-centered">
                <div>
                  <p class="heading">Partial Hosp.</p>
                </div>
              </div>
              <div class="level-item has-text-centered">
                <div>
                  <p class="heading">Intensive OP</p>
                </div>
              </div>
              <div class="level-item has-text-centered">
                <div>
                  <p class="heading">Outpatient</p>
                </div>
              </div>
            </div>
            <div class="level">
              <div class="level-item has-text-centered">
                <h1 class="title is-1">Local Allowable</h1>
              </div>
            </div>
            <div class="level">
              <div class="level-item has-text-centered">
                This policy will abide by your local blue pricing for all levels of care.
              </div>
            </div>
          </section>
        </div>
      </div>
      <div class="card-content" v-else-if="report.match_level <= 0">
        There is currently no reimbursement estimate available for this inquiry.
      </div>
      <div class="card-content" v-else>
        <div class="content">
          <div>
            <section class="section has-background-white-ter">
              <div class="level">
                <div class="level-item has-text-centered">
                  <div>
                    <p class="heading has-text-primary">Average</p>
                    <p class="title has-text-primary" v-if="report.avg">
                      {{ report.avg.round(2) }}%
                    </p>
                    <p class="title" v-else>-</p>
                  </div>
                </div>
                <div class="level-item has-text-centered">
                  <div>
                    <p class="heading">Detox</p>
                    <p class="title" v-if="report.dtx">{{ report.dtx.round(2) }}%</p>
                    <p class="title" v-else>-</p>
                  </div>
                </div>
                <div class="level-item has-text-centered">
                  <div>
                    <p class="heading">Residential</p>
                    <p class="title" v-if="report.rtc">{{ report.rtc.round(2) }}%</p>
                    <p class="title" v-else>-</p>
                  </div>
                </div>
                <div class="level-item has-text-centered">
                  <div>
                    <p class="heading">Partial Hosp.</p>
                    <p class="title" v-if="report.php">{{ report.php.round(2) }}%</p>
                    <p class="title" v-else>-</p>
                  </div>
                </div>
                <div class="level-item has-text-centered">
                  <div>
                    <p class="heading">Intensive OP</p>
                    <p class="title" v-if="report.iop">{{ report.iop.round(2) }}%</p>
                    <p class="title" v-else>-</p>
                  </div>
                </div>
                <div class="level-item has-text-centered">
                  <div>
                    <p class="heading">Outpatient</p>
                    <p class="title" v-if="report.op">{{ report.op.round(2) }}%</p>
                    <p class="title" v-else>-</p>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div class="has-text-centered" v-if="matchMessage">
          <p>
            <strong>
              This data does not include co-insurance. It is percentage allowed on charges.
            </strong>
          </p>
          <strong v-if="matchMessage">{{ matchMessage }}</strong>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
Number.prototype.round = function (n) {
  const d = Math.pow(10, n);
  return Math.round((this + Number.EPSILON) * d) / d;
};
import request from '../request';
import { mapGetters } from 'vuex';

export default {
  name: 'ReimbursementReport',
  props: {
    inquiryId: String,
  },
  data() {
    return {
      report: null,
    };
  },
  computed: {
    ...mapGetters(['permReimbursements', 'hasFeatureReimbursement', 'hasOverrideReimbursement']),
    isAvailable() {
      return (
        this.permReimbursements && (this.hasFeatureReimbursement || this.hasOverrideReimbursement)
      );
    },
    isVisible() {
      return (
        this.permReimbursements &&
        (this.hasFeatureReimbursement || this.hasOverrideReimbursement) &&
        this.report != null
      );
    },
    matchMessage() {
      if (!this.report) {
        return '';
      }
      let level = this.report.match_level;
      // Blues and Non-Blues
      if (level == 2) {
        return 'No Group Number data was available. Showing all reimbursement data for the Employer.';
      }
      // Only Blues
      if (level == 1) {
        return 'No Employer data was available. Showing all reimbursement data for the Alpha Prefix.';
      }
      return '';
    },
  },

  created() {
    if (this.isAvailable) {
      request.get(`/inquiries/${this.inquiryId}/reimbursements`).then((resp) => {
        this.report = resp.data.report;
      });
    }
  },
};
</script>

<style scoped>
.heading {
  font-size: 14px;
}
</style>
