<template>
  <b-navbar v-if="isLoggedIn" shadow wrapper-class="container is-max-hd">
    <template #brand>
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        <img style="margin-bottom: -6px" src="/static/logo-wide.png" />
      </b-navbar-item>
    </template>
    <template #start>
      <b-navbar-item tag="router-link" :to="{ name: 'InquiryList' }">Inquiries</b-navbar-item>
    </template>

    <template #end>
      <b-navbar-dropdown v-if="isLoggedIn" right :label="user.email">
        <div v-if="isAdmin">
          <b-navbar-item tag="router-link" :to="{ name: 'AdminIndex' }">
            <strong>Admin</strong>
          </b-navbar-item>
          <hr />
        </div>
        <div v-if="isManager">
          <b-navbar-item tag="router-link" :to="{ name: 'OrganizationDetail' }">
            Organization Settings
          </b-navbar-item>
        </div>
        <b-navbar-item tag="router-link" :to="{ name: 'ProfileDetail' }">
          Profile Settings
        </b-navbar-item>
        <b-navbar-item tag="a" href="mailto:support@quickadmit.com">Email Support</b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ name: 'TermsAndConditions' }">Terms</b-navbar-item>
        <hr />
        <b-navbar-item @click="logout">Logout</b-navbar-item>
      </b-navbar-dropdown>
      <div class="buttons" v-if="!isLoggedIn">
        <b-navbar-item tag="div">
          <b-button type="is-primary" tag="router-link" :to="{ name: 'Login' }">Login</b-button>
          <b-button type="is-secondary" tag="router-link" :to="{ name: 'Register' }">
            Register
          </b-button>
        </b-navbar-item>
      </div>
    </template>
  </b-navbar>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Nav',
  computed: {
    ...mapGetters(['isLoggedIn', 'isAdmin', 'isManager', 'user']),
  },
  methods: {
    logout() {
      this.$store.dispatch('logout');
    },
  },
};
</script>

<style scoped>
hr {
  margin: 0.5rem 0;
}
</style>
