<template>
  <form-base
    title="Update User"
    cancel="OrganizationDetail"
    :error="form.errors.get('NOFIELD')"
    @submit="handleSubmit"
    :loading="loading"
  >
    <template v-slot:fields>
      <div class="block">
        <form-input :form="form" label="First Name" field-name="first_name" />
      </div>
      <div class="block">
        <form-input :form="form" label="Last Name" field-name="last_name" />
      </div>

      <div class="block">
        <form-input :form="form" label="Email" field-name="email" />
      </div>

      <div class="block">
        <PhoneField v-model="form.phone" :errors="form.errors" />
      </div>

      <template>
        <section>
          <b-field>
            <section class="levels">
              <b-radio v-model="levelContent" name="manager" native-value="manager">
                <b>Manager</b>
              </b-radio>
              <p>Can manage account and users</p>
            </section>
          </b-field>

          <b-field>
            <section class="levels">
              <b-radio v-model="levelContent" name="standard_user" native-value="standard_user">
                <b>Standard User</b>
              </b-radio>
              <p>Can see all inquiries for the organization</p>
            </section>
          </b-field>

          <b-field>
            <section class="levels">
              <b-radio v-model="levelContent" name="restricted_user" native-value="restricted_user">
                <b>Restricted User</b>
              </b-radio>
              <p>Can only see inquiries they've created</p>
            </section>
          </b-field>
        </section>
      </template>
      <br />

      <div class="block">
        <form-switch :form="form" label="Is Active" field-name="is_active" />
      </div>

      <div class="block">
        <form-switch
          :form="form"
          label="Can see reimbursement estimates"
          field-name="perm_reimbursements"
        />
      </div>
    </template>
  </form-base>
</template>

<script>
import { mapGetters } from 'vuex';

import Form from '../utils/form';
import FormBase from '../components/FormBase.vue';
import FormInput from '../components/FormInput.vue';
import FormSwitch from '../components/FormSwitch.vue';
import PhoneField from '../components/fields/PhoneField.vue';

export default {
  name: 'UserUpdateForm',
  components: {
    FormBase,
    FormInput,
    FormSwitch,
    PhoneField,
  },
  props: {
    user: Object,
    isForAdmin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: true,
      form: new Form({
        name: '',
        npi: '',
        tax_id: '',
        phone: '',
        is_manager: false,
        is_restricted: false,
        perm_reimbursements: false,
      }),
      levelContent: 'restricted_user',
    };
  },
  created() {
    this.setData(this.user);
  },
  computed: {
    ...mapGetters(['organization']),
  },
  methods: {
    setData(user) {
      if (user.membership.is_manager) {
        this.levelContent = 'manager';
      } else if (user.membership.is_restricted) {
        this.levelContent = 'restricted_user';
      } else {
        this.levelContent = 'standard_user';
      }

      this.form = new Form({
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        phone: user.phone,
        is_active: user.is_active,
        is_manager: user.membership.is_manager,
        is_restricted: user.membership.is_restricted,
        perm_reimbursements: user.membership.perm_reimbursements,
      });
      this.loading = false;
    },
    handleSubmit() {
      this.loading = true;

      if (this.levelContent === 'manager') {
        this.form.is_manager = true;
        this.form.is_restricted = false;
      } else if (this.levelContent === 'standard_user') {
        this.form.is_manager = false;
        this.form.is_restricted = false;
      } else if (this.levelContent === 'restricted_user') {
        this.form.is_manager = false;
        this.form.is_restricted = true;
      }

      // check if in admin page, if so, get user from admin endpoint
      const url = this.isForAdmin ? `/admin/users/${this.user.id}` : `/users/${this.user.id}`;

      this.form
        .put(url)
        .then(() => {
          let msg = `${this.form.first_name} ${this.form.last_name} was updated successfully.`;
          this.$buefy.toast.open({ message: msg, type: 'is-success' });
          let routeTo = this.isForAdmin ? 'AdminUserList' : 'OrganizationDetail';
          this.$router.push({ name: routeTo });
        })
        .catch(() => {
          if (!this.form.errors.any()) {
            this.form.errors.setGenericError();
          }
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
