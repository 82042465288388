<template>
  <AdminLayout>
    <template #breadcrumb>
      <breadcrumb-link view="AdminInquiryList" label="Inquiries" :active="true" />
    </template>

    <div class="box" style="position: relative">
      <b-loading :is-full-page="false" v-model="loading"></b-loading>

      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <h1 class="title">Inquiry List</h1>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <b-switch :left-label="true" v-model="showErrors">Show All Inquiries</b-switch>
          </div>
          <div class="level-item">
            <b-field>
              <b-select
                v-model="selectedSince"
                placeholder="Last 7 Days"
                :loading="loading"
                @input="handleSelect()"
              >
                <option value="7">Last 7 Days</option>
                <option value="30">Last 30 Days</option>
                <option value="0">All Time</option>
              </b-select>
            </b-field>
          </div>
        </div>
      </div>
      <hr />
      <div>
        <section class="section has-text-centered" v-if="!loading && !options.length">
          <p class="title is-5 block">You haven't created any inquiries yet.</p>
        </section>
        <InquiryTable
          v-if="!loading && options.length"
          :options="filteredOptions"
          :isForAdmin="true"
        />
      </div>
    </div>
  </AdminLayout>
</template>

<script>
import InquiryTable from '../../components/InquiryTable.vue';
import AdminLayout from '../../layouts/AdminLayout.vue';

import request from '../../request';

export default {
  name: 'AdminInquiryList',
  components: {
    InquiryTable,
    AdminLayout,
  },
  data() {
    return {
      loading: true,
      options: [],
      selectedSince: 7,
      showErrors: false,
    };
  },
  mounted() {
    this.fetchInquiries().finally(() => (this.loading = false));
  },
  methods: {
    handleSelect() {
      this.loading = true;
      this.fetchInquiries().finally(() => (this.loading = false));
    },
    fetchInquiries() {
      let args = {};
      if (this.selectedSince != 0) {
        args['since'] = this.selectedSince;
      }
      let params = new URLSearchParams(args).toString();
      return request(`/admin/inquiries/?${params}`).then(
        (resp) => (this.options = resp.data.inquiries)
      );
    },
  },
  computed: {
    filteredOptions() {
      return this.options.filter(
        (opt) => this.showErrors || !['error', 'failure'].includes(opt.status)
      );
    },
  },
};
</script>
