<template>
  <div id="app">
    <nprogress-container></nprogress-container>
    <div
      v-if="!isProd && isLoggedIn"
      class="notification is-danger is-light has-text-centered mb-0"
      style="border-radius: 0"
    >
      You are using the
      <strong>{{ environment }}</strong>
      version of QuickAdmit. This is only for testing purposes. Please visit the
      <a href="https://app.quickadmit.com">Production Version</a>
      .
    </div>
    <Nav />
    <router-view />
  </div>
</template>

<script>
import Nav from './components/Nav.vue';
import { mapGetters } from 'vuex';
import NprogressContainer from 'vue-nprogress/src/NprogressContainer';

export default {
  name: 'App',
  components: {
    Nav,
    NprogressContainer,
  },
  data() {
    return {
      currentVersion: null,
      isNotified: false,
    };
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'isProd', 'environment']),
  },
  mounted() {
    setInterval(
      () => {
        this.versionCheck();
      },
      1000 * 60 * 15
    ); // every 15 minutes
  },
  methods: {
    // versionCheck will hit the home page to get index.html and has
    // the content and store that as the version. Every XX seconds this gets called.
    // The index.html has a unique hashed js file embeded so the file will differ.
    async versionCheck() {
      if (!this.isLoggedIn) {
        return;
      }

      if (this.isNotified) {
        return;
      }

      fetch('/')
        .then((resp) => {
          let text = resp.text();
          let version = text.hashCode();
          if (this.currentVersion === null) {
            this.currentVersion = version;
          } else if (this.currentVersion !== version) {
            this.isNotified = true;
            this.$buefy.snackbar.open({
              indefinite: true,
              message: 'There is a new version of QuickAdmit. Please reload.',
              type: 'is-info',
              position: 'is-bottom-left',
              actionText: 'Reload',
              queue: false,
              onAction: () => {
                window.location.reload();
              },
            });
          }
        })
        .catch(() => {
          // silently ignore
        });
    },
  },
};
</script>
