<template>
  <b-field
    v-if="schema.allowed"
    :label="schema.label"
    :message="errors"
    :type="{ 'is-danger': errors ? errors.length : false }"
  >
    <b-input
      v-model="content"
      :pattern="schema.pattern"
      :maxlength="schema.maxLength"
      :required="schema.required"
      :use-html5-validation="false"
      @input="$emit('input', content)"
    ></b-input>
  </b-field>
</template>

<script>
export default {
  data() {
    return {
      content: this.value,
    };
  },
  props: {
    value: String,
    schema: Object,
    errors: String,
    helpText: String,
  },
};
</script>
