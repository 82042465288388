<template>
  <div v-if="notes && notes.length !== 0">
    <div class="content" v-if="notes">
      <div class="has-text-grey">Notes</div>
      <ul class="has-text-grey">
        <li v-for="(note, index) in notes" :key="index" class="force-break">
          <template v-if="typeof note === 'string'">
            {{ note }}
          </template>
          <template v-else-if="typeof note === 'object'">
            {{ note.type }}: {{ note.message }}
          </template>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PayerNotes',
  props: ['notes'],
};
</script>
