<template>
  <div>
    <b-loading :is-full-page="false" v-model="loading"></b-loading>
    <breadcrumb-list>
      <breadcrumb-link view="Home" label="Home" />
      <breadcrumb-link view="AdminIndex" label="Admin" />
      <breadcrumb-link view="AdminLAPrefixList" label="Local Allowable Upload" :active="true" />
    </breadcrumb-list>
    <form-base
      title="Add Local Allowable Alphas"
      cancel="AdminLAPrefixList"
      :error="form.errors.get('NOFIELD')"
      :loading="loading"
      :disabled="loading"
      @submit="handleSubmit"
    >
      <template v-slot:description>
        <p class="block">
          This form allows you to copy and paste a list of
          <strong>Member IDs</strong>
          .
        </p>
        <p class="block">
          This list will be converted into Alpha Prefixes. If an inquiries MemberID matches one of
          these alpha prefixes, the user will see
          <strong>Local Allowable</strong>
          for the reimbursement estimate instead of individual percentages.
        </p>
      </template>
      <template v-slot:fields>
        <div class="block">
          <form-text-area :form="form" label="Member IDs" field-name="member_ids" />
        </div>
      </template>
    </form-base>
  </div>
</template>

<script>
import request from '../../request';
import Form from '../../utils/form';
import FormBase from '../../components/FormBase.vue';
import FormTextArea from '../../components/FormTextArea.vue';

export default {
  name: 'AdminLAPrefixUpload',
  components: { FormBase, FormTextArea },
  data() {
    return {
      loading: true,
      options: [],
      form: new Form({
        member_ids: '',
      }),
    };
  },
  created() {
    this.getLAPrefixes();
  },
  methods: {
    getLAPrefixes() {
      return request
        .get('/admin/la-prefixes')
        .then((resp) => (this.options = resp.data.la_prefixes))
        .then(() => (this.loading = false));
    },

    toast(msg, msgType) {
      this.$buefy.toast.open({ message: msg, type: msgType, duration: 5000 });
    },
    handleSubmit() {
      this.loading = true;
      let formData = new FormData();
      formData.append('member_ids', this.form.member_ids);
      request
        .post('/admin/la-prefixes/upload', formData)
        .then(() => {
          this.toast('Member IDs uploaded successfully.', 'is-success');
          this.form.member_ids = '';
        })
        .catch((err) => {
          if (err.response && err.response.status == 422) {
            this.errors.record(err.response.data.errors);
          } else {
            this.toast('Failed to upload.', 'is-danger');
          }
        })
        .finally(() => {
          this.getLAPrefixes();
        });
    },
  },
};
</script>
