<template>
  <div class="card">
    <div class="card-content">
      <div class="content">
        <p>
          Gain access to 200k+ (and climbing) reimbursement data points for major commercial payers,
          including:
        </p>
        <ul>
          <li>Blue Cross Blue Shield</li>
          <li>United Healthcare</li>
          <li>Aetna</li>
          <li>Cigna</li>
        </ul>
        <p>
          Our database is updated every quarter to ensure you have the most accurate and relevant
          information to make the right decisions for your business.
        </p>
        <p>
          With the introduction of the No Surprises Act, the Quick Admit Reimbursement Estimate
          widget will help you provide Good Faith Estimates for your clients and their families.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReimbursementFeatureDetail',
};
</script>
