<template>
  <div>
    <breadcrumb-list>
      <breadcrumb-link view="Home" label="Home" />
      <breadcrumb-link view="AdminIndex" label="Admin" />
      <breadcrumb-link view="AdminPayerList" label="Payers" />
      <breadcrumb-link view="AdminPayerUpload" label="Upload" :active="true" />
    </breadcrumb-list>
    <section>
      <div class="container is-max-hd">
        <div class="box">
          <h1 class="title">Upload Payers</h1>
          <hr />
          <p class="block">
            This form allows you to upload a CSV file of Payers to create and/or update Payers in
            the system.
          </p>
          <p class="block">
            The format should be two columns
            <em>without headers</em>
            .
            <br />
            <strong>CODE</strong>
            ,
            <strong>NAME</strong>
          </p>
          <div class="level">
            <div class="level-left">
              <div class="level-item">
                <b-field class="file is-primary" :class="{ 'has-name': !!file }">
                  <b-upload v-model="file" class="file-label">
                    <span class="file-cta">
                      <b-icon class="file-icon" icon="upload"></b-icon>
                      <span class="file-label">Select CSV File</span>
                    </span>
                    <span class="file-name" v-if="file">
                      {{ file.name }}
                    </span>
                  </b-upload>
                </b-field>
              </div>
            </div>
            <div class="level-right">
              <p class="level-item">
                <b-button :loading="loading" @click="handleSubmit()" type="is-primary">
                  Upload
                </b-button>
              </p>
            </div>
          </div>
        </div>
        <div class="has-text-centered">
          <b-button
            type="is-primary is-outlined"
            tag="router-link"
            :to="{ name: 'AdminPayerList' }"
          >
            Nevermind, take me back.
          </b-button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import request from '../../request';

export default {
  name: 'AdminPayerUpload',
  data() {
    return {
      file: null,
      loading: false,
    };
  },
  methods: {
    toast(msg, msgType) {
      this.$buefy.toast.open({
        message: msg,
        type: msgType,
        duration: 5000,
      });
    },
    handleSubmit() {
      this.loading = true;
      let formData = new FormData();
      formData.append('file', this.file);
      request({
        url: '/admin/payers/upload',
        data: formData,
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(() => {
          this.toast('Payers uploaded successfully.', 'is-success');
          this.$router.push({ name: 'AdminPayerList' });
        })
        .catch(() => {
          this.toast('Payers failed to upload.', 'is-danger');
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
