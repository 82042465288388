<template>
  <div class="box" v-if="filteredInvites.length">
    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <h1 class="title is-3">Pending Invites</h1>
        </div>
      </div>
    </div>
    <hr />
    <b-table
      :data="filteredInvites"
      sort-icon="chevron-up"
      sort-icon-size="is-small"
      :default-sort="['name', 'asc']"
      striped
      :paginated="filteredInvites.length > 10"
      per-page="10"
      hoverable
    >
      <b-table-column label="Invitee Email" v-slot="props">
        {{ props.row.email }}
      </b-table-column>

      <b-table-column label="Invited By" v-slot="props">
        {{ props.row.user.first_name }} {{ props.row.user.last_name }}
      </b-table-column>

      <b-table-column field="created_at" label="Invited Date" v-slot="props">
        {{ new Date(props.row.created_at).toLocaleDateString() }}
      </b-table-column>
      <b-table-column numeric v-slot="props">
        <a href="#" class="has-text-danger" @click.prevent="onRevoke(props.row)">Revoke</a>
        {{ ' / ' }}
        <a href="#" class="has-text-info" @click.prevent="onResend(props.row)">Resend</a>
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import request from '../request';

export default {
  name: 'InviteList',
  data() {
    return {
      loading: true,
      invites: [],
    };
  },
  props: {
    isForAdmin: {
      type: Boolean,
      default: false,
    },
    organization: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    this.getInvites();
  },
  computed: {
    filteredInvites() {
      return this.invites.filter((o) => o.revoked_at === null && o.accepted_at === null);
    },
  },
  methods: {
    getInvites() {
      this.loading = true;
      const url = this.isForAdmin
        ? `/admin/invites?organization_id=${this.organization.id}`
        : '/invites';

      request
        .get(url)
        .then((resp) => (this.invites = resp.data.invites))
        .finally(() => (this.loading = false));
    },
    onRevoke(invite) {
      const url = this.isForAdmin ? `/admin/invites/${invite.id}` : `/invites/${invite.id}`;

      this.$buefy.dialog.confirm({
        message: `Are you sure you want to revoke invitation for "${invite.email}"?`,
        onConfirm: () => {
          request
            .put(url, { revoke: true })
            .then(() => this.getInvites())
            .finally(() => {
              let msg = 'Invitation revoked!';
              this.$buefy.toast.open({ message: msg, type: 'is-success' });
            });
        },
      });
    },
    onResend(invite) {
      const url = this.isForAdmin ? `/admin/invites/${invite.id}` : `/invites/${invite.id}`;

      this.$buefy.dialog.confirm({
        message: `Are you sure you want to resend the invitation for "${invite.email}"?`,
        onConfirm: () => {
          request.put(url, { resend: true }).finally(() => {
            let msg = 'Invitation sent!';
            this.$buefy.toast.open({ message: msg, type: 'is-success' });
          });
        },
      });
    },
  },
};
</script>
