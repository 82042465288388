<template>
  <div class="px-5">
    <h4 class="title is-4">Creating a New Inquiry</h4>
    <p class="block">
      To create a new inquiry, select one of your available Providers and one of the available
      Payers.
    </p>
    <strong>Providers</strong>
    <br />
    <p class="block">
      You can select any of your previously configured providers. If the provider you would like to
      use isn't available,
      <span v-if="isManager">
        you can add a
        <router-link :to="{ name: 'ProviderCreate' }">new provider</router-link>
        .
      </span>
      <span v-else>you can ask the organization manager to add it for you.</span>
    </p>
    <strong>Payers</strong>
    <br />
    <p class="block">
      All of our available Payers are listed here. We actively monitor and update this list.
    </p>
    <hr class="aq-helper" />
    <p class="block">
      Once you've selected a payer and provider, the payer's allowed, and required fields will be
      displayed.
    </p>
    <p class="block">
      <strong>Required fields</strong>
      are denoted with a
      <strong>*</strong>
      in the field label.
      <br />
    </p>
    <p class="block">
      <strong>As of Date</strong>
      is the date that you would like to search for coverage. By default, it will be
      <em>today's</em>
      date.
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'InquiryFormInstructions',
  computed: {
    ...mapGetters(['isManager']),
  },
};
</script>

<style>
hr.aq-helper {
  background-color: #bbb;
  height: 1px;
}
</style>
