<template>
  <div class="block pb-6">
    <breadcrumb-list>
      <breadcrumb-link view="Home" label="Home" />
      <breadcrumb-link view="OrganizationDetail" :label="organization" :active="true" />
    </breadcrumb-list>
    <section>
      <div class="container is-max-hd">
        <SubscriptionDetail :subscription="subscription" />
        <InviteList />
        <UserList />
        <ProviderList />
      </div>
    </section>
  </div>
</template>

<script>
import request from '../request';

import { mapGetters } from 'vuex';

import SubscriptionDetail from '../components/SubscriptionDetail';
import InviteList from '../components/InviteList';
import UserList from '../components/UserList';
import ProviderList from '../components/ProviderList';

export default {
  name: 'OrganizationDetail',
  components: {
    SubscriptionDetail,
    InviteList,
    UserList,
    ProviderList,
  },
  data() {
    return {
      subscription: null,
    };
  },
  mounted() {
    this.getSubscription();
  },
  computed: {
    ...mapGetters(['organization']),
  },
  methods: {
    getSubscription() {
      request
        .get('/subscriptions/current')
        .then((resp) => (this.subscription = resp.data.subscription))
        .finally(() => (this.loading = false));
    },
  },
};
</script>
