<template>
  <div class="content" v-if="contacts && contacts.length !== 0">
    <div class="has-text-grey">Contacts</div>
    <div v-for="(contact, idx) in contacts" :key="idx">
      <p>
        <strong>Name:</strong>
        {{ contact.name }}
        <br />
        <strong>Category:</strong>
        {{ contact.category }}
      </p>

      <ul>
        <li v-for="(val, index) in contact.info" :key="index">{{ val | title }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactList',
  props: ['contacts'],
};
</script>
