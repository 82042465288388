<template>
  <div v-if="showLevel">
    <div class="columns is-multiline">
      <div class="column is-2" v-if="autoBenefit">
        <div class="block">
          <div class="has-text-grey">
            <b-tooltip
              type="is-primary is-light"
              label="Auxiliary benefits are always shown, regardless of filters"
            >
              Aux
              <b-icon icon="information" size="is-small" />
            </b-tooltip>
          </div>
          <strong :class="autoBenefit.type">{{ autoBenefit.label }}</strong>
        </div>
      </div>
      <!-- payment check is required for autobenefits -->
      <div class="column is-2" v-if="benefit.amount">
        <div class="block">
          <div class="has-text-grey">Detail</div>
          <strong class="has-text-primary">{{ benefit.amount }}</strong>
        </div>
      </div>
      <div class="column is-2" v-if="benefit.network">
        <div class="block">
          <div class="has-text-grey">Network</div>
          <strong class="has-text-info">{{ benefit.network }}</strong>
        </div>
      </div>
      <div class="column is-2" v-if="benefit.level">
        <div class="block">
          <div class="has-text-grey">Level</div>
          <span>{{ benefit.level }}</span>
        </div>
      </div>
      <div class="column is-2" v-if="benefit.description">
        <div class="block">
          <div class="has-text-grey">Description:</div>
          <span>{{ benefit.description }}</span>
        </div>
      </div>
      <div class="column is-2" v-if="benefit.auth_required_unknown === false">
        <div class="block">
          <div v-if="benefit.auth_required !== null">
            <div class="has-text-grey">Auth Required</div>
            <span v-if="benefit.auth_required === false" class="has-text-grey">Not Required</span>
            <strong v-if="benefit.auth_required === true" class="has-text-danger strong">
              Required
            </strong>
          </div>
        </div>
      </div>
      <div class="column is-2" v-if="benefit.benefit_start">
        <div class="block">
          <div class="has-text-grey">Benefit Begin:</div>
          <span>{{ benefit.benefit_start | formatDate }}</span>
        </div>
      </div>
      <div class="column is-2" v-if="benefit.benefit_end">
        <div class="block">
          <div class="has-text-grey">Benefit End:</div>
          <span>{{ benefit.benefit_end | formatDate }}</span>
        </div>
      </div>
      <div class="column is-3" v-if="benefit.place_of_service">
        <div class="block">
          <div class="has-text-grey">Place of service:</div>
          <span>{{ benefit.place_of_service }}</span>
        </div>
      </div>
    </div>

    <div class="block is-size-5">
      <span>{{ benefit.text }}</span>
    </div>

    <PayerNotes :notes="benefit.payer_notes" />
    <ContactList :contacts="benefit.contacts" />

    <div v-if="benefit.delivery_information && benefit.delivery_information.length !== 0">
      <div class="has-text-grey">Delivery Information</div>
      {{ benefit.delivery_information }}
    </div>
    <hr />
  </div>
</template>

<script>
import PayerNotes from './PayerNotes';
import ContactList from './ContactList';

export default {
  name: 'BenefitAmount',
  components: {
    PayerNotes,
    ContactList,
  },
  props: {
    benefit: Object,
    autoBenefit: Object,
    selectedLevel: String,
  },
  computed: {
    showLevel() {
      return ['All', this.benefit.level].includes(this.selectedLevel);
    },
  },
};
</script>

<style scoped>
pre {
  white-space: pre-wrap;
  word-wrap: anywhere;
}
/* There are some payer notes that have no spaces and are very long which never break. */
.force-break {
  overflow-wrap: break-word;
}

hr {
  margin: 2rem 0;
}

.content ul {
  margin-top: 0;
}
</style>
