<template>
  <section>
    <label class="label mb-3">
      {{ schema.label }}
      <small v-if="schema.multiple && schema.max_options && schema.max_options < 10">
        (Maximum: {{ schema.max_options }})
      </small>
    </label>
    <b-field v-for="opt in schema.options" :key="opt.id">
      <b-checkbox
        v-model="content"
        :name="schema.name"
        :native-value="opt.id"
        @input="$emit('input', content)"
        :type="errors && errors.length ? 'is-danger' : ''"
        :class="{ 'has-text-danger': errors ? errors.length : false }"
      >
        {{ opt.name }}
      </b-checkbox>
    </b-field>
    <p v-if="errors ? errors.length : false" class="help is-danger">{{ errors }}</p>
  </section>
</template>

<script>
export default {
  data() {
    return {
      content: this.value || [],
    };
  },
  props: {
    value: Array,
    schema: Object,
    errors: String,
    helpText: String,
  },
};
</script>
