<template>
  <div class="box" v-if="showBenefit">
    <p class="title is-4">
      {{ benefitGroup.name }}
      <b-tag class="is-pulled-right" :type="statusClass" v-if="statusCode !== 'unknown'">
        {{ statusName }}
      </b-tag>
    </p>
    <template v-if="benefitGroup.status_details">
      <inquiry-detail-benefit-amount-status
        v-for="(amount, i) in benefitGroup.status_details"
        :key="i"
        :amount="amount"
      />

      <p v-if="!hasActiveStatus(benefitGroup.status_details)">
        No active status returned from the payer
      </p>
    </template>

    <ContactList :contacts="benefitGroup.contacts" />

    <hr class="has-background-primary" />

    <template v-if="benefitGroup.benefits">
      <div v-for="(benefit, i) in benefitGroup.benefits" :key="'b_' + i">
        <template v-if="showNetwork(benefit.network_code) && showPayment(benefit.amount_code)">
          <inquiry-detail-benefit-amount
            v-if="benefit"
            :key="i"
            :benefit="benefit"
            :selected-level="selectedLevel"
          />
        </template>
      </div>
    </template>

    <template v-if="benefitGroup.auxilary">
      <div v-for="(benefit, i) in benefitGroup.auxilary" :key="'a_' + i">
        <template v-if="showNetwork(benefit.network_code) && showPayment(benefit.amount_code)">
          <inquiry-detail-benefit-amount
            v-if="benefit"
            :key="i"
            :benefit="benefit"
            :auto-benefit="getAutoBenefit(benefit, autoBenefits)"
            :selected-level="selectedLevel"
          />
        </template>
      </div>
    </template>

    <div v-if="benefitGroup?.additional_payers && benefitGroup?.additional_payers.length !== 0">
      <p class="has-text-grey">Additional Payers</p>
      <div v-for="payer in benefitGroup?.additional_payers" :key="payer.name">
        {{ payer.name }}
        <span v-if="payer.primary === true">, primary</span>
        <span v-if="payer.secondary === true">, secondary</span>
        <span v-if="payer.tertiary === true">, tertiary</span>
        <span v-if="payer.third_party_administrator === true">, third party administrator</span>
      </div>
    </div>

    <p v-if="!hasBenefitsDetails(benefitGroup)">No benefit details returned from the payer</p>

    <b-collapse :open="false" position="is-bottom" v-if="isAdmin && !isPdf">
      <template #trigger="props">
        <a class="is-pulled-right">
          <b-icon :icon="!props.open ? 'menu-down' : 'menu-up'"></b-icon>
        </a>
      </template>
      <pre>{{ benefitGroup }} </pre>
    </b-collapse>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import InquiryDetailBenefitAmountStatus from './InquiryDetailBenefitAmountStatus.vue';
import InquiryDetailBenefitAmount from './InquiryDetailBenefitAmount.vue';
import { possibleBenefitItems, possibleNetworks } from '../constants.js';
import ContactList from './ContactList.vue';

const autoBenefits = possibleBenefitItems.filter((obj) => obj.auto === true);

export default {
  name: 'Benefit',
  components: {
    InquiryDetailBenefitAmountStatus,
    InquiryDetailBenefitAmount,
    ContactList,
  },
  data() {
    return {
      possibleNetworks,
      autoBenefits,
    };
  },
  props: {
    label: String,
    benefitGroup: Object,
    selectedNetworks: Array,
    selectedPayments: Array,
    selectedBenefits: Array,
    selectedLevel: String,
    isPdf: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters(['isAdmin']),
    showBenefit() {
      if (this.selectedBenefits.includes('All')) {
        return true;
      }
      if (this.selectedBenefits.includes(this.benefitGroup.name)) {
        return true;
      }
      return false;
    },
    statusName() {
      if (this.benefitGroup.status) {
        return this.benefitGroup.status;
      }
      if (this.benefitGroup.status_details && this.benefitGroup.status_details.length !== 0) {
        return this.benefitGroup.status_details[0].status;
      }
      return 'Unknown';
    },
    statusCode() {
      if (this.benefitGroup.status_code) {
        return this.benefitGroup.status_code;
      }
      if (this.benefitGroup.status_details && this.benefitGroup.status_details.length !== 0) {
        return this.benefitGroup.status_details[0].status_code;
      }
      return 'unknown';
    },
    statusClass() {
      if (['1', '2', '3', '4', '5'].includes(this.statusCode)) {
        return 'is-success';
      }
      if (['0', '6', '7', '8'].includes(this.statusCode)) {
        return 'is-danger';
      }
      return 'is-info';
    },
  },
  methods: {
    showNetwork(network) {
      if (this.selectedNetworks.includes('All')) {
        return true;
      }
      if (this.selectedNetworks.includes(network)) {
        return true;
      }
      return false;
    },
    showPayment(payment) {
      if (this.selectedPayments.includes('All')) {
        return true;
      }
      if (this.selectedPayments.includes(payment)) {
        return true;
      }
      return false;
    },
    hasActiveStatus(details) {
      if (!details || details.length === 0) {
        return false;
      }
      return true;
    },
    hasBenefitsDetails(benefit) {
      if (benefit.benefits && benefit.benefits.length !== 0) {
        return true;
      }
      return false;
    },
    getAutoBenefit(benefit, autoBenefits) {
      for (const autoBenefit of autoBenefits) {
        if (autoBenefit.key === benefit.type_code) {
          return autoBenefit;
        }
      }
      return {};
    },
  },
};
</script>
