<template>
  <div>
    <breadcrumb-list>
      <breadcrumb-link view="Home" label="Home" />
      <breadcrumb-link view="OrganizationDetail" :label="organization" />
      <breadcrumb-link
        view="ProviderUpdate"
        :params="{ id: provider.id }"
        :label="`Update ${provider.name}`"
        :active="true"
        v-if="!loading"
      />
    </breadcrumb-list>

    <form-base
      title="Update Provider"
      cancel="OrganizationDetail"
      :error="form.errors.get('NOFIELD')"
      @submit="handleSubmit"
    >
      <template v-slot:fields>
        <div class="block">
          <form-input :form="form" label="Provider Name" field-name="name" />
        </div>

        <div class="block">
          <form-input :form="form" label="Provider ID (NPI)" field-name="npi" />
        </div>

        <div class="block">
          <form-input :form="form" label="Tax ID" field-name="tax_id" />
        </div>

        <div class="block">
          <form-switch :form="form" label="Is Active" field-name="is_active" />
        </div>
      </template>
    </form-base>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Form from '../utils/form';
import FormBase from '../components/FormBase.vue';
import FormInput from '../components/FormInput.vue';
import FormSwitch from '../components/FormSwitch.vue';
import request from '../request';

export default {
  name: 'ProviderUpdate',
  components: { FormBase, FormInput, FormSwitch },
  data() {
    return {
      loading: true,
      provider: null,
      form: new Form({
        name: '',
        npi: '',
        tax_id: '',
      }),
    };
  },
  computed: {
    ...mapGetters(['organization']),
  },
  beforeRouteEnter(to, from, next) {
    request
      .get(`/providers/${to.params.id}`)
      .then((resp) => next((vm) => vm.setData(resp.data.provider)))
      .catch(() => next({ name: 'NotFound' }));
  },
  methods: {
    setData(provider) {
      this.provider = provider;
      this.form = new Form({
        name: provider.name,
        npi: provider.npi,
        tax_id: provider.tax_id,
        is_active: provider.is_active,
      });
      this.loading = false;
    },
    handleSubmit() {
      this.loading = true;
      this.form
        .put(`/providers/${this.$route.params.id}`)
        .then(() => {
          let msg = `${this.form.name} was updated successfully.`;
          this.$buefy.toast.open({ message: msg, type: 'is-success' });
          this.$router.push({ name: 'OrganizationDetail' });
        })
        .catch(() => {})
        .finally(() => (this.loading = false));
    },
  },
};
</script>
