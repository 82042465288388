<template>
  <div class="card">
    <div class="card-content">
      <div class="media">
        <div class="media-content has-text-centered">
          <p class="title is-4">{{ plan.name }}</p>
          <p class="subtitle is-6">${{ plan.base_cost }}/mo</p>
        </div>
      </div>
      <b-button
        expanded
        type="is-primary"
        @click="$emit('click', plan)"
        :loading="loading"
        :disabled="isActive"
      >
        <span v-if="isActive">This is your active plan</span>
        <span v-else>Select the {{ plan.name }}</span>
      </b-button>
      <div class="content">
        <div class="mt-2 has-text-centered">
          <small v-if="!plan.is_enabled" class="has-text-danger">This plan is grandfathered</small>
        </div>

        <ul>
          <li>
            {{ plan.base_inquiries }} Inquiries
            <small class="has-text-grey">(${{ plan.addl_cost }} per extra)</small>
          </li>
          <li>Unlimited Users</li>
          <li>Unlimited Providers/Facilities</li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SubscriptionOption',
  props: {
    plan: {
      required: true,
      type: Object,
    },
    isActive: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
};
</script>
