<template>
  <div>
    <div class="columns is-multiline">
      <div class="column is-3" v-if="amount.level">
        <div class="has-text-grey">Level</div>
        <span>{{ amount.level }}</span>
      </div>
      <div class="column is-3" v-if="amount.planNetworkName">
        <div class="has-text-grey">Plan Network</div>
        <span>{{ amount.planNetworkName }}</span>
      </div>
      <div class="column is-2" v-if="amount.planNumber">
        <div class="has-text-grey">Plan Number</div>
        <span>{{ amount.planNumber }}</span>
      </div>
      <div class="column is-2" v-if="amount.benefit_start">
        <div class="has-text-grey">Benefit Begin</div>
        <span>{{ amount.benefit_start | formatDate }}</span>
      </div>
      <div class="column is-2" v-if="amount.benefit_end">
        <div class="has-text-grey">Benefit End</div>
        <span>{{ amount.benefit_end | formatDate }}</span>
      </div>
      <div class="column is-2" v-if="amount.coverage_start">
        <div class="has-text-grey">Coverage Begin</div>
        <span>{{ amount.coverage_start | formatDate }}</span>
      </div>
      <div class="column is-2" v-if="amount.coverage_end">
        <div class="has-text-grey">Coverage End</div>
        <span>{{ amount.coverage_end | formatDate }}</span>
      </div>
      <div class="column is-2" v-if="amount.insurance_type">
        <div class="has-text-grey">Insurance Type</div>
        <span v-if="amount.insurance_type_code == 'PR'">PPO</span>
        <span v-else>{{ amount.insurance_type }}</span>
      </div>
      <div class="column is-2" v-if="amount.description">
        <div class="has-text-grey">Description</div>
        <span>{{ amount.description }}</span>
      </div>
    </div>
    <PayerNotes :notes="amount.notes" />
    <br />
    <ContactList :contacts="amount.contacts" />
  </div>
</template>

<script>
import PayerNotes from './PayerNotes';
import ContactList from './ContactList';

export default {
  name: 'BenefitAmountStatus',
  components: {
    PayerNotes,
    ContactList,
  },
  props: {
    amount: Object,
  },
};
</script>

<style scoped>
pre {
  white-space: pre-wrap;
  word-wrap: anywhere;
}
.content ul {
  margin-top: 0;
}
</style>
