<template>
  <div class="box">
    <p class="title is-4">
      <span v-if="payer?.is_blue && payerNameOverride != ''">
        {{ payerNameOverride }}
      </span>
      <span v-else>
        {{ payer.name }}
      </span>
      <b-tag class="is-pulled-right" :type="statusClass">{{ plan.status }}</b-tag>
    </p>
    <p class="subtitle" v-if="showSubtitle">
      {{ responsePayer }}
    </p>

    <div class="columns is-multiline">
      <div class="column is-2" v-if="plan.insurance_type">
        <div class="has-text-grey">Insurance Type</div>
        <span v-if="plan.insurance_type == 'PR'">PPO</span>
        <span v-else>{{ plan.insurance_type }}</span>
      </div>

      <div class="column is-3" v-if="plan.name">
        <div class="has-text-grey">Plan Name</div>
        <span>{{ plan.name }}</span>
      </div>
      <div class="column is-3" v-if="plan.number">
        <div class="has-text-grey">Plan Number</div>
        <span>{{ plan.number }}</span>
      </div>
      <div class="column is-3" v-if="plan.plan_network_name">
        <div class="has-text-grey">Plan Network Name</div>
        <span>{{ plan.plan_network_name }}</span>
      </div>

      <div class="column is-3" v-if="plan.group_name">
        <div class="has-text-grey">Group Name</div>
        <span>{{ plan.group_name }}</span>
      </div>
      <div class="column is-2" v-if="plan.group_number">
        <div class="has-text-grey">Group Number</div>
        <span>{{ plan.group_number }}</span>
      </div>
      <div class="column is-3" v-if="plan.plan_start">
        <div class="has-text-grey">Plan Start Date</div>
        <span>{{ plan.plan_start | formatDate }}</span>
      </div>
      <div class="column is-3" v-if="plan.plan_end">
        <div class="has-text-grey">Plan End Date</div>
        <span>{{ plan.plan_end | formatDate }}</span>
      </div>
      <div class="column is-3" v-if="plan.coverage_start">
        <div class="has-text-grey">Coverage Start Date</div>
        <span>{{ plan.coverage_start | formatDate }}</span>
      </div>
      <div class="column is-3" v-if="plan.coverage_end">
        <div class="has-text-grey">Coverage End Date</div>
        <span>{{ plan.coverage_end | formatDate }}</span>
      </div>
    </div>

    <ContactList :contacts="plan.contacts" />

    <span v-if="plan.premium_paid_to_end_date">
      <strong>Premium Paid Through</strong>
      :
      {{ plan.premium_paid_to_end_date | formatDate }}
      <br />
    </span>

    <span v-if="plan.last_update_date">
      <strong>Last Updated</strong>
      : {{ plan.last_update_date | formatDate }}
      <br />
    </span>

    <PayerNotes :notes="plan.payer_notes" />

    <PrimaryCareProvider :primary-care-provider="plan?.primaryCareProvider"></PrimaryCareProvider>

    <b-collapse :open="false" position="is-bottom" v-if="isAdmin && !isPdf">
      <template #trigger="props">
        <a class="is-pulled-right">
          <b-icon :icon="!props.open ? 'menu-down' : 'menu-up'"></b-icon>
        </a>
      </template>
      <pre>{{ plan }}</pre>
    </b-collapse>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import ContactList from './ContactList';
import PayerNotes from './PayerNotes';
import PrimaryCareProvider from './PrimaryCareProvider';

export default {
  name: 'InquiryDetailPayer',
  components: {
    ContactList,
    PayerNotes,
    PrimaryCareProvider,
  },
  props: {
    responsePayer: String,
    plan: Object,
    payer: Object,
    payerNameOverride: String,
    isPdf: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters(['isAdmin']),
    // Don't show subtitle if its the same as the title or if the
    // payer is a group. If the payer is a group, the subtitle would
    // be one of the individual payers (eg. Anthem North Dakota).
    // Which would confused the user.
    showSubtitle() {
      return this.responsePayer && this.responsePayer !== this.payer.name && !this.payer.is_group;
    },
    // Return the CSS class for the status.
    statusClass() {
      if (['1', '2', '3', '4', '5'].includes(this.plan.status_code)) {
        return 'is-success';
      }
      if (['0', '6', '7', '8'].includes(this.plan.status_code)) {
        return 'is-danger';
      }
      return 'is-info';
    },
  },
};
</script>
