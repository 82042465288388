import Vue from 'vue';

// Pretty JSON
Vue.filter('pretty', function (value) {
  return JSON.stringify(value, null, 2);
});

// Currency
var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});
Vue.filter('currency', function (value) {
  if (isNaN(value)) {
    return '';
  }
  return formatter.format(value);
});

// Date
Vue.filter('formatDate', function (value) {
  const [year, month, day] = value.split('T')[0].split('-');

  const formattedDate = `${month}/${day}/${year}`;

  return formattedDate;
});

// Date and Time
Vue.filter('formatDateTime', function (value) {
  if (!value) return '';

  value = new Date(value);
  
  return value.toLocaleString();
});

// Title case
Vue.filter('title', function (value) {
  if (!value) return '';
  value = value.toString().toLowerCase();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter('titleCase', function (value) {
  value = value.toLowerCase().split(' ');
  for (var i = 0; i < value.length; i++) {
    value[i] = value[i].charAt(0).toUpperCase() + value[i].slice(1);
  }
  return value.join(' ');
});
