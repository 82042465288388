<template>
  <div>
    <b-loading :is-full-page="true" v-model="loading"></b-loading>
    <h1 class="title is-3 has-text-centered mb-6">Change your plan at any time.</h1>
    <div v-if="subscription" class="block">
      <div class="columns">
        <div class="column" v-for="plan in filteredOptions" :key="plan.id">
          <SubscriptionOption
            :is-active="subscription.plan_id == plan.id"
            :plan="plan"
            @click="handleSelect"
          />
        </div>
      </div>
      <div class="has-text-centered box">
        <div class="block">
          <p>Activate the Reimbursement Estimates feature for an additional $500/month.</p>
          <p>
            <br />
            Gain access to historical payer data.
            <b-notification type="is-danger" role="alert" v-if="error">
              {{ error }}
            </b-notification>
            <a @click="showModal = !showModal">See details</a>
            <span>&nbsp;</span>
            <a v-if="!subscription.has_feature_reimbursement" @click="showCoupon = !showCoupon">
              Apply Coupon
            </a>
          </p>
          <div class="block" v-if="showCoupon">
            <div class="columns">
              <div class="column"></div>
              <div class="column">
                <b-field label="Coupon">
                  <b-input
                    v-model="promotion"
                    type="text"
                    placeholder="Enter coupon code"
                  ></b-input>
                </b-field>
              </div>
              <div class="column"></div>
            </div>
          </div>
        </div>
        <b-switch
          size="is-medium"
          @input="handleFeature($event)"
          v-model="subscription.has_feature_reimbursement"
        >
          Reimbursement Estimate Feature
        </b-switch>
      </div>

      <div class="has-text-centered mt-6">
        <b-button type="is-danger is-outlined" size="is-small" @click="onManageSubscription">
          Cancel Subscription
        </b-button>
      </div>
    </div>
    <b-modal v-model="showModal" :width="640" scroll="keep">
      <reimbursement-feature-detail></reimbursement-feature-detail>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import request from '../request';
import SubscriptionOption from './SubscriptionOption';
import ReimbursementFeatureDetail from './ReimbursementFeatureDetail';

export default {
  name: 'SubscriptionChange',
  components: {
    SubscriptionOption,
    ReimbursementFeatureDetail,
  },
  data() {
    return {
      loading: false,
      options: [],
      subscription: null,
      showModal: false,
      showCoupon: false,
      promotion: '',
      error: '',
    };
  },
  computed: {
    ...mapGetters(['organization']),
    activePlan() {
      return this.options.find((opt) => this.subscription.plan_id === opt.id);
    },
    filteredOptions() {
      return this.options
        .filter((opt) => {
          // Show enabled as well as the activesubscription if its grandfathered.
          return opt.is_enabled || opt.id === this.subscription.plan_id;
        })
        .sort((a, b) => {
          // Sort so tiers are shown small to large.
          return a.tier > b.tier;
        });
    },
  },
  mounted() {
    this.loading = true;
    Promise.all([this.fetchCurrentSubscription(), this.fetchPlans()]).finally(
      () => (this.loading = false)
    );
  },
  methods: {
    fetchCurrentSubscription() {
      return request
        .get('/subscriptions/current')
        .then((resp) => (this.subscription = resp.data.subscription));
    },
    fetchPlans() {
      return request.get('/plans').then((resp) => {
        this.options = resp.data.plans;
      });
    },
    onManageSubscription() {
      this.loading = true;
      request.post('/subscriptions/portal-session').then((resp) => {
        window.location.href = resp.data.redirect_url;
      });
    },
    handleSelect(plan) {
      let msg = `<p>This will change you to the ${plan.name}.</p><br/><p>Your base monthly fee will be prorated and you will be billed/credited at the end of your normal billing cycle.`;
      if (!this.activePlan.is_enabled) {
        msg =
          msg +
          `<br/><br/> <strong>Your current plan will be unavailable once you change.</strong>`;
      }
      this.$buefy.dialog.confirm({
        title: `Change Plan`,
        message: msg,
        confirmText: 'Agree',
        type: 'is-success',
        onConfirm: () => {
          this.loading = true;
          request.post('/subscriptions/change', { new_plan_id: plan.id }).then(() => {
            this.$buefy.toast.open({
              message: `You have changed to ${plan.name}`,
              type: 'is-success',
            });
            // Refetch to update visible subscription.
            this.fetchCurrentSubscription().finally(() => (this.loading = false));
          });
        },
      });
    },
    handleFeature(event) {
      let action = event ? 'activate' : 'deactivate';
      let actionTitle = action.charAt(0).toUpperCase() + action.slice(1);
      let msg = `<p>This will ${action} Reimbursement Estimates.</p>`;
      if (action == 'activate') {
        msg = `${msg} <br/><small>By activating this feature you are agreeing to the QuickAdmit Terms & Conditions.</small>`;
      }
      this.$buefy.dialog.confirm({
        title: 'Reimbursement Estimates',
        message: msg,
        confirmText: actionTitle,
        type: 'is-success',
        onCancel: () => {
          // This ensures the switch returns to its original position on cancel.
          this.loading = true;
          this.fetchCurrentSubscription().finally(() => (this.loading = false));
        },
        onConfirm: () => {
          this.loading = true;
          request
            .post('/subscriptions/change', {
              has_feature_reimbursement: event,
              promotion: this.promotion,
            })
            .then(() => {
              this.$buefy.toast.open({
                message: `You have ${action}d Reimbursements.`,
                type: 'is-success',
              });
              this.error = '';
              this.showCoupon = false;
            })
            .catch((err) => {
              // Refetch to update visible subscription.
              if (err.response && err.response.status == 422) {
                this.error = err.response.data.errors.NOFIELD;
              }
            })
            .finally(() => {
              this.fetchCurrentSubscription().finally(() => (this.loading = false));
            });
        },
      });
    },
  },
};
</script>
