<template>
  <section class="section">
    <div class="container is-max-hd">
      <div class="has-text-centered mt-3">
        <p class="mb-5"><img src="/static/logo-wide.png" /></p>
      </div>
      <div class="block mt-3 mb-6">
        <div class="has-text-centered">
          <h3 class="title is-3">Subscription Agreement</h3>
        </div>
      </div>
      <hr />
      <div class="columns">
        <div class="column is-10 is-offset-1">
          <div class="box content">
            <p>
              This Subscription Agreement (“Agreement") is between you (“you,” “your” and/or
              “Healthcare Provider”) and Baradar, Inc. (“we,” “us,” “our,” and/or Baradar”). Please
              read this Agreement carefully as it governs your use of, and access to, QuickAdmit.
              For the purposes of this Agreement, you and Baradar are sometimes referred to
              individually as a “Party” and collectively as the “Parties.”
            </p>

            <div class="block">
              <h3 class="title is-3">INTRODUCTION</h3>

              <p>
                Baradar’s product, QuickAdmit, is a cloud-based software-as-a-service (“SAAS”)
                application which includes a user interface, access to health insurance eligibility
                information, and information storage (“QuickAdmit”), which are used to assist
                healthcare providers with electronic verification of individual health insurance
                eligibility and benefits (“Health Insurance Information”). Your use of QuickAdmit
                interested in improving its verification of individual health insurance eligibility
                for Healthcare Provider’s business as a health care provider (“Healthcare
                Practice”), and for this reason, it and Baradar enter into this Agreement. In
                addition, the following statements apply to this Agreement:
              </p>
              <ol>
                <li>
                  Using QuickAdmit, you will be able to easily verify Health Insurance Information
                  for a potential or current healthcare patient (“Patient”). QuickAdmit provides
                  assistance with tasks associated with verification of Health Insurance
                  Information, such as, inputting healthcare practice identity information, the
                  Patient’s name, identity information, health insurance, health information, and
                  potential treatment information to verify and obtain data associated with the
                  Patient’s health insurance eligibility and benefit information.
                </li>
                <li>
                  QuickAdmit is owned and managed by Baradar. The content provided by the QuickAdmit
                  is owned by Baradar, unless otherwise indicated in this Agreement. As used in this
                  Agreement, the term “Baradar” includes Baradar’s officers, subsidiaries,
                  affiliates, parent companies, joint ventures, and any other corporate entities
                  under common ownership, successors, assigns, directors, officers, agents, service
                  providers, suppliers, and employers.
                </li>
                <li>
                  This Agreement contains terms and conditions which govern the relationship between
                  Healthcare Provider and Baradar regarding the use of QuickAdmit. Together with any
                  additional terms, updates, and/or supplements, this Agreement and all Baradar
                  rules and policies to which you and your Healthcare Practice agree to when
                  registering for and/or using QuickAdmit. The terms and conditions may be updated
                  periodically. Any updates to the Terms and Conditions will be reflected in
                  QuickAdmit’s user interface. Together, this Agreement, the Terms and Conditions,
                  and Data Collection and Privacy Statement, both of which may updated on our user
                  interface, constitute the entire agreement regarding your use of QuickAdmit. The
                  Agreement, the Terms and Conditions, and the Privacy Statement each includes
                  legally binding terms and conditions governing your license to use QuickAdmit.
                  These Terms and Conditions apply to all persons who use QuickAdmit, regardless of
                  their level of participation.
                </li>
                <li>
                  You are registering for and will be using Baradar’s proprietary services,
                  QuickAdmit, to access Baradar’s systems, databases, applications, processes, and
                  other Baradar assets. You acknowledges that in exchange for use of QuickAdmit, you
                  will be charged a monthly or yearly fee as agreed upon by you and Baradar. The
                  agreed-upon fee will be available on the user interface of QuickAdmit. Therefore,
                  the (i) terms and conditions stated herein, and (ii) any updated terms and
                  conditions in the future and accessible in QuickAdmit’s user interface (current
                  and future terms and conditions are collectively referred to as “Terms”) apply to
                  the registration and any license to use and/or access QuickAdmit, including the
                  services and/or software applications associated therewith.
                </li>
                <li>
                  You are legally bound by this agreement. If you do not agree to the terms stated
                  in this agreement, do not sign this agreement, and cease all use of QuickAdmit.
                </li>
                <li>
                  BARADAR AND QUICKADMIT DO NOT GIVE MEDICAL ADVICE, NOR DO THEY PROVIDE MEDICAL OR
                  DIAGNOSTIC SERVICES.
                </li>
              </ol>
            </div>
            <h3 class="title is-3">TERMS & CONDITIONS</h3>

            <h5 class="title is-5">1. ACCEPTANCE OF TERMS</h5>

            <p>
              By using QuickAdmit, you are agreeing to comply with this Agreement and the Terms
              stated in this agreement, and as updated from time to time. If you object to these
              Terms, or any modifications thereof, your only recourse is to discontinue Baradar’s
              Services. If you become dissatisfied with QuickAdmit or other Baradar services at any
              time, your only recourse is to discontinue use of Baradar’s Services. Baradar may
              strictly enforce this Agreement and the Terms stated herein and as updated on its
              platform, through self-help, active investigation, litigation, and/or prosecution, but
              is not obligated to do so. Baradar may cease all or part of QuickAdmit at any time
              without notice and is not obligated to refund any monies received from you.
            </p>
            <p>
              By executing this Agreement, and/or by accessing or using QuickAdmit, you verify that
              you have read, understand, and agree to these Terms. By continuing to use QuickAdmit
              after notification of changes to these Terms, you verify that you have read,
              understood, and agree to the modified Terms.
            </p>
            <p>
              If the user interface on QuickAdmit prompts you to indicate that you read and
              understood the Terms, you understand that you must click to agree to the Terms prior
              to accessing QuickAdmit. You affirm that you, and your officers, directors, officers,
              agents, service providers, and employees who use QuickAdmit (“your users”) are more
              than 18 years of age, or an emancipated minor, or possess legal parental or guardian
              consent, and that each person using QuickAdmit is able and competent to enter into the
              terms, conditions, obligations, affirmations, representations, and warranties set
              forth in these Terms, and to comply with these Terms. You represent that the person
              executing this agreement has authority to bind you to this Agreement. If you are an
              individual user, you also agree that you have read, understand and accept the terms,
              conditions, obligations, affirmations, representations, and warranties set forth in
              these Terms, and to abide by and comply with these Terms. You acknowledge these Terms
              are a binding and enforceable agreement between you and Baradar.
            </p>
            <p>
              By executing this Agreement, and/or by accessing or using QuickAdmit, you agree that
              agree that you, and your users will not use QuickAdmit for any purpose that is
              unlawful or in contravention of these. Baradar reserves the right to change the Terms
              from time to time without notifying you. Should you object to or disagree with such
              changes, you agree that your only recourse is to cease using QuickAdmit. By continuing
              to access and/or use QuickAdmit after the effective date of any such changes you and
              your users ratify such changes and agree to be bound by the modified Terms.
            </p>
            <p>
              Baradar recommends that you and your users review the Terms, which are available in
              QuickAdmit’s user interface.
            </p>

            <h5 class="title is-5">2. USE OF QUICKADMIT</h5>

            <p>
              This section governs your use of QuickAdmit and the information provided by Baradar.
              You represent and warrant that you have read, understand, and agree to each of the
              following provisions:
            </p>

            <ol>
              <li>
                Compliance with HIPAA. By accessing or using QuickAdmit, you accept and agree to
                these Terms and the Data Collection and Privacy Statement. You agree that you will
                restrict access to and use of QuickAdmit to healthcare representatives and providers
                and employees thereof who are permitted to access the private health information and
                documentation available through QuickAdmit under all applicable laws, rules, and
                regulations unless otherwise agreed to by Baradar in writing. If you are a Covered
                Entity as defined in the Health Insurance Portability and Accountability Act of 1996
                (hereinafter “HIPAA”), you accept and agree to ensure that your users of QuickAdmit
                comply with HIPAA and, as stated below, you agree to indemnify Baradar from any
                claimed violations of HIPAA arising out of your or your users’ actions and/or
                inactions. You acknowledge that HIPAA governs the use and transmission of
                individually identifiable health information, including setting rules for health
                care providers and health insurance companies about who can look at and receive an
                individual’s health information. To that end, you acknowledge that you are
                responsible for compliance with HIPAA regulations.
              </li>
              <li>
                Necessary Equipment. You warrant that you are not prohibited from receiving products
                of U.S. origin, including services or software. You must provide all equipment and
                software necessary to connect to QuickAdmit, including, but not limited to, a
                computing device and internet connection that are suitable to connect with and use
                QuickAdmit. You are responsible for any fees, including Internet connection or
                mobile fees that you or your users incur when accessing QuickAdmit.
              </li>
              <li>
                Use Limited to Business Purposes. You and your users agree that you will use
                QuickAdmit only in connection with the conduct of your business as a healthcare
                representative or healthcare provider.
              </li>
              <li>
                Compliance with Current and Future Laws and Regulations. You shall at all times use
                QuickAdmit under and in compliance with the laws and regulations of the jurisdiction
                in which you are using QuickAdmit, and in compliance with all lawful acts, rules,
                and regulations and orders of any commissions, boards or other legislative,
                executive or judicial bodies or officers having power to regulate or supervise the
                use of the Service, including those related to data privacy, health care privacy,
                international communications, and the transmission of technical or personal data.
                Healthcare Provider will not utilize the Service for any improper and/or illegal
                actions.
              </li>
              <li>
                Account Security. To access and/or use QuickAdmit, you will be required to create an
                account. You agree that you are responsible for all use of QuickAdmit that occurs
                under your account, without regard to who uses QuickAdmit, whether such use is by
                you, your users, or an unauthorized third party. You agree that it is your
                responsibility to keep and maintain the confidentiality of your account credentials
                (e.g., your username and password). You agree that you or your users will not
                disclose your account credentials to any third parties outside of your Healthcare
                Practice. You agree that you will only disclose your account credentials within your
                Healthcare Practice to the extent necessary for the legitimate use of QuickAdmit,
                and that you must maintain all reasonable security provisions to protect the
                integrity of the information provided by QuickAdmit, and the information that you
                disclose to Baradar by using QuickAdmit. You agree to immediately notify Baradar in
                the event of any unauthorized access to or use of your account or other breach of
                security. If at any time, you know or suspect unauthorized copying or distribution
                of the content from QuickAdmit, you agree to notify Baradar immediately and use all
                reasonable efforts to stop such unauthorized copying or distribution.
              </li>
              <li>
                Security Safeguards. You agree to collect, store, and use all information provided
                to you by QuickAdmit, or by any third party for the purpose of using QuickAdmit, or
                obtained as a result of this Agreement (“Information”) in a safe and secure manner.
                This includes implementing appropriate and reasonable administrative, technical, and
                physical safeguards to protect the security, confidentiality, and integrity of the
                Information, to protect against anticipated threats or hazards to the security or
                integrity of the information and QuickAdmit, and to prevent use or disclosure of
                Information other than as provided for by this Agreement. Such safeguards shall
                include, without limitation, electronic firewalls or similar barriers, password
                protected access, and encryption of all communications in accordance with industry
                security standards. You shall develop and maintain a written information privacy and
                security program that include administrative, technical, and physical safeguards
                appropriate to the size and complexity of your operations and the nature and scope
                of your activities. You agree to provide Baradar with your current policies upon
                request. You agree to establish and maintain additional physical, electronic, and
                procedural safeguards to protect the Information from unwarranted disclosure at
                Baradar’s request.
              </li>
              <li>
                Theft or Accidental Disclosure. If there is any actual or suspected theft of,
                accidental disclosure of, loss of, or if you are unable to account for any
                information or any unauthorized intrusions into your systems affecting information
                in QuickAdmit and/or third-party data (“Security Incident”), you shall report the
                Security Incident to Baradar, within 24 hours after you know, or should reasonably
                should have known of the Security Incident. The report shall include: (i) an
                estimate of the disclosure or intrusion on Baradar, (ii) the corrective action you
                will take. You shall complete the corrective action, both on your systems, and, if
                affected, Baradar’s systems, at your cost and expense and within a reasonable period
                of time.
              </li>
              <li>
                Purpose of QuickAdmit. QuickAdmit is intended to assist you with obtaining the
                Health Insurance Information of the Patient who may be treated by your Healthcare
                Practice. It is not intended to be used by you to obtain information for any other
                healthcare practice. You warrant and agree that you and your users will not use
                information obtained from QuickAdmit for any improper or illegal purpose or act,
                including, but not limited to patient brokering. You also agree not to share the
                Patient’s Health Insurance Information with any unauthorized user in any manner.
              </li>
              <li>
                Healthcare Provider’s Sole Responsibility for Use of Information. You understand
                that although QuickAdmit will assist to you to obtain the Health Insurance
                Information of potential patients, Baradar is not your agent or representative. You
                acknowledge that the Health Insurance Information obtained through QuickAdmit is for
                informational purposes only and not a guarantee of payment, authorization,
                acceptance by the Patient’s health insurance company, or accuracy as to a final
                amount. You have sole responsibility and liability for making treatment and/or
                patient admissions decisions based on the Patient’s Health Insurance Information,
                obtaining payments from the Patient for all healthcare treatments, medications,
                durable medical equipment, and any other service provided by your Healthcare
                Practice, or any other activities associated with a healthcare practice or product.
                You are responsible for monitoring such activities to ensure accuracy, completeness,
                and compliance with any applicable rules and regulations. Baradar will attempt to
                obtain the Health Insurance Information accurately; however, you agree that you are
                solely responsible and liable for verifying the accuracy of the Health Insurance
                Information gathered through use of QuickAdmit. You understand that failure to
                independently verify the Health Insurance Information may cause you to incur a
                significant cost, which you agree that Baradar is neither responsible for nor liable
                for.
              </li>
              <li>
                Historical Data Estimates. You acknowledge that in connection with the use of
                QuickAdmit, Baradar will attempt, when available, to provide you with expected
                payment estimates based upon historical payer data on reimbursement. These estimates
                are not always available and are estimates, which are subject to change at any time.
                Baradar makes no guarantees with respect to the accuracy of these estimates, but
                will endeavor to attempt to provide the most accurate information when available and
                based upon those data points known to Baradar. You agree that your reliance on any
                estimates of historical payer data is not an actionable reliance and is provided
                solely for information purposes which can be, with other available information and
                at your risk, used in making business decisions. You acknowledge that by providing
                estimates of historical payer data, Baradar cannot guarantee future payment from
                third parties upon whose data the estimates may be derived. You acknowledge that
                there may be instances where no estimate can be provided given the lack of available
                data.
              </li>
              <li>
                Changes to Health Insurance Information; Indemnification. You acknowledge and agree
                that you are solely responsible for communicating with the Patient’s health
                insurance company to determine any changes that may occur to the Patient’s health
                insurance information.
              </li>
              <li>
                General Liability Insurance and Worker’s Compensation. If you are a corporation,
                limited liability company, or other legal entity, during the term of this Agreement,
                you shall obtain and maintain insurance, including worker’s compensation and
                commercial general liability insurance (with applicable riders), in amounts and on
                terms that are in accordance with acceptable standards in the industry, and as are
                acceptable to Baradar. If requested by Baradar in writing, the insurance vendor will
                furnish us with proof of such insurance. The insurance vendor may prove adequate
                insurance coverage by presenting Baradar with valid certificates of current
                coverage, certificates of renewal coverage, or proofs of premium payments.
              </li>
              <li>
                Health Insurance Policies. You agree that it is your sole responsibility to review
                and comply with any terms and conditions, privacy policies, and other rules and
                restrictions associated with covering the cost of the Patient’s medical expenses (
                “Health Insurance Coverage Policies”).
              </li>
              <li>
                Indemnification. You agree to indemnify Baradar against any claim, demand, and/or
                liabilities, including costs and expenses, asserted by the Patient or any
                third-party, resulting from or arising out of changes to the Patient’s Health
                Insurance Information and/or violations of Health Insurance Coverage Policies. By
                accessing and using QuickAdmit, you agree to bear all risks associated with the use
                of QuickAdmit. You agree that Baradar is not responsible for the Patient’s health
                insurance coverage responsibilities or the Patient’s health insurance payment
                responsibilities.
              </li>
              <li>
                Third-Party Agreements. You represent and warrant that your use of QuickAdmit does
                not conflict with any third-party agreements and you agree to indemnify and hold
                harmless Baradar if your use of QuickAdmit violates or conflicts with such
                third-party agreements.
              </li>
            </ol>

            <h5 class="title is-5">3. PRIVACY</h5>

            <p>
              Baradar respects your privacy and the privacy of your patients and is committed to
              protecting it. Our Data Collection and Privacy Statement (“Privacy Statement” explains
              Baradar’s policies and practices regarding your and your patients’ information.
              Through its Privacy Statement, Baradar intends to provide you with transparency in how
              it collects, uses, and safeguards personal and other information it collects or that
              you provide through QuickAdmit, and how you can contact Baradar if you have any
              questions or concerns. Baradar knows that our data handling practices are important to
              you, and to developing an enduring relationship with you. By using QuickAdmit, you
              agree to the terms of the Privacy Statement and agree to hold Baradar harmless from
              any breaches of Baradar’s privacy protections. Baradar reserves the right to modify
              its Privacy Statement in its reasonable discretion from time to time. Any changes to
              the Privacy Statement will be reflected in the user interface of QuickAdmit.
            </p>

            <h5 class="title is-5">4. FEES</h5>

            <p>
              You agree that you shall pay a monthly fee to have access to QuickAdmit, unless a
              different fee schedule is arranged, in writing, between you Baradar. Baradar will
              implement your account on the date of your first payment, or the first business day
              thereafter. The date of expiration shall be one month from that date of payment (e.g.,
              if payment occurred January 2, the date of expiration is February 2.) The monthly fee
              must be paid prior to the date of expiration to continue using QuickAdmit. All fees
              paid are non-refundable.
            </p>
            <p>
              Baradar reserves the right to periodically raise its fees for access to QuickAdmit.
              Should Baradar find it necessary to raise its fees, it will provide you with 60 days
              notice by way of email and/or notification on QuickAdmit’s user interface.
            </p>
            <p>
              If Baradar discontinues your access to QuickAdmit due to your violation of any of the
              Terms, as described in Section 10.c., your access to QuickAdmit may be terminated
              immediately, and Baradar will not refund any fees paid by you for the Service.
            </p>
            <p>
              Baradar only provides the service described herein. You are responsible for any fees
              incurred in order to access QuickAdmit, such as internet connectivity or cell data
              transmission. If you have any questions about such fees, please contact your provider.
            </p>

            <h5 class="title is-5">5. OTHER CONDITIONS/RESTRICTIONS</h5>

            <p>
              In addition to the conditions contained in other sections of this Agreement, the
              following restrictions apply to the use of QuickAdmit:
            </p>

            <ol>
              <li>You shall not use QuickAdmit for patient brokering;</li>
              <li>
                You shall not use QuickAdmit to obtain and share Protected Health Information,
                including Electronic Protected Health Information (collectively, “PHI”), which
                includes individually identifiable health information, with an unauthorized user;
              </li>
              <li>You shall not rely on QuickAdmit when making healthcare treatment decisions;</li>
              <li>
                You shall independently verify the Individual’s Health Insurance Information
                obtained as a result of QuickAdmit;
              </li>
              <li>
                You shall treat your account credentials as confidential information and not
                disclose your account credentials to anyone outside of your Healthcare Practice;
              </li>
              <li>
                You shall not share, or indicate that you will share, Baradar’s technology or
                processes with other, whether in public or private, and whether your knowledge of
                such technology and processes is through disclosure by Baradar or through your own
                observation;
              </li>
              <li>
                You shall not use QuickAdmit if you are located in a country embargoed by the United
                States or if you are on the U.S. Treasury Department’s list of Specially Designated
                Nationals;
              </li>
              <li>
                In connection with your use of QuickAdmit, you will not engage in any activity that
                may create a risk of injury, death, property damage, and/or liability of any kind;
              </li>
              <li>
                When accessing and/or using QuickAdmit, you shall not inflict emotional distress on
                any person, humiliate any other person, assault or threaten any other person;
              </li>
              <li>
                You shall not sublicense, rent, lease, sell, trade, gift, bequeath, or otherwise
                transfer your account to anyone without Baradar’s written permission;
              </li>
              <li>
                You shall not access or use a QuickAdmit account that has been sublicensed, rented,
                leased, sold, traded, gifted, bequeathed, or otherwise transferred from the original
                account creator without Baradar’s express written permission;
              </li>
              <li>You shall not build a competitive product or service;</li>
              <li>
                You shall not build a product or service using similar ideas, features, functions,
                images, sounds, icons, or graphics as Baradar and QuickAdmit; and
              </li>
              <li>
                You shall not copy any ideas, features, functions, logos, and/or graphics as Baradar
                and QuickAdmit.
              </li>
            </ol>
            <h5 class="title is-5">
              6. SYSTEM REQUIREMENTS, MAINTENANCE, UPDATES, AND LIMITATIONS ON SERVICE
            </h5>

            <p>
              This section governs system requirements, maintenance, updates and limitations on
              service. You represent and warrant that you have read, understand, and agree to each
              of the following provisions:
            </p>

            <ol>
              <li>
                Compatible Computer Equipment and Software. It is your responsibility, at your
                expense, to obtain, maintain, and operate suitable and fully compatible computer
                equipment and software for the use of QuickAdmit. As Baradar will update its Service
                from time to time, you may need to update your equipment accordingly, at your
                expense. The speed and performance of QuickAdmit may be affected by your internet
                speed, hardware, or software performance.
              </li>
              <li>
                Limitations or Delays Due to Internet Connection. Baradar’s Service may be subject
                to limitations, delays, and other problems inherent in the use of the internet and
                electronic communications. Baradar is not responsible for any delays, delivery
                failures, losses, or other damage resulting from such problems.
              </li>
              <li>
                Operation of QuickAdmit. Baradar reserves the right, in its sole discretion, to
                change, improve, correct, modify, suspend, discontinue or permanently cancel the
                operation of QuickAdmit or its individual features at any time without any notice to
                you, and without incurring any obligations or liabilities to you or to any
                third-party. Your use of QuickAdmit after any changes are posted to the user
                interface will be considered acceptance of such changes. You agree that Baradar is
                not responsible for any costs, loss of revenue, or loss of business of any kind
                resulting from the maintenance of QuickAdmit, or due to modifications, or
                elimination, of particular features, or as a result of QuickAdmit being inaccessible
                for any other reason.
              </li>
              <li>
                Software Updates. From time to time, Baradar will make available to you new
                versions, releases, and updates to QuickAdmit to solve defects and/or errors, keep
                QuickAdmit up-to-date with market developments, or otherwise improve QuickAdmit.
                These improvements may include bug fixes. Any changes to QuickAdmit will not affect
                its functionality for the purposes laid out in this agreement. Baradar will only
                support the most recent version of the Service. Although such updates may affect the
                accessibility of QuickAdmit during the updating process, Baradar shall make
                reasonable efforts to limit the the impact on you and your users during the process
                of updating the software. Baradar will advise you with reasonable notice of any
                material incremental cost increase associated with the updates and improvements.
              </li>
              <li>
                Resolution of Software Problems. You shall to provide notice of any errors, defects,
                or failures of QuickAdmit to Baradar by email at [{{ email }}]. When such notice is
                provided, you shall supply Baradar and its support vendors access to, and use of,
                all information and facilities reasonably necessary for Baradar to render support
                for any errors, defects, or failures of QuickAdmit. If such access is not permitted,
                or is limited by your internet speed, it may result in slower resolution time, or
                inhibit Baradar’s ability to resolve the errors. Baradar is not responsible for
                problems or failures of your Internet Service Provider or changes to your network
                made by you or any other third party.
              </li>
            </ol>

            <h5 class="title is-5">7. LIMITED LICENSE</h5>

            <p>
              This section governs your license to use QuickAdmit. You represent and warrant that
              you have read, understand, and agree to each of the following provisions:
            </p>

            <ol>
              <li>
                Limited License Granted to Healthcare Provider. We grant you a limited,
                non-exclusive, non-transferable license, subject to the terms of this Agreement, to
                access and use QuickAdmit, and related content, materials, information
                (collectively, the "Content") solely for approved purposes as permitted by us from
                time to time. Any other use of QuickAdmit or Content is expressly prohibited and all
                other right, title, and interest in the Site or Content is exclusively the property
                of Baradar and its licensors. You agree not to copy, transmit, distribute, sell,
                license, reverse engineer, modify, publish, or participate in the transfer or sale
                of, create derivative works from, or in any other way exploit any of the Content, in
                whole or in part. QuickAdmit and Content, including the selection, coordination, and
                arrangement of the same, are protected by, or subject to protection by, worldwide
                copyright, trademark, patent, trade secret, and/or other proprietary rights, laws,
                and treaty provisions whether or not a copyright notice or other proprietary mark is
                present. You shall not copy, nor permit to be copied, any version of QuickAdmit,
                including screenshots thereof, for any purpose other than for educational/training
                purposes your employees or for the purpose of obtaining technical support from
                Baradar.
              </li>
              <li>
                Approved Purpose. The only approved purpose for the access and use of QuickAdmit,
                and related Content, is for use in your Healthcare Practice.
              </li>
              <li>
                Rights Reserved by Baradar. All rights, title, and interest in QuickAdmit and
                Content not expressly granted to you in these Terms are reserved by Baradar. You
                agree not to dispute Baradar’s claims of ownership or validity of its rights to
                QuickAdmit and Content.
              </li>
              <li>
                Termination of Service for Violation of the Terms. Baradar may suspend or terminate
                all your rights and authorization at any time for any reason. If you, or your users,
                violates any of the Terms, your rights under this section will terminate at the sole
                discretion of Baradar. Such termination of your access to QuickAdmit and Content may
                occur without notice. If your access to QuickAdmit and Content is terminated
                pursuant to this provision, Baradar will not refund any fees paid for QuickAdmit.
              </li>
              <li>
                Obligations and Restrictions. Any right or authorization granted to Healthcare
                Provider by Baradar is subject to the following obligations and restrictions:
              </li>
            </ol>
            <ol>
              <li>
                You must retain, on all copies of the Content downloaded, all copyright and other
                proprietary notices contained in the Content and QuickAdmit;
              </li>
              <li>
                You may not use QuickAdmit or the Content with other information or in a manner that
                is unlawful or tortious. You, or your users, shall not use QuickAdmit or content in
                a manner that is threatening, infringing, abusive, harassing, defamatory, libelous,
                deceptive, fraudulent, invasive of another’s privacy, or in such a way that contains
                explicit or graphic descriptions, obscene. You shall not use QuickAdmit to
                victimize, harass, degrade, or intimidate an individual or group of individuals on
                the basis of religion, gender, sexual orientation, race, ethnicity, age, or
                disability;
              </li>
              <li>
                You may not send or store material containing software viruses, worms, Trojan
                horses, or other harmful computer code, files, scripts, agents or programs;
              </li>
              <li>
                You may not interfere with or disrupt the integrity or performance of QuickAdmit or
                the data contained therein;
              </li>
              <li>
                You may not attempt to gain unauthorized access to QuickAdmit or its related systems
                or networks;
              </li>
              <li>
                You may not register for or use QuickAdmit or Content in a manner that impersonates
                any other person, business or entity, including Baradar, and its employees and
                agents;
              </li>
              <li>
                You may not interfere, try to interfere, disrupt, or try to disrupt the Baradar
                servers or networks, or disobey any network access or security requirements,
                policies, directives, procedures, or regulations of Baradar or its representatives
                and agents who facilitate your use of QuickAdmit;
              </li>
              <li>
                You may not use QuickAdmit or Content to engage in conduct that reflects poorly
                upon, disparages or devalues Baradar’s reputation or goodwill, as determined in
                Baradar’s sole discretion; and
              </li>
              <li>
                You may not establish a link or otherwise hold yourself out as an exclusive user of
                QuickAdmit or suggest an affiliation with, approval of, or endorsement by Baradar
                when none exists;
              </li>
              <li>You must comply with all other obligations contained in this Agreement.</li>
            </ol>

            <h5 class="title is-5">8. THIRD-PARTY SERVICES</h5>

            <p>
              QuickAdmit may display, include or make available content, data, information,
              applications or materials from third parties (“Third Party Services”). By using
              QuickAdmit, you acknowledge and agree that Baradar is not responsible for examining or
              evaluating the content, accuracy, completeness, timeliness, validity, copyright
              compliance, legality, decency, quality or any other aspect of such Third Party
              Services or web sites. Any use of Third Party Services is at your own risk and subject
              to the terms of use with respect to such Third Party Services. We have no control over
              Third Party Services, and we disclaim all representations and warranties with respect
              to such Third Party Services in their entirety. Third Party Services are provided
              solely as a convenience to you. You agree to indemnify Baradar from all liability from
              your use of Third Party Services.
            </p>

            <h5 class="title is-5">9. USER CONTENT</h5>

            <p>
              Baradar wishes to continually expand and improve QuickAdmit. Baradar welcomes any
              ideas, suggestions, complaints, or feedback, related to QuickAdmit or for upgrades,
              updates, improvements, new features, or functions thereto (“Ideas”). If you provide
              Baradar with any Ideas, whether orally, in writing, or in any other way, you grant
              Baradar a non-exclusive, worldwide, royalty-free, sub-licensable and non-revocable
              license to develop, make, reproduce, import, modify, make derivative works of, sell,
              and offer to sell Ideas as part of Baradar’s technology, products or services. You
              shall not knowingly provide Baradar with any Ideas that are subject to third party
              intellectual property rights or that include or reveal any confidential information of
              any person. You are not entitled to any compensation, payment or commission based on
              the Ideas you provide to Baradar.
            </p>

            <p>
              All data, information and records that you transmit to Baradar through QuickAdmit
              shall remain your property. By transmitting your data to Baradar through QuickAdmit,
              you are permitting Baradar to use your data to provide you with the information you
              request through QuickAdmit. You shall have sole responsibility for the accuracy,
              quality, integrity, legality, reliability, appropriateness, and right to use of all
              the data that you transmit to Baradar. Baradar shall not be responsible or liable for
              the deletion, correction, destruction, damage, loss, or failure to store any of your
              data. In the event this you or Baradar terminate this Agreement for any reason,
              Baradar reserves the right to withhold, remove, and/or discard any of your data
              without notice for any breach, including, without limitation for non-payment breach.
              Upon termination of this Agreement, your right to access or use your data on Baradar’s
              Service immediately ceases, and Baradar shall have no obligation to maintain or
              forward any of your data.
            </p>

            <h5 class="title is-5">10. TERMINATION OF SERVICE</h5>

            <p>
              This section governs the term of this agreement and the ways for both you and Baradar
              to terminate this Agreement. You represent and warrant that you have read, understand,
              and agree to each of the following provisions:
            </p>

            <ol>
              <li>
                Term of the Agreement. The license term of this Agreement is one year commencing on
                the first date that QuickAdmit is available for your use (the “Effective Date”). All
                confidentiality and misappropriation provisions in this Agreement shall continue
                indefinitely. You may extend the Term of this Agreement for an additional one-year
                period upon payment of fees set by Baradar, which may be increased periodically.
              </li>
              <li>
                Termination by Healthcare Provider. If you choose to terminate your license to use
                QuickAdmit for any reason, you must provide no less than 60 days’ prior written
                notification to Baradar to cancel any periodic fees for QuickAdmit. The cancellation
                will take effect the day after the last day of the current subscription period. You
                will not receive a refund or credit for any partial subscription periods, except as
                expressly stated in these terms. Cancellation of your fees will automatically cancel
                any interests you may have in QuickAdmit .
              </li>
              <li>
                Termination by Baradar. Baradar, inits sole discretion, has the right (but not the
                obligation) to deactivate your account, or otherwise terminate your access to or use
                of QuickAdmit, immediately and without notice, for any reason, including, without
                limitation, if Baradar believes that you have acted inconsistently with the letter
                or spirit of these Terms. Further, you agree that Baradar shall not be liable to you
                or any third-party for our termination of your access to QuickAdmit. Further, you
                agree not to attempt to use QuickAdmit after said termination, unless otherwise
                permitted by Baradar in writing.
              </li>
              <li>
                Survival of Obligations. For the purposes of the Agreement, any termination of your
                license to use QuickAdmit will not affect, in any way, the continuing obligations
                relating to Baradar’s ownership in QuickAdmit, any covenants relating to the
                misappropriation, misuse or improper use or operation of the content and information
                contained therein, indemnification/hold harmless, limitation of liability, or any
                independent cause of action Baradar may have against you, as the same survives the
                termination of any or all of your license to use QuickAdmit, this Agreement, or any
                terminable provisions of this Agreement.
              </li>
            </ol>
            <h5 class="title is-5">11. ASSIGNMENT AND DELEGATION</h5>

            <p>
              Without the prior express written consent of Baradar, you may not assign this
              Agreement in whole or in part. Baradar may delegate or assign its duties and
              obligations under this Agreement to others without any notice to you.
            </p>

            <p>
              Baradar enters this agreement based on your representation that you are not a direct
              competitor of Baradar. You must provide notice to Baradar of any actual or proposed
              change in control of your corporation, limited liability company, or partnership that
              results or would result in a direct competitor of Baradar owning or controlling 50% or
              more of your corporation, limited liability company, or partnership, either indirectly
              or directly. Such notice shall entitle Baradar to terminate this Agreement immediately
              upon written notice.
            </p>

            <h5 class="title is-5">12. INTELLECTUAL PROPERTY AND OTHER PROPRIETARY RIGHTS</h5>

            <p>
              This section governs the Baradar’s intellectual property and other proprietary rights.
              You represent and warrant that you have read, understand, and agree to each of the
              following provisions:
            </p>
            <ol>
              <li>
                Definition. “Intellectual Property Rights” means unpatented inventions, patent
                applications, patents, design rights, copyrights, trademarks, service marks, trade
                names, domain name rights, know-how, and other trade secret rights, and all other
                intellectual property rights, derivatives thereof, and forms of protection of a
                similar nature anywhere in the world.
              </li>
              <li>
                Ownership of Intellectual Property Rights. This Agreement does not convey any rights
                of ownership in or related to QuickAdmit or the Intellectual Property Rights owned
                by Baradar. The Baradar name, the Baradar logo, and the product names associated
                with QuickAdmit, are trademarks of Baradar, and no right or license is granted to
                use them. All Intellectual Property Rights related to this Agreement and QuickAdmit
                shall be owned by Baradar (and its third-party providers, where applicable). Baradar
                shall own all rights, title and interest, including all related Intellectual
                Property Rights, including, but not limited to, trademarks, inventions, discoveries,
                writings, trade secrets, know-how, methods, practices, procedures, engineering
                information, designs, devices, improvements, and other technology, whether or not
                patentable or copyrightable, and any patent applications, patents, or copyrights
                based thereon in and to QuickAdmit and any suggestions, ideas, inventions,
                enhancement requests, feedback, recommendations or other information provided by you
                or any other party relating to QuickAdmit.
              </li>
              <li>
                Content and Compilation. The content and compilation of content included in
                QuickAdmit, such as sounds, text, graphics, logos, icons, images, audio clips,
                digital downloads, and software, are the property of Baradar, or its licensors, and
                are protected by the United States and international copyright laws. Such copyright
                protected content cannot be reproduced without Baradar’s express written permission.
              </li>
              <li>
                Trademark and Trade Dress. Baradar’s trademarks and trade dress, including, but not
                limited to the trade name QuickAdmit and the QuickAdmit trademark and associated
                logos, may not be used in connection with any product or service that is not
                Baradar’s, in any manner that is likely to cause confusion among consumers, or in
                any manner that disparages or discredits Baradar. You shall not register or use any
                trade name, trademark, logo, domain name or any other name or sign that relates to
                or incorporates any of Baradar’s intellectual property (in whole or part) or the
                subject matter of Baradar’s intellectual property or that is confusingly similar
                thereto.
              </li>
              <li>
                Prohibition. This Agreement strictly prohibits your use of the Baradar or QuickAdmit
                names, logos, or trademarks in any fashion without the express written consent of
                Baradar.
              </li>
              <li>
                Rights Reserved. Baradar reserves all rights not expressly granted in the Agreement.
                You will not take any action to jeopardize, limit, or interfere with Baradar’s
                intellectual property rights in the software, Service, products, and/or Baradar
                websites and applications.
              </li>
            </ol>
            <h5 class="title is-5">13. CONFIDENTIALITY</h5>

            <p>
              This section governs the confidentiality of information, which is disclosed by either
              Party, whether intentional, or by observation. All provisions in this section shall
              survive the termination of this Agreement for any reason. Baradar and you agree to the
              following:
            </p>

            <ol>
              <li>
                Confidential Information. You agree that the existence and the terms of this
                Agreement, including, but not limited to pricing, and any oral or written
                information exchanged between the you and Baradar in connection with the preparation
                and performance this Agreement are regarded as confidential information. Each Party
                shall maintain confidentiality of all such confidential information, and without
                obtaining the written consent of the other Party, it shall not disclose any relevant
                confidential information to any third parties, except for the information that: (a)
                is or will be in the public domain (other than through the receiving Party’s
                unauthorized disclosure); (b) is under the obligation to be disclosed pursuant to
                the applicable laws or regulations, or orders of the court or other government
                authorities; (c) is explicitly approved for release in writing by Baradar; or (d) is
                required to be disclosed by any Party to its employees, partners, shareholders,
                investors, legal counsels or accountants regarding the transaction contemplated by
                this Agreement, provided that such employees, partners, shareholders, investors,
                legal counsels or accountants, provided that such persons are bound by
                confidentiality obligations at least as stringent as those set forth in this
                section. Disclosure of any confidential information by your employees, partners,
                shareholders, or agencies hired by you shall be deemed disclosure of such
                confidential information by you. Disclosure of any confidential information shall be
                a material breach of the Agreement.
              </li>
              <li>
                Business Information. You agree that you will treat confidentially all information
                provided by Baradar regarding the Baradar’s businesses and operations, including but
                not limited to, QuickAdmit, software, design, formulas, algorithms, names of
                Baradar’s third-party providers, know-how, processes, Ideas, and other technical,
                business, financial, developmental and product plans. Subject to Section 13.c., any
                information provided to you by Baradar shall be used only for the sole for the
                purpose of receiving services pursuant to this Agreement, and shall not be disclosed
                to any third party, without the prior written consent of Baradar, except for a
                limited number of your employees, attorneys, accountants and other advisers on a
                need-to-know basis and solely for the purposes of receiving services under this
                Agreement.
              </li>
              <li>
                Protected Health Information. QuickAdmit provides you with protected health
                information (“PHI”) which is regulated under state and federal law. Under this
                Agreement, you may use PHI information solely to make business decisions regarding
                your Healthcare Practice. You shall comply with all federal and state laws regarding
                the transmission, storage and protection of all PHI disclosed to you by Baradar in
                the performance of this Agreement. You agree that any failure of Healthcare Provider
                to comply with the requirements of federal and/or state and/or local privacy laws
                shall be a material breach of the Agreement.
              </li>
              <li>
                Governmental Proceedings. If you become compelled in any court proceeding or are
                requested by a governmental body having regulatory jurisdiction over either you or
                Baradar to make any disclosure that is otherwise prohibited hereunder, you shall
                provide Baradar with prompt notice of such compulsion or request so that it may seek
                an appropriate protective order or other remedy or waive compliance with this
                section. In the absence of a protective order or other remedy, if you are compelled
                or requested to disclose the any information prohibited from disclosure by this
                Agreement, you may disclose such information on the required date for production or
                disclosure of the information.
              </li>
              <li>
                Remedy. In the event of an actual or threatened breach of the above confidentiality
                provisions, Baradar will have no adequate remedy at law and will be entitled to
                immediate injunctive and other equitable relief, without bond and without the
                necessity of showing actual money damages.
              </li>
            </ol>

            <h5 class="title is-5">14. NOTICES</h5>

            <p>
              Baradar may give notice by means of a general notice on published on the user
              interface of QuickAdmit, electronic mail to the e-mail address on record for you in
              Baradar’s account information, or by written communication sent by first-class mail or
              pre-paid post to your address on record in Baradar’s account information. Such notice
              shall be deemed to have been given upon the expiration of 48 hours after mailing or
              posting (if sent by first class mail or pre-paid post) or 12 hours after sending (if
              sent by email), or at the time of published on QuickAdmit’s user interface.
            </p>
            <p>
              Any notice, consent, approval, request or other communication required to be given
              under any of the Agreement to Baradar shall be in writing and shall be deemed
              effective (1) upon receipt if delivered by hand to an authorized individual to accept
              correspondences on behalf of Baradar, (2) on the date of delivery when sent by
              certified or registered mail, return receipt requested.
            </p>

            <p>In general, communications to Baradar may be made to:</p>

            <p>
              Baradar, Inc.
              <br />
              23282 Mill Creek Dr.
              <br />
              Suite 225
              <br />
              Laguna Hills, CA 92653
              <br />
            </p>

            <h5 class="title is-5">15. DISCLAIMER TO TERMS AND CONDITIONS</h5>

            <p>
              Baradar may at any time revise these Terms by updating the Terms on the QuickAdmit
              website and/or user interface. You shall be bound by such revisions and should,
              therefore, visit this QuickAdmit website and/or user interface to review the current
              Terms from time to time.
            </p>

            <p>
              HEALTHCARE PROVIDER AGREES THAT HEALTHCARE PROVIDER’S USE OF QUICKADMIT SHALL BE AT
              HEALTHCARE PROVIDER’S SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, BARADAR, AND
              THEIR AGENTS, OFFICERS, DIRECTORS, EMPLOYEES, PARENTS, AFFILIATES, SHAREHOLDERS, AND
              REPRESENTATIVES DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH
              QUICKADMIT AND HEALTHCARE PROVIDER’S USE THEREOF. BARADAR, AND ITS AGENTS, MAKE NO
              WARRANTIES OR REPRESENTATIONS ABOUT THE CONTENT AND THE PROCESSES PROVIDED BY THESE
              SERVICES AND ASSUMES NO LIABILITY OR RESPONSIBILITY FOR ANY (I) ERRORS, MISTAKES, OR
              INACCURACIES OF CONTENT OR THE PROCESSES, (II) PERSONAL INJURY OR PROPERTY DAMAGE, OF
              ANY NATURE WHATSOEVER, RESULTING FROM HEALTHCARE PROVIDER’S ACCESS TO AND USE OF
              QUICKADMIT, (III) ANY UNAUTHORIZED ACCESS TO OR USE OF BARADAR’S SECURE SERVERS AND/OR
              ANY AND ALL PERSONAL INFORMATION, INSURANCE INFORMATION, AND/OR FINANCIAL INFORMATION
              STORED THEREIN, (IV) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM
              BARADAR’S SERVERS, (V) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE
              TRANSMITTED TO OR THROUGH BARADAR’S SERVICES BY ANY THIRD-PARTY, AND/OR (VI) ANY
              ERRORS OR OMISSIONS IN ANY CONTENT OR PROCESSES OR FOR ANY LOSS OR DAMAGE OF ANY KIND
              INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, EMAILED, TRANSMITTED, OR
              OTHERWISE MADE AVAILABLE VIA QUICKADMIT AND ANY PROCESSES PROVIDED BY QUICKADMIT.
              BARADAR DOES NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT
              OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH QUICKADMIT OR ANY
              HYPERLINKED WEBSITE OR FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND BARADAR WILL
              NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN
              HEALTHCARE PROVIDER AND THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE
              PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, HEALTHCARE
              PROVIDER SHOULD USE HEALTHCARE PROVIDER’S BEST JUDGMENT AND EXERCISE CAUTION WHERE
              APPROPRIATE. NEITHER BARADAR NOR THEIR AGENTS ARE PROVIDING ANY LEGAL, TAX, MEDICAL,
              COUNSELING, ACCOUNTING, INVESTMENT, FINANCIAL OR ANY OTHER PROFESSIONAL SERVICES OR
              ADVICE.
            </p>

            <p>
              HEALTHCARE PROVIDER ALSO ACKNOWLEDGES AND AGREES THAT AS TO BARADAR, (i) HEALTHCARE
              PROVIDER ACCEPTS QUICKADMIT AS-IS, AND THAT BARADAR HAS NOT MADE, AND DOES NOT HEREBY
              MAKE, ANY REPRESENTATION OR WARRANTY OR COVENANT WITH RESPECT TO FITNESS,
              MERCHANTABILITY, CONDITION, QUALITY, DURABILITY OR SUITABILITY OF ITS SOFTWARE,
              SERVICE, OR ANY CONTENT, IN ANY RESPECT OR IN CONNECTION WITH, OR FOR THE PURPOSES AND
              USES OF HEALTHCARE PROVIDER, OR AS TO TITLE THERETO OR ANY OTHER REPRESENTATION OR
              WARRANTY OR COVENANT OF ANY KIND OF CHARACTER, EXPRESS OR IMPLIED WITH RESPECT
              THERETO; AND (ii) THAT IN NO EVENT SHALL BARADAR, OR ANY SUB-CONTRACTOR, SALESPERSON,
              OR AUTHORIZED SERVICE PROVIDERS HIRED BY BARADAR IN CONNECTION WITH THE TRANSACTIONS
              CONTEMPLATED BY THE AGREEMENTS, BE LIABLE TO HEALTHCARE PROVIDER OR ANY THIRD PARTY,
              WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE) OR ANY OTHER THEORY OF LIABILITY
              (WHETHER OR NOT THE POSSIBILITY OF SUCH DAMAGES OR LOSSES HAS BEEN NOTIFIED TO
              BARADAR), FOR ANY DIRECT OR INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE OR CONSEQUENTIAL
              DAMAGES WHATSOEVER, OR FOR LOST PROFITS, BUSINESS INTERRUPTION, SAVINGS, REVENUES,
              LEGAL COSTS AND/OR EXPENSES, OTHER ECONOMIC LOSS, LOSS OF (OR COST OF SUBSTITUTE)
              EQUIPMENT OR MATERIALS OF ANY KIND WHATSOEVER, WHICH MAY ARISE, DIRECTLY OR
              INDIRECTLY, IN ANY MANNER IN CONNECTION WITH: (i) THE USE OF OR ANY PROCESSES RELATED
              TO QUICKADMIT; (ii) FOR ANY PURCHASE, USE OR LICENSE OF ANY COMPUTER SOFTWARE; (iii)
              FOR ANY DATA FURNISHED UNDER OR AS A RESULT OF THE AGREEMENTS; (vi) THE DURATION IN
              WHICH HEALTH INSURANCE INFORMATION IS EFFECTIVE; (v) ANY INACCURACIES IN THE HEALTH
              INSURANCE INFORMATION; (vi) ANY OF HEALTHCARE PROVIDER’S TREATMENT DECISIONS; OR (vi)
              ANY OTHER ACTIVITIES ARISING OUT OF OR RELATING TO HEALTHCARE PROVIDER’S USE OF
              QUICKADMIT.
            </p>
            <p>
              IN NO EVENT SHALL BARADAR’S AGGREGATE LIABILITY EXCEED THE AMOUNTS ACTUALLY PAID BY
              AND/OR DUE FROM HEALTHCARE PROVIDER IN THE SIX-MONTH PERIOD IMMEDIATELY PRECEDING THE
              EVENT GIVING RISE TO SUCH CLAIM. IN NO EVENT SHALL BARADAR AND/OR ITS THIRD-PARTY
              PROVIDERS BE LIABLE TO ANYONE FOR ANY INDIRECT, PUNITIVE, SPECIAL, EXEMPLARY,
              INCIDENTAL, CONSEQUENTIAL OR OTHER DAMAGES OF ANY TYPE OR KIND (INCLUDING LOSS OF
              DATA, REVENUE, PROFITS, USE OR OTHER ECONOMIC ADVANTAGE) ARISING OUT OF, OR IN ANY WAY
              CONNECTED WITH QUICKADMIT, INCLUDING BUT NOT LIMITED TO THE USE OR INABILITY TO USE
              QUICKADMIT, OR FOR ANY CONTENT OBTAINED FROM OR THROUGH QUICKADMIT, ANY INTERRUPTION,
              INACCURACY, ERROR OR OMISSION, REGARDLESS OF CAUSE IN THE CONTENT, EVEN IF RECEIVING
              PARTY HAS BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
            </p>

            <p>
              Certain states and/or jurisdictions do not allow the exclusion of implied warranties
              or limitation of liability for incidental, consequential, or certain other types of
              damages, so the exclusions set forth above may not apply fully to you. Nevertheless,
              this Agreement reflects the intention of both Baradar and the Healthcare Provider to
              minimize to the fullest extent possible the implied warranties and liability for
              remote or uncertain damages to be claimed from Baradar.
            </p>

            <h5 class="title is-5">16. RELEASE AND COVENANT NOT TO SUE</h5>

            <p>
              To the maximum extent permitted by applicable law, Healthcare Provider hereby
              releases, and waives all claims and demands against Baradar and its officers,
              subsidiaries, affiliates, parent companies, joint ventures, any other corporate
              entities under common ownership, successors, assigns, directors, officers, agents,
              service providers, suppliers, and employers for claims, damages (actual and
              consequential), costs and expenses (including litigation costs and attorneys’ fees) of
              every kind and nature, arising out of or in any way connected with the use of
              QuickAdmit.
            </p>

            <h5 class="title is-5">17. THIRD-PARTY DISPUTES</h5>

            <p>
              You are solely responsible for any activities related to your independent verification
              of health insurance information, whether or not performed through QuickAdmit. Baradar
              reserves the right, but has no obligation, to become involved in any way with third
              party disputes, including, but not limited to, disputes between individuals and health
              insurance companies, individuals and health care practices, and health insurance
              companies and health care practices. You release Baradar and its officers,
              subsidiaries, affiliates, parent companies, joint ventures, any other corporate
              entities under common ownership, successors, assigns, directors, officers, agents,
              service providers, suppliers, employees, and employers from claims, demands and
              damages (actual and consequential) of every kind and nature, known and unknown,
              arising out of or in any way connected with any third party disputes, including,
              without limitation, any intellectual property dispute or any dispute over health
              insurance information.
            </p>

            <h5 class="title is-5">18. FORCE MAJEURE</h5>

            <p>
              Baradar shall not be liable for any delay or failure to provide QuickAdmit resulting
              from causes outside the reasonable control of Baradar, including without limitation
              any failure to perform hereunder due to unforeseen circumstances or cause beyond
              Baradar control such as acts of God, war, terrorism, riots, embargoes, acts of civil
              or military authorities, fire, floods, accidents, network infrastructure failures,
              strikes, or shortages of transportation facilities, fuel, energy, labor or materials.
            </p>

            <h5 class="title is-5">19. INDEMNITY AND HOLD HARMLESS</h5>

            <p>
              By using QuickAdmit you agree that you will defend, indemnify, and hold harmless
              Baradar, together with its subsidiaries, affiliates, parent companies, joint ventures,
              any other corporate entities under common ownership, successors, assigns, directors,
              officers, agents, service providers, suppliers, employees, and employers, from and
              against all the liabilities, liens, actions, claims, costs, taxes, damages and
              expenses (including reasonable attorneys’ fees and costs) if any third party brings a
              claim against Baradar in connection with, or arising out of (i) your breach of the
              Terms; (ii) your violation of any law, rule or regulation of the United States or any
              other country; (iii) your infringement or violation of the rights of any third parties
              (including intellectual property rights); (iv) your use of QuickAdmit; (v) any other
              party’s access and use of QuickAdmit with your account credentials or other
              appropriate security feature; (vi) violation of any laws related to HIPAA; or (vii)
              any other actions and/or inactions by Healthcare Provider which exposes Baradar to
              potential liability. Baradar reserves the right to assume the exclusive defense and
              control of any matter otherwise subject to your indemnification, and, in such case,
              you shall agree to cooperate with Baradar’s defense of such claim.
            </p>

            <h5 class="title is-5">20. ARBITRATION</h5>

            <p>
              Baradar hopes that it can resolve any dissatisfaction that you may have with its fees,
              QuickAdmit or the performance of this Agreement so that both parties may have a long
              and mutually productive relationship. For this reason, Baradar encourages you to bring
              any dissatisfaction or dispute to Baradar’s attention as soon as it arises. It is
              Baradar’s belief that most such problems can be resolved by good faith discussions
              between the parties. If the unfortunate situation arises in which a dispute between us
              cannot be resolved by discussions between us, Baradar believes that such disputes may
              be resolved through binding arbitration, which is more expeditious and less costly
              than court proceedings. You agree to the following provisions regarding arbitration:
            </p>

            <ol>
              <li>
                Arbitration at Baradar’s Discretion. Any controversy or claim arising out of or
                relating to this Agreement, or the breach thereof, between you and Baradar
                (including, without limitation, any individual currently employed or formerly
                employed by Baradar) shall, at the sole discretion of Baradar, be subject to binding
                arbitration, and judgment on the award rendered by the arbitrator may be entered in
                any court having jurisdiction thereof. In addition, all questions regarding the
                arbitrability of the dispute, including whether you and/or Baradar has agreed to
                arbitrate the dispute, shall be decided by such arbitration. By agreeing to
                arbitrate, all parties are waiving a jury trial.
              </li>
              <li>
                Arbitration Venue. The arbitration shall be held in Orange County, California,
                before a mutually acceptable arbitrator from the American Arbitration Association,
                pursuant to the Commercial Arbitration Rules of the American Arbitration Association
                existing at the time of arbitration. The parties also agree that the AAA Optional
                Rules for Emergency Measures of Protection and the Federal Arbitration Act shall
                apply to the proceeding. The arbitrator shall be a practicing attorney or retired
                judge with at least fifteen ten years of total working experience as such. You
                consent to personal jurisdiction in California and venue in Orange County in any
                action and agree to waive any right to proceed in any other jurisdiction or forum
                concerning a dispute between the parties or relating to this Agreement.
              </li>
              <li>
                Time for Submission of Demand. No demand for arbitration may be made after the date
                when the institution of legal or equitable proceedings based on such claim or
                dispute would be barred by the applicable statute of limitations.
              </li>
              <li>
                Punitive or Other Damages. The arbitrator is not authorized to award punitive or
                other damages not measured by the prevailing party’s actual damages.
              </li>
              <li>
                Injunctive Relief. A party may apply to the arbitrator seeking injunctive relief
                until an arbitration award is rendered or the dispute is otherwise resolved. A party
                also may, without waiving any other remedy, seek from any court having jurisdiction
                any interim or provisional relief that is necessary to protect the rights or
                property of that party pending the arbitrator’s appointment or decision on the
                merits of the dispute.
              </li>
              <li>
                Prevailing Party. If the arbitrator determines that a party has generally prevailed
                in the arbitration proceeding, then the arbitrator shall award to that party its
                reasonable out-of-pocket expenses related to the arbitration, including filing fees,
                arbitrator compensation, attorney’s fees, and legal costs.
              </li>
              <li>
                Confidentiality of the Arbitration Proceedings and Award. The arbitration
                proceedings and arbitrator’s award shall be maintained by the parties as strictly
                confidential, except as is otherwise required by court order or as is necessary to
                confirm, vacate or enforce the award and for disclosure in confidence to the
                parties’ respective attorneys, tax advisors, and senior management and to family
                members of a party who is an individual.
              </li>
              <li>
                Exchange of Information/Discovery. (1) The arbitrator shall require exchange by the
                parties of (i) the name and, if known, address and telephone number of each person
                likely to have knowledge of relevant information, identifying the subjects of the
                information, and (ii) non-privileged documents, including those in electronic form,
                that are relevant to the issues raised by any claim, defense or counterclaim or on
                which the producing party may rely in support of or in opposition to any claim,
                defense or counterclaim. The arbitrator shall limit such production based on
                considerations of unreasonable expense, duplication, and undue burden. (2) These
                exchanges shall occur no later than a specified date within 60 days following the
                appointment of the arbitrator. (3) At the request of a party, the arbitrator may at
                his or her discretion order the deposition of witnesses. Depositions shall be
                limited to a maximum of three depositions per party, each of a maximum of four hours
                duration unless the arbitrator otherwise determines. (4) The arbitrator may allow
                such other discovery as he or she determines is reasonably necessary for a fair
                determination of the dispute. Any dispute or objections regarding discovery or the
                relevance of evidence shall be determined by the arbitrator. All discovery shall be
                completed within 120 days following the appointment of the arbitrator unless the
                arbitrator otherwise determines.
              </li>
              <li>
                Survival of the Arbitration Provision. Your agreement to arbitrate shall survive the
                termination of the Service or this Agreement. Your agreement to arbitrate any
                disputes between us applies even if some person or entity claims that this Agreement
                is void, voidable, or unenforceable, for any reason.
              </li>
            </ol>
            <h5 class="title is-5">21. MISCELLANEOUS</h5>

            <ol>
              <li>
                Entire Agreement. This Agreement, together with any additional terms to which you
                agree when using any aspect of the Service constitute the entire agreement regarding
                the Service and supersede all prior or contemporaneous communications, whether
                electronic, oral or written between you and Baradar with respect to the Service.
              </li>
              <li>
                California Law & Jurisdiction. You agree that these this Agreement and the Terms
                stated herein are governed by, and construed in accordance with, the laws of the
                State of California, regardless of principles of conflict/choice of laws that may
                require the application of the laws of another jurisdiction. All lawsuits arising
                from or relating to the Agreements shall be brought in a competent Federal or State
                court of California, and you hereby irrevocably submit to the exclusive personal
                jurisdiction of such courts for such purpose.
              </li>
              <li>
                Interpretation. If a court determines that any term or condition in this Agreement,
                or any of the Terms contained herein, is illegal or unenforceable, then such
                provision(s) shall be construed, as nearly as possible, to reflect the intentions of
                the invalid or unenforceable provision(s), with all other provisions remaining in
                full force and effect.
              </li>
              <li>
                Healthcare Provider’s Location. Baradar and its third-party providers make no
                representation that the Service is appropriate or available for use in locations
                outside of the United States or that certain aspects of the Service are legally
                usable within all parts of the United States.
              </li>
              <li>
                Modifications. Except as expressly and specifically, set forth in these Terms, no
                representations, statements, consents, waivers, or other acts or omissions by
                Baradar shall be deemed a modification of this Agreement, or the Terms contained
                therein, nor be legally binding, unless documented in physical writing, hand signed
                by Healthcare Provider and a duly appointed officer of Baradar.
              </li>
              <li>
                Waivers. No delay or omission to exercise any right, power or remedy accruing to
                Baradar upon any breach or default by Healthcare Provider under the Agreements shall
                impair any such right, power or remedy of Baradar, nor shall any such delay or
                omission be construed as a waiver of any breach or default, or of any similar breach
                or default occurring, nor shall any waiver of a single breach or default be deemed a
                waiver of any subsequent breach or default, nor shall any single or partial exercise
                of any right preclude the exercise of any other right or any other remedy at law or
                in equity. A waiver by either Party of any breach of any provision of this Agreement
                shall not operate or be construed as a waiver of any subsequent breach of the same
                or any other provision of this agreement
              </li>
              <li>
                Terms and Conditions Binding on Both Parties. The Terms in the Agreement shall be
                binding upon and inure to the benefit of you and your permitted successors and
                assigns (subject to any other provision in the Agreements), and shall be binding
                upon and inure to the benefit of Baradar and its successors and assigns.
              </li>
              <li>
                No Employment Relationship. It is expressly acknowledged by the parties that Baradar
                is an “independent contractor” with respect to Healthcare Provider and nothing in
                this Agreement is intended or shall be construed to create any employer/employee
                relationship, partnership, or joint venture between the parties. No employee or
                agent of either party may be deemed an employee or agent of the other party by
                reason of this Agreement. Baradar shall be fully responsible for all tax liabilities
                arising from its status as an independent contractor. Each party shall notify the
                other and permit the other to participate in the resolution of any inquiry or audit
                relating to Baradar’s status as an independent contractor.
              </li>
              <li>
                Subject Headings. The subject headings of the paragraphs and subparagraphs of this
                Agreement are included for purposes of convenience only, and shall not affect the
                construction or interpretation of any of its provisions.
              </li>
            </ol>

            <p>
              HEALTHCARE PROVIDER EXPRESSLY ACKNOWLEDGES THAT HEALTHCARE PROVIDER HAS READ THE TERMS
              AND CONDITIONS AND UNDERSTAND THE OBLIGATIONS, RIGHTS, CONDITIONS, TERMS,
              RESTRICTIONS, CONDITIONS, AFFIRMATIONS, REPRESENTATIONS, AND WARRANTIES SET FORTH
              HEREIN. HEALTHCARE PROVIDER EXPRESSLY CONSENTS TO BE BOUND BY THE TERMS AND CONDITIONS
              OF THIS AGREEMENT AND GRANT TO BARADAR THE RIGHTS SET FORTH HEREIN.
            </p>

            <h3 class="title is-3">Data Collection and Privacy Statement</h3>

            <h5 class="title is-5">General Statement</h5>
            <p>
              Your privacy is important to us. This data collection and privacy statement
              (“Statement”) explains Baradar’s policies and practices and is an integral part of the
              Subscription Agreement with Baradar. This Statement explains:
            </p>

            <ul>
              <li>The Baradar organization and QuickAdmit;</li>
              <li>
                What personally identifiable and non-personally identifiable information of yours or
                a current or potential patient healthcare patient of yours (the “Individual”); is
                collected through your use of QuickAdmit;
              </li>
              <li>How we use the information;</li>
              <li>How we collect the information;</li>
              <li>How we share the information;</li>
              <li>The choices available to you regarding your information;</li>
              <li>How you can access and control your information; and</li>
              <li>
                The security measures we use to reduce the risk of loss, misuse, unauthorized
                access, disclosure, and alteration of your information.
              </li>
            </ul>

            <p>
              By explaining our policies and practices in this Statement we intend to be completely
              transparent in in how we collect, use, and safeguard personal and other information we
              collect or that you provide through Service so that you can feel comfortable with your
              use of the Service. This Statement also explains how you can contact us if you have
              any questions or concerns. It is our sincere hope that by disclosing our data
              collection and privacy practices, we will develop a honest and enduring relationship
              with you. By using QuickAdmit, you indicate that you agree to the terms of this
              Statement.
            </p>

            <h5 class="title is-5">Scope and Acceptance</h5>
            <p>
              This Statement applies to any persons or entities that use the Service. This Statement
              may be updated by Baradar from time to time. Baradar will provide notice of any
              materially significant changes to this Statement by posting a notice on the user
              interface of the Service or by emailing you. By accepting the Terms and Conditions
              stated in the Subscription Agreement, which includes this Statement, you consent to
              our collection, storage, use and disclosure of your personally identifiable and
              non-personally identifiable information as described in the Statement. Your execution
              of the Subscription Agreement with Baradar, and the downloading, accessing, and/or
              using any portion QuickAdmit will indicate your acceptance of the Statement. However,
              we may also ask you to indicate your acceptance in other ways, such as clicking a
              button or icon on the user interface of QuickAdmit indicating that you accept or agree
              to the Statement.
            </p>

            <h5 class="title is-5">Information About Our Organization and QuickAdmit</h5>

            <p>
              The business purpose of QuickAdmit is to provide a technology platform to automate and
              assist you with electronic verification of individual health insurance eligibility and
              benefits for the Individual. Such assistance includes, but is not limited to, handling
              certain tasks of inputting information including, but not limited to, an individual’s
              name, identity information, health insurance, and health information, including
              proposed treatment information, to verify and obtain data associated with the
              individual’s health insurance eligibility and benefit information in a quick and
              efficient manner. We collect certain information from you for the purpose of providing
              this service to you, and in order to improve the service that we provide. You may opt
              out of providing such information; however, we may not be able to provide you with the
              full functionality of QuickAdmit, or provide you any information at all, if you do not
              include necessary information for health insurance verification.
            </p>

            <h5 class="title is-5">Information We Collect From you</h5>
            <p>
              “Personally identifiable information” or “personal information” is information that
              can be associated with a particular person and could be used to identify that
              particular person, whether from the information alone or from a combination of other
              information that Baradar has or is likely to have access to. Baradar does not consider
              information that is anonymous or aggregated so that it can no longer be used to
              identify a particular person to be personal information.
            </p>
            <p>
              We collect personal information from you that you provide to us directly and
              voluntarily, such as when you register for or during your use of QuickAdmit. Such
              personal information that you provide to us voluntarily may include, but is not
              limited to your and/or the Individual’s name, physical address, email, telephone
              number, health insurance information, financial information, and potential treatment
              information.
            </p>
            <p>
              The Service will function properly only when all details of a health insurance
              verification request are available to us. Providing personal information will enable
              us to provide you with your or the Individual’s available health insurance and
              eligibility information. The details we may need include, but are not limited to,
              health insurance policy information, healthcare treatment coverage, the terms of the
              Individual’s copay, coinsurance, and deductible information.
            </p>
            <p>
              For each health insurance verification request you submit to us for processing,
              QuickAdmit may store in our databases your user authentication information, your
              identity information, the Individual’s identity information, the Individual’s health
              insurance information, the Individual’s health information, and a date and time the
              request was submitted to us. This verification request information is used for
              providing you with health insurance verification information and may be used for
              improving QuickAdmit. The personal information that you submit in a health insurance
              request, will be stored so that you may easily replicate the data for future searches.
              Additionally, we will store the health insurance verification information provided to
              you for at least 48 hours, so that we can efficiently process the same request should
              you require it.
            </p>
            <p>
              If a verification request generates an error, we may also store information about the
              request for the purpose of diagnosing the potential problem and implementing a
              solution. Such information will only be used for these purposes, unless otherwise
              indicated in this Statement. Any unnecessary data will be destroyed upon solution of
              the error and will not be made available to any third parties, unless required to
              implement the solution.
            </p>
            <p>
              Should you request a healthcare insurance verification, we will use your information,
              and that of the Individual’s, to generate a healthcare insurance verification request
              using QuickAdmit. The following information may be stored on our databases or on a
              third-party database for the purpose of determining usage of QuickAdmit, for
              compliance with HIPAA, for providing customer support, or for any of the purposes
              identified in this Statement and in your Subscription Agreement. Personally
              identifiable information associated with the creation of a verification request will
              only be available on an as needed basis to Baradar, its third party service providers,
              or you. Any other data, such as aggregate totals of verification requests or
              non-personally-identifiable data, may be used by Baradar and disclosed to others, but
              will not identify you or the Individual.
            </p>
            <p>
              We collect other information, non-personally identifiable information, from users of
              QuickAdmit. This means that we do not track these items on an individual basis that
              identifies the users of QuickAdmit, but rather gather and collect this information on
              an aggregate [or anonymous] basis that includes all such users. Non-personal
              information includes tracking web pages associated with QuickAdmit that are visited or
              the amount of time spent using QuickAdmit. Such data will include, but is not limited
              to: device identifiers, MAC address, location information, computer and connection
              information, internet service provider, operating system, IP address, browsing
              history, and web log information. Our primary goal in collecting aggregate information
              from you is to be able to perform metrics that allow us to improve the functionality
              of QuickAdmit. We also collect aggregate information in order to track page visits and
              peak usage times for QuickAdmit, and to perform click-through analysis and web page
              layout re-design.
            </p>
            <p>
              When you ask for customer support assistance or customer support assistance is
              reasonably necessary for your continued use of QuickAdmit, we may collect and store
              contact information that you provide, information about the customer support issue,
              and your user account information. We may also store any correspondence associated
              with the customer support assistance.
            </p>
            <p>
              We may also supplement the information we collect from you with information from
              third-party sources, such as health insurance clearinghouses, in order to perform or
              improve the Service.
            </p>
            <p>
              When providing personal information about the Individual, you agree that such personal
              information that you provide about someone else is provided with their written consent
              in compliance with HIPAA and that you alone are responsible for informing them as to
              how their personal information may be collected, used, and disclosed.
            </p>
            <p>
              We use session and persistent “cookies” and session logs to enhance the performance of
              the Service, and to provide you with the best user experience. QuickAdmit may use web
              beacons/GIF/pixel tags which are instrumentation tools that help us to determine, for
              instance, whether a page has been viewed or not and, if so, how many times. We may
              submit the aggregate data we collect through your use of the Service to third-party
              analytics companies in order to improve our services. We will never use tags, cookies,
              or other tracking devices for the purpose of advertising to you or to others.
            </p>

            <h5 class="title is-5">How We Use the Information We Collect</h5>
            <p>
              We collect, store, and use your information to provide you with our service and to
              improve QuickAdmit. We may use the information collected in connection with the
              Service:
            </p>

            <ol>
              <li>To create your account and allow your use of QuickAdmit;</li>
              <li>
                To provide you with the health insurance information that you request, collect fees,
                resolve disputes with you or third-parties, trouble-shoot any problems with your
                account, and for any other purposes related to QuickAdmit and consistent with this
                Statement and any applicable laws;
              </li>
              <li>To provide technical and other customer support or respond to user inquiries;</li>
              <li>
                To prevent fraud, security breaches, or other illegal activities or enforce the
                Terms and Conditions and other applicable policies;
              </li>
              <li>To notify you of service updates or updates to our policies and terms;</li>
              <li>To process a health insurance verification request for you;</li>
              <li>For accounting and litigation purposes;</li>
              <li>
                To retain for our records and provide you with your health insurance verification
                request history and payment transaction history for billing purposes and to improve
                QuickAdmit;
              </li>
              <li>
                To measure the use and effectiveness of our service and monitor traffic through
                QuickAdmit;
              </li>
              <li>
                To troubleshoot and diagnose any technical issues and implementing solutions
                thereto;
              </li>
              <li>To customize the user experience;</li>
              <li>
                To inform you about promotional activities or supplemental products and services;
              </li>
              <li>To solicit input or feedback about our products and services;</li>
              <li>To verify your personal information for accuracy, as may be necessary;</li>
              <li>
                To generate analytics that improve QuickAdmit, content, and product offerings and
                services;
              </li>
              <li>
                To compile aggregate user data that is stored in our databases, which may be used
                for marketing and other purposes related to QuickAdmit;
              </li>
              <li>To track your usage of QuickAdmit;</li>
              <li>
                To comply with legal requirements and our policies and/or use procedures reflected
                in the Terms and Conditions;
              </li>
              <li>
                To provide and promote our products and services that are related to QuickAdmit;
              </li>
              <li>
                To process your payment for the use of QuickAdmit or notify you of the status of
                your account; and
              </li>
              <li>To verify your identity (together, the “Purposes”).</li>
            </ol>

            <p>
              During or after the term of of your use of QuickAdmit, Baradar may use non- personal
              statistical data regarding the Service for purposes of enhancing the Service,
              aggregated statistical analysis, technical support and other business purposes.
              QuickAdmit.
            </p>
            <p>
              We retain your information and the Individual’s information as long as it is necessary
              and useful for our service. Upon your written request, your information and the
              Individual’s information will be deleted from closed accounts or made anonymous within
              a reasonable time. After it is no longer necessary for us to store your information
              and the Individual’s information, we will destroy your information and the
              Individual’s information in a secure manner.
            </p>

            <h5 class="title is-5">How We Collect Information</h5>
            <p>
              We collect information you voluntarily provide to us in connection with your
              registration and use of QuickAdmit.
            </p>
            <p>
              We may also obtain information about you and the Individual from outside sources. For
              example, we may obtain certain information you have previously provided to healthcare
              clearinghouses. We also may obtain commercially-available healthcare information from
              third parties.
            </p>
            <p>
              Other than the information collected through outside sources, including healthcare
              clearinghouses, and information that you voluntarily provide to us through your
              registration and use of QuickAdmit, information may also be collected in connection
              with certain software and Internet-based tools, which are explained in more detail
              herein below.
            </p>
            <p>
              A “cookie” is a small text file that helps us in many ways to make your use of
              QuickAdmit more enjoyable and meaningful to you. For example, cookies avoid you having
              to log in every time you come back to use QuickAdmit. They also allow us to tailor
              QuickAdmit or advertisements to better match your interests and preferences. There are
              a couple different types of cookies.
            </p>
            <p>
              A “session” cookie is stored only in your computer’s working memory (RAM) and only
              lasts for your browsing session. When you close all your browser’s windows, or when
              you shut down your computer, the session cookie disappears forever.
            </p>
            <p>
              A “persistent” cookie is stored on your computer’s hard drive until a specified date,
              which could be tomorrow, next week, or 10 years from now. Persistent cookies stay on
              your computer until either a) they expire, b) they are overwritten with newer cookies,
              or c) you manually remove them. Most browsers can be configured not to accept cookies;
              however, this may prevent you from having access to some of QuickAdmit’s functions or
              features.
            </p>
            <p>
              QuickAdmit may use third-party click tracking analytics tools for the purpose of error
              tracking, and other analysis. We do not use such tools for advertisement.
            </p>
            <p>
              QuickAdmit may use web beacons/GIF/pixel tags which are instrumentation tools that
              help us to determine, for instance, whether a page has been viewed or not and, if so,
              how many times. Emails or electronic newsletters we send may use tools (e.g., pixel
              tags or web beacons) to gather email metrics and information to improve the reader’s
              experience such as how many of the emails are opened, if they were printed or
              forwarded, the type of device (e.g., mobile or PC) from which they were opened, and
              the city, state, and county associated with the applicable IP address. In general, any
              electronic image viewed as part of a webpage, including an ad banner, can act as a web
              beacon.
            </p>
            <p>
              When you use QuickAdmit the server housing the pages may automatically generate a
              “session log.” We use session logs to help us determine how people travel through
              QuickAdmit. In this way, we can structure our pages so that the information most
              frequently visited is easier to find. By tracking page visits, we can also determine
              if the information we’re providing is being used. The data gathered may include the
              Internet Protocol (IP) address from which you came, the website or software
              application that referred you, the pages you visited and the date and time of those
              visits.
            </p>

            <h5 class="title is-5">Disclosure</h5>
            <p>
              We will disclose your information (in some cases personal information) with third
              parties that is parties other than Baradar, in the following circumstances:
            </p>

            <ol>
              <li>
                We may disclose your information to third-party service providers to perform
                services associated with our business, including health insurance verification
                request, payment processing, bill collection, website operations, data analysis,
                e-mail delivery, hosting services, customer service, and to assist us in our
                marketing efforts. We direct all such third party service providers to maintain the
                confidentiality of the information disclosed to them and to not use your information
                for any purpose other than to provide services for us.
              </li>
              <li>
                We do not disclose your personal information or the Individual’s personal
                information to third parties for their own purposes without your explicit consent.
              </li>
              <li>
                We may disclose aggregate information (information about you and other users and
                your/their use of QuickAdmit collectively, but not personally identifiable) to our
                affiliates, partners, subsidiaries, subscribers, and other third parties for their
                benefit.
              </li>
              <li>
                In the event that Baradar undergoes a business transaction, such as a merger,
                acquisition by another company, or sale of all or a portion of its assets, we may
                transfer all of your information, including personal information, to the successor
                organization in such transaction. If material changes to our privacy practices occur
                as a result of the business transaction, we will notify you and other users of the
                business transition prior to transferring your personal information.
              </li>
              <li>
                We may, at our discretion, disclose your information to our affiliates, partners,
                subsidiaries, or commonly owned or controlled companies. We may share such
                information as a normal part of conducting business and offering products and
                services to our customers.
              </li>
            </ol>

            <p>
              Vendors, contractors or partners of QuickAdmit who have access to your personal
              information in connection with providing services for QuickAdmit are required to keep
              the information confidential and are not permitted to use this information for any
              other purpose than to carry out the services they are performing for QuickAdmit.
            </p>
            <p>
              QuickAdmit will disclose your personal information, without notice, if (1) in the good
              faith belief that such action is necessary to: (a) conform to the edicts of the law or
              comply with legal process served on Baradar, its affiliates or QuickAdmit; (b) protect
              and defend the rights or property of Baradar its affiliates or QuickAdmit; or (c) act
              under exigent circumstances to protect the personal safety of Baradar or affiliate
              personnel, users of their Service, or the public; or (d) if required to do so by law.
            </p>
            <p>
              We do not share, sell, or rent personally identifiable information with independent
              companies for their own use without providing you a choice.
            </p>

            <h5 class="title is-5">Choices</h5>
            <p>
              You have a choice about how we use some or all of your personal information to
              communicate with you, send you marketing information, provide you with a customized
              experience, and contact others for your benefit. You may withdraw your consent to use
              your personal information for such purposes by notifying us by email at {{ email }},
              so that we may comply with your request. If you withdraw such consent, we may not be
              able to provide all the features of QuickAdmit to you.
            </p>
            <p>
              If you no longer want Baradar to make active use of your information, you may inform
              us by contacting us at the email and/or physical address provided above. We will
              respond to your request within a reasonable time period. Please note that certain
              records, for example those pertaining to payments or customer service matters, may be
              retained for legal or accounting purposes.
            </p>

            <h5 class="title is-5">Access To and Control over the Use of your Information</h5>
            <p>
              To correct or update your personal information contact us by email at
              {{ email }}, so that we may process your request. We will use reasonable efforts to
              correct any factual inaccuracies in your information.
            </p>
            <p>
              The scope of access available for this request is for information stored in the
              database that holds the data for QuickAdmit. Any personal information that has been
              collected from you on other databases will not be accessible or changeable through the
              processing of this request.
            </p>

            <h5 class="title is-5">
              Information that is collected by or sent to us may be stored and processed in the
              United States or any other country in which we or our affiliates, subsidiaries, or
              service providers maintain facilities. In this regard, or for purposes of sharing or
              disclosing data in accordance with this data collection and privacy statement, we
              reserve the right to transfer information outside of your country. By using QuickAdmit
              you consent to any such transfer of information outside of your country.
            </h5>
            <p>
              Users in certain jurisdictions have a right to access personal information held about
              themselves. Your right to access can be exercised in accordance with any applicable
              law. Please submit any request for such access to your personal information in writing
              to the email address or physical address provided above, so that we may process your
              request.
            </p>
            <p>
              We will honor any statutory right you might have to access, modify or erase your
              personal information or the personal information of the Individual. You can contact us
              at the email address or physical address provided herein to request access. Where you
              have a legal right to request access or request the modification or erasure of
              information, we can still withhold that access or decline to correct information in
              some cases in accordance with applicable law, but will provide you with our reason for
              doing so.
            </p>

            <h5 class="title is-5">Security of your Information</h5>
            <p>
              Safeguarding the information of our users is of utmost important to us. We use
              systems, policies and procedures to maintain accuracy of information and to protect
              information from loss, misuse or alteration.
            </p>
            <p>
              All usernames and passwords that we store in our databases are encrypted. In addition,
              When you provide personal information, such as email address or telephone numbers, to
              us through QuickAdmit, we use “Secure Socket Layers” or SSL to protect your data. SSL
              is encryption technology that helps safeguard your personal information against misuse
              by unauthorized individuals.
            </p>
            <p>
              Vendors, contractors or partners of QuickAdmit who have access to your personal
              information in connection with providing services related to the use and operation of
              QuickAdmit are required to keep the information confidential. Vendors, contractors, or
              partners are not permitted to use this information for any other purpose than to carry
              out the services they are performing for QuickAdmit.
            </p>
            <p>
              Although we have taken measures to safeguard your personal information, we cannot
              guarantee or ensure the absolute privacy of your personally identifiable information.
              For example, we may be required by law to disclose information to government or law
              enforcement agencies. We may also be required to disclose personal information to
              legal authorities in the event of a complaint or legal action arising from your use of
              QuickAdmit. In addition, third parties may gain unlawful access to your information.
              Accordingly, although it is our practice to safeguard your personal information, we
              cannot guarantee that your personal information will remain private.
            </p>

            <h5 class="title is-5">Privacy Policies of Third Party Links</h5>
            <p>
              QuickAdmit may contain advertisements from companies other than Baradar or links to
              other websites, applications, or services. We encourage you to review the data
              collection and privacy statements of all services, applications, or websites that you
              visit, including those whose links are provided in QuickAdmit, so that you can
              understand how your information may be collected, used and shared by them. We are not
              responsible for the data collection and privacy statements, content or data handling
              practices on other applications or websites.
            </p>

            <h5 class="title is-5">How Long is your Information or Data Kept by Baradar</h5>
            <p>
              We will retain your information for as long as is necessary to: (1) fulfill any of the
              Purposes included in this Statement or (2) comply with applicable legislation,
              regulatory requests and relevant orders from competent courts.
            </p>

            <h5 class="title is-5">Contacting Us</h5>
            <p>
              If you have any questions, comments or concerns about this Statement, our data
              collection and privacy practices, or have any questions regarding the content of
              QuickAdmit, please contact us by email at
              {{ email }}.
            </p>

            <h5 class="title is-5">Effective Date and Revisions</h5>
            <p>
              The effective date of the Statement is the date that we publish such statement. We
              will provide the most up-to-date Statement with our Subscription Agreement for your
              review. Occasionally we may update this Statement in order to reflect any changes to
              QuickAdmit or our privacy practices. When we post changes to the Statement, whether by
              within the interface of QuickAdmit, or by email or other communication method, we will
              update the “last updated” date at the bottom of the data collection and privacy
              statement. If there are material changes to this Statement or in how we will use your
              personal information, we will notify you. Such notification may occur through a
              prominent posting of the changes on QuickAdmit’s user interface , or by directly
              sending you a notification through email or mail. You agree that your continued use of
              QuickAdmit or associated websites or products after notice shall constitute your
              acceptance of the data collection and privacy statement. We encourage you to
              periodically review the Statement found on the user interface of QuickAdmit to be
              informed of how Baradar is collecting, using, and protecting your information.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'TermsAndConditions',
  data() {
    return {
      email: 'support@quickadmit.com',
    };
  },
};
</script>
