<!-- eslint-disable -->
<template>
  <b-field
    :message="form.errors.get(fieldName)"
    :type="{ 'is-danger': form.errors.has(fieldName) }"
  >
    <b-switch
      size="is-medium"
      v-model="form[fieldName]"
      :type="form.errors.has(fieldName) ? 'is-danger' : ''"
    >
      {{ label }}
    </b-switch>
  </b-field>
</template>

<script>
export default {
  name: 'form-switch',
  props: ['label', 'form', 'fieldName'],
};
</script>
