<template>
  <section class="section">
    <div class="container is-max-desktop">
      <div class="has-text-centered mt-3">
        <p class="mb-5"><img src="/static/logo-wide.png" /></p>
      </div>
      <div class="block mt-3 mb-6">
        <div class="has-text-centered">
          <h3 class="title is-3">Register your account</h3>
          <h6 class="subtitle is-6 pt-1">
            Or
            <router-link :to="{ name: 'Login' }">login if you're already registerd</router-link>
          </h6>
        </div>
      </div>

      <b-notification v-if="error" type="is-danger" role="alert">
        {{ error }}
      </b-notification>

      <div class="columns" v-if="invite">
        <div class="column is-three-fifths is-offset-one-fifth">
          <div class="box">
            <div class="block">
              <b-notification v-if="form.errors.has('NOFIELD')" type="is-danger" role="alert">
                {{ form.errors.get('NOFIELD') }}
              </b-notification>

              <form @submit.prevent="handleSubmit" novalidate>
                <div class="block">
                  <b-field label="Email" v-if="invite">
                    <b-input disabled v-model="invite.email"></b-input>
                  </b-field>
                </div>

                <div class="columns">
                  <div class="column">
                    <b-field
                      label="First Name"
                      :message="form.errors.get('first_name')"
                      :type="{ 'is-danger': form.errors.has('first_name') }"
                    >
                      <b-input v-model="form.first_name" />
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field
                      label="Last Name"
                      :message="form.errors.get('last_name')"
                      :type="{ 'is-danger': form.errors.has('last_name') }"
                    >
                      <b-input v-model="form.last_name" />
                    </b-field>
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <b-field
                      label="Password"
                      :message="form.errors.get('password')"
                      :type="{ 'is-danger': form.errors.has('password') }"
                    >
                      <b-input type="password" v-model="form.password" password-reveal />
                    </b-field>
                  </div>
                  <div class="column">
                    <PhoneField v-model="form.phone" :errors="form.errors" />
                  </div>
                </div>
                <div class="block">
                  <b-checkbox
                    v-model="form.agree"
                    class="pt-2"
                    :type="form.errors.has('agree') ? 'is-danger' : ''"
                    :class="{ 'has-text-danger': form.errors.has('agree') }"
                  >
                    I agree to the
                    <router-link :to="{ name: 'TermsAndConditions' }">
                      Terms and Conditions
                    </router-link>
                  </b-checkbox>

                  <b-button
                    :loading="loading"
                    type="is-primary"
                    native-type="submit"
                    class="is-pulled-right"
                  >
                    Register Account
                  </b-button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Form from '../utils/form';
import request from '../request';
import PhoneField from '../components/fields/PhoneField.vue';

export default {
  name: 'RegisterInvite',
  components: {
    PhoneField,
  },
  data() {
    return {
      loading: true,
      error: '',
      invite: null,
      form: new Form({
        first_name: '',
        last_name: '',
        password: '',
        phone: '',
        invite_id: this.$route.params.id,
        agree: false,
      }),
    };
  },
  created() {
    // This doesn't use beforeRouteEnter so we can show a better error message.
    request
      .get(`/invites/${this.$route.params.id}`)
      .then((resp) => (this.invite = resp.data.invite))
      .catch(() => (this.error = 'This invitation is invalid or has expired.'))
      .finally(() => (this.loading = false));
  },
  methods: {
    handleSubmit() {
      this.loading = true;
      let inviteID = this.$route.params.id;
      this.form
        .post(`/auth/register/${inviteID}`)
        .then(() => {
          let msg = "You've registered and been logged in!";
          this.$buefy.toast.open({ message: msg, type: 'is-success' });
          this.$router.push({ name: 'InquiryList' });
        })
        .catch(() => {
          if (!this.form.errors.any()) {
            this.form.errors.errors.NOFIELD = 'An unknown error occured. Please try again.';
          }
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
