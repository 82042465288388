<template>
  <div>
    <breadcrumb-list>
      <breadcrumb-link view="Home" label="Home" />
      <breadcrumb-link view="OrganizationDetail" :label="organization" />
      <breadcrumb-link
        view="UserUpdate"
        :params="{ id: user.id }"
        :label="`Update ${user.first_name} ${user.last_name}`"
        :active="true"
        v-if="!loading"
      />
    </breadcrumb-list>

    <UserUpdateForm v-if="!loading" :user="user"></UserUpdateForm>
  </div>
</template>

<script>
import request from '../request';
import { mapGetters } from 'vuex';
import UserUpdateForm from '../components/UserUpdateForm';

export default {
  name: 'UserUpdate',
  components: {
    UserUpdateForm,
  },
  data() {
    return {
      loading: true,
      user: null,
    };
  },
  computed: {
    ...mapGetters(['organization']),
  },
  beforeRouteEnter(to, from, next) {
    request
      .get(`/users/${to.params.id}`)
      .then((resp) => next((vm) => vm.setData(resp.data.user)))
      .catch(() => next({ name: 'NotFound' }));
  },
  methods: {
    setData(user) {
      this.user = user;
      this.loading = false;
    },
  },
};
</script>
