<template>
  <div>
    <b-loading :is-full-page="true" v-model="loading"></b-loading>
    <h1 class="title is-3 has-text-centered mb-6">Choose the plan that is right for your team.</h1>
    <div class="block">
      <div class="columns">
        <div class="column" v-for="plan in filteredOptions" :key="plan.id">
          <SubscriptionOption :is-active="false" :plan="plan" @click="handleSelect" />
        </div>
      </div>
      <div class="has-text-centered box">
        <div class="block">
          <p>Activate the Reimbursement Estimates feature for an additional $500/month.</p>
          <p>
            <br />
            Gain access to historical payer data.
            <a @click="showModal = !showModal">See details</a>
          </p>
        </div>
        <b-switch size="is-medium" v-model="hasFeatureReimbursement">
          Reimbursement Estimate Feature
        </b-switch>
      </div>
    </div>
    <b-modal v-model="showModal" :width="640" scroll="keep">
      <reimbursement-feature-detail></reimbursement-feature-detail>
    </b-modal>
  </div>
</template>

<script>
import request from '../request';
import SubscriptionOption from './SubscriptionOption';
import ReimbursementFeatureDetail from './ReimbursementFeatureDetail';

export default {
  name: 'SubscriptionChange',
  components: {
    SubscriptionOption,
    ReimbursementFeatureDetail,
  },
  data() {
    return {
      loading: false,
      options: [],
      hasFeatureReimbursement: false,
      showModal: false,
    };
  },
  computed: {
    filteredOptions() {
      return this.options.filter((opt) => opt.is_enabled);
    },
  },
  mounted() {
    request.get('/plans').then((resp) => {
      this.options = resp.data.plans;
    });
  },
  methods: {
    handleSelect(plan) {
      if (!this.hasFeatureReimbursement) {
        this.$buefy.dialog.confirm({
          title: 'Reimbursement Estimates',
          message: `<p>You haven't activated Reimbursement Estimates. Gain access to 200k+ behavioral reimbursement data points now.</p><br/><p>Activate estimates now for $500/month.</p>`,
          confirmText: 'Activate Feature',
          cancelText: 'Do Not Activate',
          onConfirm: () => {
            this.hasFeatureReimbursement = true;
            this.submit(plan);
          },
          onCancel: () => {
            this.submit(plan);
          },
        });
      } else {
        this.submit(plan);
      }
    },
    submit(plan) {
      this.loading = true;
      request
        .post('/subscriptions/checkout-session', {
          plan_id: plan.id,
          has_feature_reimbursement: this.hasFeatureReimbursement,
        })
        .then((resp) => {
          window.location.href = resp.data.redirect_url;
        });
    },
  },
};
</script>
