<template>
  <div>
    <breadcrumb-list>
      <breadcrumb-link view="Home" label="Home" />
      <breadcrumb-link view="InquiryList" label="Inquiries" :active="true" />
    </breadcrumb-list>
    <section>
      <div class="container is-max-hd">
        <div class="box">
          <div class="level">
            <div class="level-left">
              <div class="level-item">
                <h1 class="title">Inquiry List</h1>
              </div>
            </div>
            <div class="level-right">
              <div class="level-item">
                <b-field>
                  <b-select
                    v-model="selectedSince"
                    placeholder="Last 7 Days"
                    :loading="loading"
                    @input="handleSelect()"
                  >
                    <option value="7">Last 7 Days</option>
                    <option value="30">Last 30 Days</option>
                    <option value="0">All Time</option>
                  </b-select>
                </b-field>
              </div>
              <div class="level-item">
                <b-field>
                  <b-select
                    v-model="selectedUserID"
                    placeholder="Show Inquiries for User"
                    :loading="loading"
                    @input="handleSelect()"
                  >
                    <option :value="0">All Users</option>
                    <option v-for="opt in userOptions" :value="opt.id" :key="opt.id">
                      {{ opt.first_name }} {{ opt.last_name }}
                    </option>
                  </b-select>
                </b-field>
              </div>

              <div class="level-item">
                <b-field>
                  <b-select
                    v-model="selectedProviderID"
                    placeholder="Show Inquiries for Provider"
                    :loading="loading"
                    @input="handleSelect()"
                  >
                    <option :value="0">All Providers</option>
                    <option v-for="opt in providerOptions" :value="opt.id" :key="opt.id">
                      {{ opt.name }}
                    </option>
                  </b-select>
                </b-field>
              </div>

              <div class="level-item">
                <b-button type="is-primary" tag="router-link" :to="{ name: 'InquiryCreate' }">
                  Create New Inquiry
                </b-button>
              </div>
            </div>
          </div>
          <hr />

          <div>
            <b-loading :is-full-page="false" v-model="loading"></b-loading>
            <section class="section has-text-centered" v-if="!loading && !filteredOptions.length">
              <p class="title is-5 block">
                <span v-if="selectedUserID === 0 || selectedUser.id === user.id">You haven't</span>
                <span v-else>{{ selectedUser.first_name }} hasn't</span>
                created any inquiries
                <span v-if="selectedSince === '0'">yet</span>
                <span v-else>in the last {{ selectedSince }} days</span>
                .
              </p>
            </section>
            <InquiryTable
              v-if="!loading && filteredOptions.length"
              :options="filteredOptions"
              @bulkremove="fetchInquiries"
            />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios';

import InquiryTable from '../components/InquiryTable.vue';
import request from '../request';
import { mapGetters } from 'vuex';

export default {
  name: 'InquiryList',
  components: {
    InquiryTable,
  },
  data() {
    return {
      selectedSince: 7,
      selectedUserID: 0,
      selectedProviderID: 0,
      inqOptions: [],
      userOptions: [],
      providerOptions: [],
      loading: true,
    };
  },
  created() {
    axios
      .all([
        request('/users').then((resp) => {
          // Sort users alphabetically by first name.
          resp.data.users.sort((a, b) => a.first_name.localeCompare(b.first_name));
          this.userOptions = resp.data.users;
        }),
        this.fetchInquiries(),
      ])
      .finally(() => (this.loading = false));

    axios
      .all([
        request('/providers').then((resp) => {
          // Sort providers alphabetically by name.
          resp.data.providers.sort((a, b) => a.name.localeCompare(b.name));
          this.providerOptions = resp.data.providers;
        }),
        this.fetchInquiries(),
      ])
      .finally(() => (this.loading = false));
  },
  methods: {
    handleSelect() {
      this.loading = true;
      this.fetchInquiries().finally(() => (this.loading = false));
    },
    fetchInquiries() {
      let args = {};
      if (this.selectedSince != 0) {
        args['since'] = this.selectedSince;
      }
      if (this.selectedUserID != 0) {
        args['user_id'] = this.selectedUserID;
      }
      if (this.selectedProviderID != 0) {
        args['provider_id'] = this.selectedProviderID;
      }
      let params = new URLSearchParams(args).toString();
      return request(`/inquiries?${params}`).then(
        (resp) => (this.inqOptions = resp.data.inquiries)
      );
    },
  },
  computed: {
    ...mapGetters(['isManager', 'user']),
    selectedUser() {
      return this.userOptions.find((opt) => opt.id === this.selectedUserID);
    },
    filteredOptions() {
      return this.inqOptions.filter((opt) => !['error', 'failure'].includes(opt.status));
    },
  },
};
</script>
