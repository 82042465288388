import { render, staticRenderFns } from "./AdminUserUpdate.vue?vue&type=template&id=32cfe27e"
import script from "./AdminUserUpdate.vue?vue&type=script&lang=js"
export * from "./AdminUserUpdate.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports