<template>
  <div>
    <breadcrumb-list>
      <breadcrumb-link view="Home" label="Home" />
      <breadcrumb-link view="InquiryList" label="Inquiries" />
      <breadcrumb-link view="InquiryCreate" label="New" :active="true" />
    </breadcrumb-list>
    <section>
      <div class="container is-max-hd">
        <div class="columns">
          <div class="column is-two-thirds">
            <div class="box" style="position: relative">
              <h1 class="title">New Inquiry</h1>
              <hr />
              <InquiryForm />
            </div>
          </div>
          <div class="column">
            <InquiryFormInstructions />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import InquiryForm from '../components/InquiryForm';
import InquiryFormInstructions from '../components/InquiryFormInstructions';

export default {
  name: 'InquiryCreate',
  components: {
    InquiryForm,
    InquiryFormInstructions,
  },
};
</script>

<style>
.box {
  padding: 2rem;
}
</style>
