<template>
  <form @submit.prevent="handleSubmit" novalidate>
    <div class="block" v-if="form.errors.has('NOFIELD')">
      <b-notification type="is-danger" role="alert">
        {{ form.errors.get('NOFIELD') }}
      </b-notification>
    </div>
    <div class="block">
      <b-field
        label="Email"
        :message="form.errors.get('email')"
        :type="{ 'is-danger': form.errors.has('email') }"
      >
        <b-input type="email" v-model="form.email" placeholder="user@email.com"></b-input>
      </b-field>
    </div>
    <div class="block">
      <b-field
        label="Password"
        :message="form.errors.get('password')"
        :type="{ 'is-danger': form.errors.has('password') }"
      >
        <b-input type="password" v-model="form.password" password-reveal></b-input>
      </b-field>
    </div>
    <div class="block has-text-right">
      <b-button type="is-text is-pulled-left" tag="router-link" :to="{ name: 'PasswordReset' }">
        Can't remember?
      </b-button>

      <b-button :loading="loading" type="is-primary" native-type="submit">Login</b-button>
    </div>
  </form>
</template>

<script>
import Form from '../utils/form';

export default {
  name: 'LoginForm',
  data() {
    return {
      error: '',
      loading: false,
      form: new Form({
        email: '',
        password: '',
      }),
    };
  },
  methods: {
    handleSubmit() {
      this.loading = true;
      this.form.errors.clear();

      this.form
        .post('/auth/login')
        .then(() => {
          let next = this.$route.query.next;
          if (next) {
            window.location.href = next;
          } else {
            window.location.href = '/inquiries';
          }
        })
        .catch(() => {
          if (!this.form.errors.any()) {
            this.form.errors.errors.NOFIELD = 'An unknown error occured. Please try again.';
          }
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
