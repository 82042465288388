<template>
  <div>
    <breadcrumb-list>
      <breadcrumb-link view="Home" label="Home" />
      <breadcrumb-link view="InquiryList" label="Inquiries" />
      <breadcrumb-link
        v-if="inquiry"
        view="InquiryDetail"
        :label="pageTitle"
        :params="{ id: inquiry.id }"
      />
      <breadcrumb-link view="InquiryCopy" label="Copy" :active="true" />
    </breadcrumb-list>

    <section>
      <div class="container is-max-hd">
        <div class="columns">
          <div class="column is-two-thirds">
            <div class="box" style="position: relative">
              <h1 class="title">Copy Inquiry</h1>
              <hr />
              <InquiryForm v-if="inquiry" :inquiry="inquiry" />
            </div>
          </div>
          <div class="column">
            <InquiryFormInstructions />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import request from '../request';
import InquiryForm from '../components/InquiryForm';
import InquiryFormInstructions from '../components/InquiryFormInstructions';

export default {
  name: 'InquiryCreate',
  components: {
    InquiryForm,
    InquiryFormInstructions,
  },
  computed: {
    pageTitle() {
      if (this.inquiry?.coverage?.patient) {
        let firstName = this.inquiry.coverage.patient.first_name;
        let lastName = this.inquiry.coverage.patient.last_name;
        return `${firstName} ${lastName}`;
      }
      return this.inquiry.id;
    },
  },
  data() {
    return {
      inquiry: null,
    };
  },
  beforeRouteEnter(to, from, next) {
    request
      .get(`/inquiries/${to.params.id}`)
      .then((resp) => {
        let status = resp.data.inquiry.status;
        if (status === 'complete' || status === 'expired' || status == 'max-retry') {
          next((vm) => (vm.inquiry = resp.data.inquiry));
        } else {
          next({ name: 'NotFound' });
        }
      })
      .catch(() => {
        next({ name: 'NotFound' });
      });
  },
};
</script>

<style>
.box {
  padding: 2rem;
}
</style>
