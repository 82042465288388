<template>
  <AdminLayout>
    <template #breadcrumb>
      <breadcrumb-link view="AdminReimbursementList" label="Reimbursements" :active="true" />
    </template>

    <div class="box" style="position: relative">
      <b-loading :is-full-page="false" v-model="loading"></b-loading>
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <h1 class="title">Manage Reimbursements</h1>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <b-button
              type="is-primary is-outlined"
              tag="router-link"
              icon-left="cloud-upload"
              :to="{ name: 'AdminReimbursementUpload' }"
            >
              Upload Reimbursements
            </b-button>
          </div>
        </div>
      </div>
      <hr />

      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <b-field grouped label="Filter by Payer">
              <b-select v-model="selectedPayer" :loading="loading" :disabled="!payerOptions.length">
                <option value="">Show for all payers</option>
                <option v-for="option in payerOptions" :value="option.id" :key="option.id">
                  {{ option.name }}
                </option>
              </b-select>
            </b-field>
          </div>
        </div>
      </div>
      <b-table
        v-if="options"
        :data="filteredOptions"
        sort-icon="chevron-up"
        sort-icon-size="is-small"
        :paginated="filteredOptions.length > 50"
        per-page="50"
        hoverable
        striped
      >
        <b-table-column label="Payer" v-slot="props">
          {{ payerName(props.row.payer_id) }}
        </b-table-column>
        <b-table-column label="Employer Name" field="employer_name" searchable v-slot="props">
          {{ props.row.employer_name }}
        </b-table-column>
        <b-table-column label="Group Number" field="group_number" searchable v-slot="props">
          {{ props.row.group_number }}
        </b-table-column>
        <b-table-column label="Alpha Prefix" field="alpha_prefix" searchable v-slot="props">
          {{ props.row.alpha_prefix }}
        </b-table-column>

        <b-table-column field="dtx" label="DTX" sortable v-slot="props">
          {{ props.row.dtx }}
        </b-table-column>
        <b-table-column field="rtc" label="RTC" sortable v-slot="props">
          {{ props.row.rtc }}
        </b-table-column>
        <b-table-column field="php" label="PHP" sortable v-slot="props">
          {{ props.row.php }}
        </b-table-column>
        <b-table-column field="iop" label="IOP" sortable v-slot="props">
          {{ props.row.iop }}
        </b-table-column>
        <b-table-column field="op" label="OP" sortable v-slot="props">
          {{ props.row.op }}
        </b-table-column>
      </b-table>
    </div>
  </AdminLayout>
</template>

<script>
import request from '../../request';
import AdminLayout from '../../layouts/AdminLayout.vue';

export default {
  name: 'AdminReimbursementList',
  components: {
    AdminLayout,
  },
  data() {
    return {
      loading: true,
      options: [],
      payerOptions: [],
      selectedPayer: '',
    };
  },
  created() {
    this.loading = true;
    request
      .get('/admin/reimbursements')
      .then((resp) => (this.options = resp.data.reimbursements))
      .finally(() => (this.loading = false));
    request
      .get('/admin/payers')
      .then(
        (resp) =>
          (this.payerOptions = resp.data.payers.filter((opt) => {
            return opt.payer_group_id === null && opt.is_available;
          }))
      )
      .finally(() => (this.loading = false));
  },
  methods: {
    payerName(id) {
      let payer = this.payerOptions.find((opt) => opt.id == id);
      return payer ? payer.name : '';
    },
  },
  computed: {
    filteredOptions() {
      let opts = this.options;
      if (this.selectedPayer) {
        opts = opts.filter((opt) => opt.payer_id === this.selectedPayer);
      }
      return opts;
    },
  },
};
</script>
