<template>
  <section class="section">
    <div class="container is-max-desktop">
      <div class="columns">
        <div class="column is-three-fifths is-offset-one-fifth">
          <b-notification type="is-danger" role="alert" v-if="error">
            {{ error }}
          </b-notification>

          <div class="box">
            <div class="block">
              <div class="has-text-centered">
                <h1 class="subtitle is-3 has-text-primary">{{ title }}</h1>
              </div>
            </div>

            <slot name="description"></slot>

            <form @submit.prevent="$emit('submit')" novalidate>
              <slot name="fields"></slot>

              <div class="has-text-right">
                <b-button
                  type="is-primary"
                  native-type="submit"
                  :loading="loading"
                  :disabled="disabled"
                >
                  {{ title }}
                </b-button>
              </div>
            </form>
          </div>
          <div class="has-text-centered">
            <b-button type="is-primary is-outlined" tag="router-link" :to="{ name: cancel }">
              Nevermind, take me back.
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FormBase',
  props: ['title', 'error', 'cancel', 'disabled', 'loading'],
};
</script>
