<template>
  <div class="box" style="position: relative">
    <b-loading :is-full-page="false" v-model="loading"></b-loading>
    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <h1 class="title">Providers</h1>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <b-button type="is-primary is-light" tag="router-link" :to="{ name: 'ProviderCreate' }">
            Create New Provider
          </b-button>
        </div>
      </div>
    </div>
    <hr />
    <div>
      <b-table
        :data="providers"
        sort-icon="chevron-up"
        sort-icon-size="is-small"
        :default-sort="['name', 'asc']"
        striped
        :paginated="providers.length > 10"
        per-page="10"
        hoverable
      >
        <b-table-column field="name" label="Name" v-slot="props">
          {{ props.row.name }}
        </b-table-column>

        <b-table-column field="npi" label="NPI" v-slot="props">
          {{ props.row.npi }}
        </b-table-column>

        <b-table-column field="tax_id" label="Tax ID" v-slot="props">
          {{ props.row.tax_id }}
        </b-table-column>

        <b-table-column field="is_active" label="Active" v-slot="props">
          <b-icon
            :type="props.row.is_active ? 'is-success' : 'is-danger'"
            :icon="props.row.is_active ? 'check-circle' : 'close-circle'"
            size="is-small"
          />
        </b-table-column>

        <b-table-column v-slot="props" numeric>
          <router-link
            size="is-small"
            :to="{ name: 'ProviderUpdate', params: { id: props.row.id } }"
          >
            <b-icon icon="square-edit-outline" />
          </router-link>
        </b-table-column>
      </b-table>
    </div>
  </div>
</template>

<script>
import request from '../request';

export default {
  name: 'ProviderList',
  data() {
    return {
      loading: true,
      providers: [],
    };
  },
  mounted() {
    this.getProviders();
  },
  methods: {
    getProviders() {
      this.loading = true;
      request
        .get('/providers')
        .then((resp) => (this.providers = resp.data.providers))
        .finally(() => (this.loading = false));
    },
  },
};
</script>
