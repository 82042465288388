import axios from 'axios';
import store from './store';

let base = axios.create({
  baseURL: '/api/v1',
});

base.interceptors.response.use(
  function (resp) {
    return resp;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      // "logout" will also redirect to Login view
      store.dispatch('logout');
    }
    return Promise.reject(error);
  }
);

export default base;
