<template>
  <div>
    <breadcrumb-list>
      <breadcrumb-link view="Home" label="Home" />
      <breadcrumb-link view="AdminIndex" label="Admin" />
      <breadcrumb-link view="AdminReimbursementList" label="Reimbursements" />
      <breadcrumb-link view="AdminReimbursementUpload" label="Upload" :active="true" />
    </breadcrumb-list>
    <section>
      <b-loading v-model="loading" :is-full-page="false"></b-loading>
      <div class="container is-max-hd">
        <div class="box">
          <h1 class="title">Upload Reimbursements</h1>
          <hr />
          <p class="block">
            This form allows you to upload a CSV file of Reimbursements to create and/or update
            Reimbursements in the system.
          </p>
          <div class="level">
            <div class="level-left">
              <div class="level-item">
                <b-field
                  class="file is-primary"
                  :message="errors.get('file')"
                  :type="{ 'is-danger': errors.has('file') }"
                >
                  <b-upload v-model="file" class="file-label">
                    <span class="file-cta">
                      <b-icon class="file-icon" icon="upload"></b-icon>
                      <span class="file-label">Select CSV File</span>
                    </span>
                    <span class="file-name" v-if="file">
                      {{ file.name }}
                    </span>
                  </b-upload>
                </b-field>
              </div>
            </div>
            <div class="level-right">
              <p class="level-item">
                <b-button :loading="loading" @click="handleSubmit()" type="is-primary">
                  Upload
                </b-button>
              </p>
            </div>
          </div>
        </div>
        <div class="has-text-centered">
          <b-button
            type="is-primary is-outlined"
            tag="router-link"
            :to="{ name: 'AdminReimbursementList' }"
          >
            Nevermind, take me back.
          </b-button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import request from '../../request';
import Errors from '../../utils/errors.js';

export default {
  name: 'AdminReimbursementUpload',
  data() {
    return {
      errors: new Errors(),
      file: null,
      loading: false,
    };
  },
  methods: {
    toast(msg, msgType) {
      this.$buefy.toast.open({
        message: msg,
        type: msgType,
        duration: 5000,
      });
    },
    handleSubmit() {
      this.$buefy.dialog.confirm({
        message:
          'NOTE: This will not modify/overwrite existing reimbursements with the same ClaimID. Do you want to continue?',
        onConfirm: () => {
          this.loading = true;
          let formData = new FormData();
          formData.append('file', this.file);
          request({
            url: '/admin/reimbursements/upload',
            data: formData,
            method: 'POST',
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
            .then(() => {
              this.toast('Reimbursements uploaded successfully.', 'is-success');
              this.$router.push({ name: 'AdminReimbursementList' });
            })
            .catch((err) => {
              if (err.response && err.response.status == 422) {
                this.errors.record(err.response.data.errors);
              } else {
                this.toast('Reimbursements failed to upload.', 'is-danger');
              }
            })
            .finally(() => {
              this.loading = false;
            });
        },
      });
    },
  },
};
</script>
