<template>
  <div>
    <breadcrumb-list>
      <breadcrumb-link view="Home" label="Home" />
      <breadcrumb-link view="ProfileDetail" label="Profile" />
      <breadcrumb-link view="PasswordChange" label="Change Password" :active="true" />
    </breadcrumb-list>
    <section>
      <div class="container is-max-desktop">
        <div class="columns">
          <div class="column is-three-fifths is-offset-one-fifth">
            <b-notification type="is-danger" role="alert" v-if="form.errors.has('NOFIELD')">
              {{ form.errors.get('NOFIELD') }}
            </b-notification>

            <div class="box is-clearfix">
              <div class="block">
                <div class="has-text-centered">
                  <h1 class="subtitle is-3 has-text-primary">Change Password</h1>
                </div>
              </div>
              <form @submit.prevent="handleSubmit" novalidate>
                <div class="block">
                  <b-field
                    label="Old Password"
                    :message="form.errors.get('old_password')"
                    :type="{ 'is-danger': form.errors.has('old_password') }"
                  >
                    <b-input type="password" v-model="form.old_password" password-reveal />
                  </b-field>
                </div>
                <div class="block">
                  <b-field
                    label="New Password"
                    :message="form.errors.get('new_password1')"
                    :type="{ 'is-danger': form.errors.has('new_password1') }"
                  >
                    <b-input type="password" v-model="form.new_password1" password-reveal />
                  </b-field>
                </div>
                <div class="block">
                  <b-field
                    label="Confirm Password"
                    :message="form.errors.get('new_password2')"
                    :type="{ 'is-danger': form.errors.has('new_password2') }"
                  >
                    <b-input type="password" v-model="form.new_password2" password-reveal></b-input>
                  </b-field>
                </div>

                <div class="block has-text-right">
                  <b-button :loading="loading" type="is-primary" native-type="submit">
                    Change Password
                  </b-button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Form from '../utils/form';

export default {
  name: 'PasswordChange',
  data() {
    return {
      loading: false,
      error: '',
      form: new Form({
        old_password: '',
        new_password1: '',
        new_password2: '',
      }),
    };
  },
  methods: {
    handleSubmit() {
      this.loading = true;
      this.form.errors.clear();
      this.form
        .post('/auth/password/change')
        .then(() => {
          let msg = 'Your password has been updated.';
          this.$buefy.toast.open({ message: msg, type: 'is-success' });
          this.$router.push('/');
        })
        .catch(() => {
          if (!this.form.errors.any()) {
            this.form.errors.setGenericError();
          }
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
