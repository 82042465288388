import Vue from 'vue';
import VueRouter from 'vue-router';
import store from './store.js';

// No auth views
import Login from './views/Login.vue';
import Register from './views/Register.vue';
import RegisterInvite from './views/RegisterInvite.vue';
import PasswordReset from './views/PasswordReset.vue';
import PasswordResetConfirm from './views/PasswordResetConfirm.vue';

// Auth views
import InquiryCreate from './views/InquiryCreate.vue';
import InquiryCopy from './views/InquiryCopy.vue';
import InquiryDetail from './views/InquiryDetail.vue';
import InquiryList from './views/InquiryList.vue';
import ProviderCreate from './views/ProviderCreate.vue';
import ProviderUpdate from './views/ProviderUpdate.vue';
import OrganizationDetail from './views/OrganizationDetail.vue';
import PlanList from './views/PlanList.vue';
import UserInvite from './views/UserInvite.vue';
import UserUpdate from './views/UserUpdate.vue';
import ProfileDetail from './views/ProfileDetail.vue';
import PasswordChange from './views/PasswordChange.vue';

// Admin views
import AdminOrganizationList from './views/admin/AdminOrganizationList.vue';
import AdminOrganizationDetail from './views/admin/AdminOrganizationDetail.vue';
import AdminInquiryList from './views/admin/AdminInquiryList.vue';
import AdminInviteList from './views/admin/AdminInviteList.vue';
import AdminPayerDetail from './views/admin/AdminPayerDetail.vue';
import AdminPayerCreate from './views/admin/AdminPayerCreate.vue';
import AdminPayerList from './views/admin/AdminPayerList.vue';
import AdminPayerUpload from './views/admin/AdminPayerUpload.vue';
import AdminPayersPrefixesUpload from './views/admin/AdminPayersPrefixesUpload.vue';
import AdminPayerUpdate from './views/admin/AdminPayerUpdate.vue';
import AdminProviderList from './views/admin/AdminProviderList.vue';
import AdminLAPrefixList from './views/admin/AdminLAPrefixList.vue';
import AdminLAPrefixUpload from './views/admin/AdminLAPrefixUpload.vue';
import AdminReimbursementList from './views/admin/AdminReimbursementList.vue';
import AdminReimbursementUpload from './views/admin/AdminReimbursementUpload.vue';
import AdminUserList from './views/admin/AdminUserList.vue';
import AdminUserInvite from './views/admin/AdminUserInvite.vue';
import AdminUserUpdate from './views/admin/AdminUserUpdate.vue';
import AdminRegisterPopulate from './views/admin/AdminRegisterPopulate.vue';

// Misc views
import NotFound from './views/NotFound.vue';
import TermsAndConditions from './views/TermsAndConditions.vue';
import TermsAndConditionsAccept from './views/TermsAndConditionsAccept.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: { name: 'InquiryList' },
    meta: { requiresAuth: true },
  },
  {
    path: '/inquiries/new',
    name: 'InquiryCreate',
    component: InquiryCreate,
    meta: { requiresAuth: true },
  },
  {
    path: '/inquiries',
    name: 'InquiryList',
    component: InquiryList,
    meta: { requiresAuth: true },
  },
  {
    path: '/inquiries/:id([A-Z0-9]{26})',
    name: 'InquiryDetail',
    component: InquiryDetail,
    meta: { requiresAuth: true },
  },
  {
    path: '/inquiries/:id([A-Z0-9]{26})/copy',
    name: 'InquiryCopy',
    component: InquiryCopy,
    meta: { requiresAuth: true },
  },

  //
  // Organization
  //

  {
    path: '/organization/settings/plan',
    name: 'PlanList',
    component: PlanList,
    meta: { requiresAuth: true, requiresManager: true },
  },
  {
    path: '/organization/settings',
    name: 'OrganizationDetail',
    component: OrganizationDetail,
    meta: { requiresAuth: true, requiresManager: true },
  },
  {
    path: '/organization/users/:id/update',
    name: 'UserUpdate',
    component: UserUpdate,
    meta: { requiresAuth: true, requiresManager: true },
  },
  {
    path: '/organization/users/new',
    name: 'UserInvite',
    component: UserInvite,
    meta: { requiresAuth: true, requiresManager: true },
  },
  {
    path: '/organization/providers/new',
    name: 'ProviderCreate',
    component: ProviderCreate,
    meta: { requiresAuth: true, requiresManager: true },
  },
  {
    path: '/organization/providers/:id/update',
    name: 'ProviderUpdate',
    component: ProviderUpdate,
    meta: { requiresAuth: true, requiresManager: true },
  },

  //
  // Admin
  //

  {
    path: '/admin',
    name: 'AdminIndex',
    redirect: { name: 'AdminOrganizationList' },
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  {
    path: '/admin/inquiries',
    name: 'AdminInquiryList',
    component: AdminInquiryList,
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  {
    path: '/admin/users',
    name: 'AdminUserList',
    component: AdminUserList,
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  {
    path: '/admin/invites',
    name: 'AdminInviteList',
    component: AdminInviteList,
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  {
    path: '/admin/payers',
    name: 'AdminPayerList',
    component: AdminPayerList,
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  {
    path: '/admin/payers/:id',
    name: 'AdminPayerDetail',
    component: AdminPayerDetail,
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  {
    path: '/admin/payers/:id/update',
    name: 'AdminPayerUpdate',
    component: AdminPayerUpdate,
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  {
    path: '/admin/payers/upload',
    name: 'AdminPayerUpload',
    component: AdminPayerUpload,
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  {
    path: '/admin/payers/new',
    name: 'AdminPayerCreate',
    component: AdminPayerCreate,
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  {
    path: '/admin/payers/prefixes/upload',
    name: 'AdminPayersPrefixesUpload',
    component: AdminPayersPrefixesUpload,
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  {
    path: '/admin/providers',
    name: 'AdminProviderList',
    component: AdminProviderList,
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  {
    path: '/admin/organizations',
    name: 'AdminOrganizationList',
    component: AdminOrganizationList,
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  {
    path: '/admin/organizations/:id',
    name: 'AdminOrganizationDetail',
    component: AdminOrganizationDetail,
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  {
    path: '/admin/organizations/:id/invite',
    name: 'AdminUserInvite',
    component: AdminUserInvite,
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  {
    path: '/admin/register/populate',
    name: 'RegistrationPopulate',
    component: AdminRegisterPopulate,
    meta: { requiresAuth: true, requiresAdmin: true },
  },

  // Admin Reimbursements
  {
    path: '/admin/la-prefixes',
    name: 'AdminLAPrefixList',
    component: AdminLAPrefixList,
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  {
    path: '/admin/la-prefixes/upload',
    name: 'AdminLAPrefixUpload',
    component: AdminLAPrefixUpload,
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  {
    path: '/admin/reimbursements',
    name: 'AdminReimbursementList',
    component: AdminReimbursementList,
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  {
    path: '/admin/reimbursements/upload',
    name: 'AdminReimbursementUpload',
    component: AdminReimbursementUpload,
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  {
    path: '/admin/users/:id/update',
    name: 'AdminUserUpdate',
    component: AdminUserUpdate,
    meta: { requiresAuth: true, requiresAdmin: true },
  },

  //
  // Organization
  //

  {
    path: '/settings',
    name: 'ProfileDetail',
    component: ProfileDetail,
    meta: { requiresAuth: true },
  },

  {
    path: '/settings/password-change',
    name: 'PasswordChange',
    component: PasswordChange,
    meta: { requiresAuth: true },
  },

  //
  // Auth
  //

  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
  },
  {
    path: '/register/:id([A-Z0-9]{26})',
    name: 'RegisterInvite',
    component: RegisterInvite,
  },
  {
    path: '/password-reset',
    name: 'PasswordReset',
    component: PasswordReset,
  },
  {
    // The url is also in password_reset_email.html.
    path: '/password-reset/:uid/:token',
    name: 'PasswordResetConfirm',
    component: PasswordResetConfirm,
  },

  //
  // Global
  //
  {
    path: '/terms',
    name: 'TermsAndConditions',
    component: TermsAndConditions,
  },
  {
    path: '/terms/accept',
    name: 'TermsAndConditionsAccept',
    component: TermsAndConditionsAccept,
    meta: { requiresAuth: true },
  },

  { path: '/404', name: 'NotFound', component: NotFound },
  { path: '/:pathMatch(.*)*', redirect: { name: 'NotFound' } },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// This route is needed to select a subscription plan. We can redirect
// here if the user has no other plan.
const subscriptionRoute = 'PlanList';

const tosAcceptRoute = 'TermsAndConditionsAccept';

router.beforeEach(async (to, from, next) => {
  // This has the overhead of hitting refresh on every endpoint.
  // This works for page load and slides the session window.
  // We don't just check `isLoggedIn` from the store because we want
  // to immediately checks since our auth lifespan is so short. If we
  // just checked the store, the user would see the page then immediately
  // get an API 401 and be logged out anyway.
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    await store
      .dispatch('refresh')
      .then(() => {
        // Check Terms of service.
        if (
          !store.getters.hasCurrentTOS &&
          to.name !== tosAcceptRoute &&
          store.getters.hasActiveSubscription
        ) {
          // The active subscription check is required so we don't get infinite loop with subscription check.
          return next({ name: tosAcceptRoute, query: { next: to.path } });
        }

        // Admin just shows notfound instead of redirecting to login
        if (to.matched.some((record) => record.meta.requiresAdmin)) {
          if (!store.getters.isAdmin) {
            return next({ name: 'NotFound' });
          }
        }

        // Admin just shows notfound instead of redirecting to login
        if (to.matched.some((record) => record.meta.requiresManager)) {
          if (!store.getters.isManager) {
            return next({ name: 'NotFound' });
          }
        }

        // Subscription
        // Do we need to even check public routes? What if they are browsing?
        if (store.getters.isLoggedIn && !store.getters.hasActiveSubscription) {
          if (to.name !== subscriptionRoute) {
            return next({ name: 'PlanList' });
          }
        }
      })
      .catch(() => {
        // Pass original url to login url as a param so we can redirect after login.
        return next({ name: 'Login', query: { next: to.path } });
      });
  }

  next();
});

export default router;
