<template>
  <AdminLayout>
    <template #breadcrumb>
      <breadcrumb-link view="AdminPayerList" label="Payers" />
      <breadcrumb-link view="AdminPayerDetail" :label="payer.name" v-if="payer" :active="true" />
    </template>
    <section style="position: relative">
      <div class="box">
        <b-loading :is-full-page="false" v-model="loading"></b-loading>
        <div v-if="payer">
          <b-button
            type="is-primary"
            tag="router-link"
            :to="{ name: 'AdminPayerUpdate', params: { id: payer.id } }"
          >
            Edit Payer
          </b-button>
          <hr />
          <h1 class="title">
            {{ payer.name }}
            <small v-if="payer.name !== payer.name_internal">
              <br />
              [{{ payer.name_internal }}]
            </small>
          </h1>
          <small>Is Group: {{ payer.is_group }}</small>
          <hr />
          <h1>Payer Information</h1>
          <pre v-text="payer" />
          <hr />
          <h1>Schema</h1>
          <pre v-if="schema" v-text="schema" />
        </div>
      </div>
    </section>
  </AdminLayout>
</template>

<script>
import request from '../../request';
import AdminLayout from '../../layouts/AdminLayout.vue';

export default {
  name: 'AdminPayerDetail',
  components: {
    AdminLayout,
  },
  data() {
    return {
      payer: null,
      schema: null,
      loading: true,
    };
  },
  created() {
    request
      .get(`/admin/payers/${this.$route.params.id}`)
      .then((resp) => {
        this.payer = resp.data.payer;
        this.schema = resp.data.schema;
      })
      .finally(() => (this.loading = false));
  },
};
</script>
