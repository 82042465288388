<template>
  <b-field :label="label" :message="errors" :type="{ 'is-danger': errors ? errors.length : false }">
    <b-autocomplete
      v-model="name"
      :data="filteredOpts"
      :field="field"
      open-on-focus
      clearable
      :disabled="disabled"
      :required="false"
      :loading="options.length < 1"
      :placeholder="placeholder"
      @select="
        (opt) => {
          $emit('input', opt ? opt.id : null);
        }
      "
    />
  </b-field>
</template>

<script>
export default {
  props: {
    label: String,
    placeholder: String,
    errors: String,
    field: {
      type: String,
      default: 'name',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    options: Array,
    value: Number,
    autoSelect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      name: '',
    };
  },
  watch: {
    // Autoselect single option
    options(val) {
      if (this.autoSelect && val.length === 1) {
        this.name = val[0][this.field];
        this.$emit('input', val[0].id);
      }
    },
    value(val) {
      // Clear field when formData is deleted (value)
      if (val === undefined) {
        this.name = '';
      } else {
        // Select initial value
        let opt = this.options.find((opt) => opt.id === this.value);
        if (opt) {
          this.name = opt[this.field];
        }
      }
    },
  },
  computed: {
    filteredOpts() {
      return this.options.filter((option) => {
        return option[this.field].toString().toLowerCase().indexOf(this.name.toLowerCase()) >= 0;
      });
    },
  },
};
</script>
