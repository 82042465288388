<template>
  <div>
    <breadcrumb-list>
      <breadcrumb-link view="Home" label="Home" />
      <breadcrumb-link view="AdminIndex" label="Admin" />
      <breadcrumb-link view="AdminPayerList" label="Payers" />
      <breadcrumb-link view="AdminPayerUpdate" label="Update" :active="true" />
      <breadcrumb-link
        view="AdminPayerUpdate"
        :params="{ id: payer.id }"
        :label="`Update ${payer.name}`"
        :active="true"
        v-if="!loading"
      />
    </breadcrumb-list>

    <form-base
      title="Update Payer"
      cancel="AdminPayerList"
      :error="form.errors.get('NOFIELD')"
      @submit="handleSubmit"
    >
      <template v-slot:fields>
        <div class="block">
          <form-input :form="form" label="Payer Name" field-name="name" />
        </div>

        <div class="block">
          <form-input
            :form="form"
            :disabled="true"
            label="Payer Internal Name"
            field-name="name_internal"
          />
        </div>

        <div class="block">
          <form-input :form="form" label="Payer Code" field-name="code" />
        </div>

        <div class="block">
          <form-switch :form="form" label="Is Group" field-name="is_group" />
        </div>

        <div class="block">
          <form-switch :form="form" label="Is Active" field-name="is_active" />
        </div>
      </template>
    </form-base>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Form from '../../utils/form';
import FormBase from '../../components/FormBase.vue';
import FormInput from '../../components/FormInput.vue';
import FormSwitch from '../../components/FormSwitch.vue';
import request from '../../request';

export default {
  name: 'PayerUpdate',
  components: { FormBase, FormInput, FormSwitch },
  data() {
    return {
      loading: true,
      payer: null,
      form: new Form({
        name: '',
        name_internal: '',
        code: '',
        is_group: false,
        is_active: false,
      }),
    };
  },
  computed: {
    ...mapGetters(['organization']),
  },
  beforeRouteEnter(to, from, next) {
    request
      .get(`/admin/payers/${to.params.id}`)
      .then((resp) => next((vm) => vm.setData(resp.data.payer)))
      .catch(() => next({ name: 'NotFound' }));
  },
  methods: {
    setData(payer) {
      this.payer = payer;
      this.form = new Form({
        name: payer.name,
        name_internal: payer.name_internal,
        code: payer.code,
        is_group: payer.is_group,
        is_active: payer.is_active,
      });
      this.loading = false;
    },
    handleSubmit() {
      this.loading = true;
      this.form
        .put(`/admin/payers/${this.$route.params.id}`)
        .then(() => {
          let msg = `${this.form.name} was updated successfully.`;
          this.$buefy.toast.open({ message: msg, type: 'is-success' });
          this.$router.push({ name: 'AdminPayerList' });
        })
        .catch(() => {})
        .finally(() => (this.loading = false));
    },
  },
};
</script>
