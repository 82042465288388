<template>
  <div>
    <breadcrumb-list>
      <breadcrumb-link view="Home" label="Home" />
      <breadcrumb-link view="ProfileDetail" label="Profile" :active="true" />
    </breadcrumb-list>
    <section>
      <div class="container is-max-desktop">
        <div class="columns">
          <div class="column is-three-fifths is-offset-one-fifth">
            <b-notification type="is-danger" role="alert" v-if="form.errors.has('NOFIELD')">
              {{ form.errors.get('NOFIELD') }}
            </b-notification>

            <div class="box is-clearfix">
              <div class="block">
                <div class="has-text-centered">
                  <h1 class="subtitle is-3 has-text-primary">Update Profile</h1>
                </div>
              </div>
              <form @submit.prevent="onSubmit" novalidate>
                <div class="block">
                  <b-field
                    label="First Name"
                    :message="form.errors.get('first_name')"
                    :type="{ 'is-danger': form.errors.has('first_name') }"
                  >
                    <b-input type="text" v-model="form.first_name" />
                  </b-field>
                </div>

                <div class="block">
                  <b-field
                    label="Last Name"
                    :message="form.errors.get('last_name')"
                    :type="{ 'is-danger': form.errors.has('last_name') }"
                  >
                    <b-input type="text" v-model="form.last_name" />
                  </b-field>
                </div>

                <div class="block">
                  <b-field
                    label="Email"
                    :message="form.errors.get('email')"
                    :type="{ 'is-danger': form.errors.has('email') }"
                  >
                    <b-input type="email" v-model="form.email" />
                  </b-field>
                </div>

                <div class="block">
                  <PhoneField v-model="form.phone" :errors="form.errors" />
                </div>

                <div class="block">
                  <b-field label="Password">
                    <b-input type="password" value="xxxxxxxxxxxxxxxxx" :disabled="true" />
                  </b-field>
                  <router-link :to="{ name: 'PasswordChange' }">Change password</router-link>
                </div>

                <div class="block has-text-right">
                  <b-button :loading="loading" type="is-primary" native-type="submit">
                    Save Changes
                  </b-button>
                </div>
              </form>
            </div>

            <div class="has-text-centered">
              <b-button type="is-primary is-outlined" tag="router-link" :to="{ name: 'Home' }">
                Nevermind, take me back.
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Form from '../utils/form';
import request from '../request';
import { mapGetters } from 'vuex';

import PhoneField from '../components/fields/PhoneField.vue';

export default {
  name: 'ProfileDetail',
  components: {
    PhoneField,
  },
  data() {
    return {
      loading: true,
      error: '',
      form: new Form({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
      }),
    };
  },
  computed: {
    ...mapGetters(['user']),
  },
  mounted() {
    this.getUser();
    this.loading = false;
  },
  methods: {
    getUser() {
      request.get(`/users/${this.user.id}`).then((resp) => {
        const user = resp.data.user;
        this.form.first_name = user.first_name;
        this.form.last_name = user.last_name;
        this.form.email = user.email;
        this.form.phone = user.phone;
      });
    },
    onSubmit() {
      this.loading = true;
      this.form.errors.clear();
      this.form
        .put(`/users/${this.user.id}`)
        .then(() => {
          let msg = 'Your information has been updated.';
          this.$buefy.toast.open({ message: msg, type: 'is-success' });
          this.$store.dispatch('refresh');
          this.$router.push({ name: 'Home' });
        })
        .catch(() => {
          if (!this.form.errors.any()) {
            this.form.errors.setGenericError();
          }
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
