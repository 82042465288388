<template>
  <AdminLayout>
    <template #breadcrumb>
      <breadcrumb-link view="AdminPayerList" label="Payers" :active="true" />
    </template>

    <div class="box" style="position: relative">
      <b-loading :is-full-page="false" v-model="loading"></b-loading>
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <h1 class="title">Manage Payers</h1>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <b-button
              type="is-primary is-outlined"
              tag="router-link"
              icon-left="account-group"
              :to="{ name: 'AdminPayerCreate' }"
            >
              Create Payer
            </b-button>
          </div>
          <div class="level-item">
            <b-button
              type="is-primary is-outlined"
              tag="router-link"
              icon-left="cloud-upload"
              :to="{ name: 'AdminPayerUpload' }"
            >
              Upload Payers
            </b-button>
          </div>
          <div class="level-item">
            <b-button type="is-primary is-outlined" icon-left="sync" @click="handleSyncAll">
              Sync All Payers
            </b-button>
          </div>
        </div>
      </div>
      <hr />

      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <b-field grouped label="Payer Group" v-if="payerGroupOptions">
              <b-select
                v-model="payerGroupSelected"
                placeholder="Change group for selected"
                :loading="loading"
                :disabled="!checkedRows.length || !payerGroupOptions.length"
                @input="handlePayerGroupBulkAdd($event)"
              >
                <option v-for="option in payerGroupOptions" :value="option.id" :key="option.id">
                  {{ option.name }}
                </option>
              </b-select>
            </b-field>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <b-switch v-model="showUnusable" type="is-primary is-light">
              <span>Show Unusable Payers</span>
            </b-switch>
          </div>
        </div>
      </div>
      <b-table
        v-if="payerOptions"
        :data="filteredPayerOptions"
        sort-icon="chevron-up"
        sort-icon-size="is-small"
        :paginated="payerOptions.length > 50"
        per-page="50"
        hoverable
        striped
        :checked-rows.sync="checkedRows"
        checkable
      >
        <b-table-column field="id" label="ID" sortable searchable v-slot="props">
          {{ props.row.id }}
        </b-table-column>

        <b-table-column field="name" label="Name" sortable searchable v-slot="props">
          <router-link :to="{ name: 'AdminPayerDetail', params: { id: props.row.id } }">
            {{ props.row.name }}
            <small v-if="props.row.name !== props.row.name_internal">
              <br />
              [{{ props.row.name_internal }}]
            </small>
          </router-link>
        </b-table-column>

        <b-table-column field="code" label="Code" sortable searchable v-slot="props">
          {{ props.row.code }}
        </b-table-column>

        <b-table-column field="payer_group.id" sortable label="Group" v-slot="props">
          <span v-if="props.row.is_group">
            <b-icon type="is-success" icon="check-circle" size="is-small" />
          </span>
          <b-tag
            v-else-if="props.row.payer_group"
            type="is-info"
            closable
            aria-close-label="Remove group"
            @close="handlePayerGroupRemove(props.row.id)"
          >
            {{ props.row.payer_group.name }}
          </b-tag>
        </b-table-column>

        <b-table-column field="is_blue" label="Blue" sortable v-slot="props">
          <b-switch
            type="is-info"
            @input="handleIsBlueSwitch($event, props.row.id)"
            :value="props.row.is_blue"
            :disabled="indexClicked === props.row.id"
          />
        </b-table-column>
        <b-table-column field="is_active" label="Active" sortable v-slot="props">
          <b-switch
            type="is-success"
            @input="handleIsActiveSwitch($event, props.row.id)"
            :value="props.row.is_active && props.row.is_available"
            :disabled="indexClicked === props.row.id || !props.row.is_available"
          />
        </b-table-column>
        <b-table-column label="Sync" v-slot="props">
          <b-button
            @click="handleSync(props.row.id)"
            type="is-small"
            icon-left="sync"
            :loading="indexSynced.includes(props.row.id)"
          />
        </b-table-column>
        <b-table-column field="created_at" label="Created At" sortable v-slot="props">
          {{ props.row.created_at | formatDate }}
        </b-table-column>
        <b-table-column v-if="showUnusable" field="is_available" label="!" sortable v-slot="props">
          <b-tooltip
            v-if="!props.row.is_available"
            label="Bad response from Availity"
            position="is-left"
            type="is-danger"
          >
            <b-icon type="is-danger" icon="alert-box" />
          </b-tooltip>
        </b-table-column>
      </b-table>
    </div>
  </AdminLayout>
</template>

<script>
import axios from 'axios';
import request from '../../request';
import AdminLayout from '../../layouts/AdminLayout.vue';

export default {
  name: 'AdminPayerList',
  components: {
    AdminLayout,
  },
  data() {
    return {
      loading: true,
      payerOptions: [],
      payerGroupOptions: [],
      checkedRows: [],
      showUnusable: false,

      indexSynced: [],
      indexClicked: null,
      payerGroupSelected: null,
    };
  },
  computed: {
    filteredPayerOptions() {
      if (this.showUnusable) {
        return this.payerOptions;
      }
      return this.payerOptions.filter((opt) => opt.is_available);
    },
  },
  created() {
    this.loading = true;
    request
      .get('/admin/payers')
      .then((resp) => {
        this.payerOptions = resp.data.payers;
        this.payerGroupOptions = resp.data.payers.filter((payer) => payer.is_group);
      })
      .finally(() => (this.loading = false));
  },
  methods: {
    handleSync(id) {
      this.indexSynced.push(id);
      request
        .post(`/admin/payers/${id}/schema`)
        .then(() => {
          let msg = 'Synced with Availity!';
          this.$buefy.toast.open({ message: msg, type: 'is-success' });
        })
        .catch((error) => {
          if (error.response.status == 502) {
            let msg = "Availity doesn't have data on this payer.";
            this.$buefy.toast.open({ message: msg, type: 'is-danger' });
          } else {
            let msg = 'There was an unknown error.';
            this.$buefy.toast.open({ message: msg, type: 'is-danger' });
          }
        })
        .finally(() => {
          this.indexSynced = this.indexSynced.filter(function (item) {
            return item !== id;
          });
        });
    },
    handleSyncAll() {
      this.loading = true;
      request
        .post(`/admin/payers/schemas`)
        .then(() => {
          request.get('/admin/payers').then((resp) => {
            this.payerOptions = resp.data.payers;
            this.payerGroupOptions = resp.data.payers.filter((p) => p.is_group);
          });

          let msg = 'Synced with Availity!';
          this.$buefy.toast.open({ message: msg, type: 'is-success' });
        })
        .catch(() => {
          let msg = 'There was an unknown error.';
          this.$buefy.toast.open({ message: msg, type: 'is-danger' });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleIsActiveSwitch(isActive, id) {
      this.indexClicked = id;
      request
        .put(`/admin/payers/${id}`, { is_active: isActive })
        .finally(() => (this.indexClicked = null));
    },
    handleIsBlueSwitch(isBlue, id) {
      this.indexClicked = id;
      request
        .put(`/admin/payers/${id}`, { is_blue: isBlue })
        .finally(() => (this.indexClicked = null));
    },
    handlePayerGroupBulkAdd(payerGroupId) {
      let payerGroup = this.payerOptions.find((opt) => opt.id === payerGroupId);

      let message = `Are you sure you want to add ${this.checkedRows.length} payers to "${payerGroup.name}"?`;
      let is = payerGroup.is_blue ? 'IS' : 'IS NOT';
      message += `<br/><br/><strong class="has-text-danger">NOTE: "${payerGroup.name}" ${is} a BLUE. All these payers will be set to the same.</strong>`;

      this.$buefy.dialog.confirm({
        message: message,
        confirmText: `Add to ${payerGroup.name}`,
        type: 'is-success',
        onConfirm: () => {
          this.loading = true;
          let ids = this.checkedRows.map((r) => r.id);
          request
            .put(`/admin/payers/${payerGroupId}/bulk`, { payer_ids: ids })
            .then(() => {
              request.get('/admin/payers').then((resp) => {
                this.payerOptions = resp.data.payers;
                this.payerGroupOptions = resp.data.payers.filter((p) => p.is_group);
              });
            })
            .finally(() => {
              this.checkedRows = [];
              this.payerGroupSelected = null;
              this.loading = false;
            });
        },
      });
    },
    handlePayerGroupRemove(payerId) {
      let payer = this.payerOptions.find((opt) => opt.id === payerId);
      this.$buefy.dialog.confirm({
        message: `Are you sure you want to remove "${payer.name}" from the group "${payer.payer_group.name}"?`,
        confirmText: 'Remove From Group',
        type: 'is-danger',
        onConfirm: () => {
          this.loading = true;
          request
            .put(`/admin/payers/${payerId}`, { payer_group_id: 0 })
            .then(() => {
              axios.all([
                request.post(`/admin/payers/${payer.payer_group.id}/schema`),
                request.get('/admin/payers').then((resp) => {
                  this.payerOptions = resp.data.payers;
                  this.payerGroupOptions = resp.data.payers.filter((p) => p.is_group);
                }),
              ]);
            })
            .finally(() => (this.loading = false));
        },
      });
    },
  },
};
</script>
