<template>
  <InviteList :isForAdmin="true" :organization="organization" />
</template>

<script>
import InviteList from '../../components/InviteList';

export default {
  name: 'AdminOrganizationInviteList',
  components: {
    InviteList,
  },
  props: {
    organization: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
