<template>
  <span v-if="primaryCareProvider">
    <br />
    <hr />
    <!-- show name, address, role -->
    <strong>Primary Care Provider</strong>
    : {{ primaryCareProvider.firstName }}
    {{ primaryCareProvider.middleName }}
    {{ primaryCareProvider.lastName }}
    <br />
    <p v-if="primaryCareProvider?.address">
      <strong>Address:</strong>
      {{ primaryCareProvider.address.line1 }}
      <br />
      {{ primaryCareProvider.address.city }}, {{ primaryCareProvider.address.stateCode }},
      {{ primaryCareProvider.address.zipCode }}
    </p>
    <br />
    <template v-if="primaryCareProvider?.role">
      <strong>Role</strong>
      : {{ primaryCareProvider.role }}
    </template>
  </span>
</template>

<script>
export default {
  name: 'PrimaryCareProvider',
  props: {
    primaryCareProvider: Object,
  },
};
</script>
