<template>
  <AdminLayout>
    <template #breadcrumb>
      <breadcrumb-link view="AdminUserList" label="Users" :active="true" />
    </template>

    <div class="box" style="position: relative">
      <b-loading :is-full-page="false" v-model="loading"></b-loading>

      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <h1 class="title">Manage Users</h1>
          </div>
        </div>
      </div>
      <hr />

      <!-- export users button -->
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <b-button
              target="_blank"
              :href="usersExportLink"
              tag="a"
              type="is-primary"
              icon-left="cloud-download-outline"
            >
              Export Users
            </b-button>
          </div>
        </div>
      </div>

      <AdminUserTable :users="userOptions" />
    </div>
  </AdminLayout>
</template>

<script>
import request from '../../request';
import AdminLayout from '../../layouts/AdminLayout.vue';
import AdminUserTable from '../../components/AdminUserTable.vue';

export default {
  name: 'AdminUserList',
  components: {
    AdminLayout,
    AdminUserTable,
  },
  data() {
    return {
      loading: true,
      userOptions: [],
    };
  },
  created() {
    this.getUsers();
  },
  methods: {
    getUsers() {
      this.loading = true;
      request
        .get('/admin/users')
        .then((resp) => (this.userOptions = resp.data.users))
        .finally(() => (this.loading = false));
    },
  },
  computed: {
    usersExportLink() {
      return '/api/v1/admin/users/export';
    },
  },
};
</script>
