import Vue from 'vue';
import Buefy from 'buefy';
// import "buefy/dist/buefy.css";
import './style.scss';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import NProgress from 'vue-nprogress';

import App from './App.vue';
import router from './router';
import store from './store';
import './filters';
import BreadcrumbList from './components/BreadcrumbList';
import BreadcrumbLink from './components/BreadcrumbLink';

// Global components
Vue.component('BreadcrumbLink', BreadcrumbLink);
Vue.component('BreadcrumbList', BreadcrumbList);

Sentry.init({
  Vue: Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  environment: process.env.VUE_APP_ENVIRONMENT,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [
        'dev-app.quickadmit.com',
        'staging-app.quickadmit.com',
        'app.quickadmit.com',
        /^\//,
      ],
    }),
  ],
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

Vue.config.productionTip = false;
Vue.use(NProgress);
Vue.use(Buefy, { defaultFieldLabelPosition: 'inside' });

const nprogress = new NProgress();

new Vue({
  router,
  store,
  nprogress,
  render: (h) => h(App),
}).$mount('#app');

// FIXME: Used for new version. Move this code some where else.
Object.defineProperty(String.prototype, 'hashCode', {
  value: function () {
    var hash = 0,
      i,
      chr;
    for (i = 0; i < this.length; i++) {
      chr = this.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  },
});
