<template>
  <div>
    <breadcrumb-list>
      <breadcrumb-link view="Home" label="Home" />
      <breadcrumb-link view="AdminIndex" label="Admin" />
      <slot name="breadcrumb"></slot>
    </breadcrumb-list>
    <section>
      <div class="container is-fluid">
        <div class="columns">
          <div class="column is-2">
            <div class="box">
              <b-menu>
                <b-menu-list label="User Content">
                  <b-menu-item
                    label="Organizations"
                    tag="router-link"
                    :to="{ name: 'AdminOrganizationList' }"
                  ></b-menu-item>
                  <b-menu-item
                    label="Inquiries"
                    tag="router-link"
                    :to="{ name: 'AdminInquiryList' }"
                  ></b-menu-item>
                  <b-menu-item
                    label="Providers"
                    tag="router-link"
                    :to="{ name: 'AdminProviderList' }"
                  ></b-menu-item>
                  <b-menu-item
                    label="Users"
                    tag="router-link"
                    :to="{ name: 'AdminUserList' }"
                  ></b-menu-item>
                  <b-menu-item
                    label="Invites"
                    tag="router-link"
                    :to="{ name: 'AdminInviteList' }"
                  ></b-menu-item>
                  <b-menu-item
                    label="Populate Registration"
                    tag="router-link"
                    :to="{ name: 'RegistrationPopulate' }"
                  ></b-menu-item>
                </b-menu-list>
                <b-menu-list label="System Content">
                  <b-menu-item
                    label="Payers"
                    tag="router-link"
                    :to="{ name: 'AdminPayerList' }"
                  ></b-menu-item>
                  <b-menu-item
                    label="Reimbursements"
                    tag="router-link"
                    :to="{ name: 'AdminReimbursementList' }"
                  ></b-menu-item>
                  <b-menu-item
                    label="Local Allowables"
                    tag="router-link"
                    :to="{ name: 'AdminLAPrefixList' }"
                  ></b-menu-item>
                  <b-menu-item
                    label="Payers Prefixes"
                    tag="router-link"
                    :to="{ name: 'AdminPayersPrefixesUpload' }"
                  ></b-menu-item>
                </b-menu-list>
              </b-menu>
            </div>
            <div class="box">
              <b-menu>
                <b-menu-list label="Danger Zone">
                  <b-menu-item label="Frontend Error" @click="throwFrontendError"></b-menu-item>
                  <b-menu-item label="Backend Error" @click="throwBackendError"></b-menu-item>
                </b-menu-list>
              </b-menu>
            </div>
          </div>
          <div class="column">
            <slot name="default"></slot>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import request from '../request';

export default {
  name: 'AdminIndex',
  data() {
    return {
      loading: false,
      active: 'organizations',
    };
  },
  methods: {
    throwFrontendError() {
      throw new Error('Sentry Error');
    },
    throwBackendError() {
      request.post('/admin/error').catch();
    },
  },
};
</script>
