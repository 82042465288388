<template>
  <div>
    <breadcrumb-list>
      <breadcrumb-link view="Home" label="Home" />
      <breadcrumb-link view="OrganizationDetail" :label="organization" />
      <breadcrumb-link view="PlanList" label="Subscription Plan" :active="true" />
    </breadcrumb-list>

    <section class="section">
      <div class="container is-max-hd">
        <div v-if="isManager">
          <div v-if="hasActiveSubscription">
            <SubscriptionChange />
          </div>
          <div v-else>
            <SubscriptionSelect />
          </div>
        </div>
        <div v-else class="column is-three-fifths is-offset-one-fifth">
          <div class="box">
            <div class="block">
              <div class="has-text-centered">
                <h1 class="title is-3 has-text-primary">Organization Subscription</h1>
                <p class="block">
                  <strong>{{ organization }}</strong>
                  does not have a current subscription. Your organization manager has been notified.
                </p>

                <p>Please try again later.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SubscriptionChange from '../components/SubscriptionChange';
import SubscriptionSelect from '../components/SubscriptionSelect';

export default {
  name: 'PlanList',
  components: {
    SubscriptionChange,
    SubscriptionSelect,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters(['organization', 'isManager', 'hasActiveSubscription']),
  },
};
</script>
